import React, { useCallback, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import {
    Checkbox,
    Input,
    InputWrapper,
    LabelPosition,
    Select,
} from '@amzn/stencil-react-components/form'
import { IconBin } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Text } from '@amzn/stencil-react-components/text'
import { ToggleSwitch } from '@amzn/stencil-react-components/toggle-switch'

import { ModuleGroupRenderOption } from 'src/components/module-groups/ModuleGroupRenderOption'
import { PECCreateEditValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'
import { FunctionalScoreDTO } from 'src/models/dto/module-groups/PECGroupDTO'
import { FunctionalScoreCreationList } from 'src/pages/module-groups/ToggleFunctionalScore'
import { ModuleGroupInformationChangeSummary } from 'src/services/module-groups/ModuleGroupEditUtils'

export interface FunctionalScoresEditProps {
    editInformationSummary: ModuleGroupInformationChangeSummary
    setEditInformationSummary: (summary: ModuleGroupInformationChangeSummary) => void
    functionalScores: FunctionalScoreDTO[]
    defaultModuleVersionId: string
    defaultModuleScores: string[]
    functionalScoresEnabled: boolean
    setFunctionalScoresEnabled: (isToggled: boolean) => void
    setValidationMessage: (validationMessage: string) => void
    editPECValidations?: PECCreateEditValidationDTO
    setEditPECValidations?: (editPECValidations: PECCreateEditValidationDTO) => void
}

export const FunctionalScoresEdit = (props: FunctionalScoresEditProps) => {
    const [addedFunctionalScores, setAddedFunctionalScores] = useState<FunctionalScoreDTO[]>(
        props.editInformationSummary.addedFunctionalScores
    )
    const [selectedModuleScores, setSelectedModuleScores] = useState<string[]>([])
    const [replacedScores, setReplacedScores] = useState(
        props.editInformationSummary.replacedScores
    )
    const [removedScores, setRemovedScores] = useState<string[]>(
        props.editInformationSummary.removedScores
    )
    const [isNewDefaultModule, setIsNewDefaultModule] = useState<boolean>(false)
    const [originalDefaultModule, setOriginalDefaultModule] = useState<string>(
        props.editInformationSummary.defaultOrPrimaryChange.old
    )

    const getDefaultModuleScore = useCallback(
        (
            functionalScore: FunctionalScoreDTO,
            defaultModuleVersionId: string = originalDefaultModule
        ) => {
            return functionalScore.moduleVersionScoreLabelMap.get(defaultModuleVersionId) as string
        },
        [originalDefaultModule]
    )

    useEffect(() => {
        const newReplacedValues = Array.from(replacedScores.values())
        const oldReplacedScores = Array.from(replacedScores.keys())

        const deletedDefaultModuleScores = removedScores.map((functionalScoreLabel) => {
            const currentFunctionalScore = props.functionalScores.find(
                (currFuncScore) => currFuncScore.functionalScoreLabel === functionalScoreLabel
            ) as FunctionalScoreDTO
            return getDefaultModuleScore(currentFunctionalScore, props.defaultModuleVersionId)
        })
        const existingUsedDefaultScores = props.functionalScores.map((functionalScore) =>
            getDefaultModuleScore(functionalScore, props.defaultModuleVersionId)
        )
        const addedDefaultModuleScores = addedFunctionalScores.map((functionalScore) =>
            getDefaultModuleScore(functionalScore, props.defaultModuleVersionId)
        )

        const remainingUsedOldDefaultScores = existingUsedDefaultScores.filter((defaultScore) => {
            return (
                !oldReplacedScores.includes(defaultScore) &&
                !deletedDefaultModuleScores.includes(defaultScore)
            )
        })

        if (isNewDefaultModule) {
            setSelectedModuleScores([...addedDefaultModuleScores, ...newReplacedValues])
        } else {
            setSelectedModuleScores([
                ...addedDefaultModuleScores,
                ...newReplacedValues,
                ...remainingUsedOldDefaultScores,
            ])
        }
    }, [
        addedFunctionalScores,
        getDefaultModuleScore,
        isNewDefaultModule,
        props.defaultModuleVersionId,
        props.functionalScores,
        removedScores,
        replacedScores,
    ])

    useEffect(() => {
        setRemovedScores([...props.editInformationSummary.removedScores])
        setAddedFunctionalScores([...props.editInformationSummary.addedFunctionalScores])
        setReplacedScores(new Map(props.editInformationSummary.replacedScores))
    }, [props.editInformationSummary])

    useEffect(() => {
        setIsNewDefaultModule(!!props.editInformationSummary.defaultOrPrimaryChange.new)
        setOriginalDefaultModule(props.editInformationSummary.defaultOrPrimaryChange.old)
    }, [props.editInformationSummary.defaultOrPrimaryChange])

    const addFunctionalScore = () => {
        const updatedAddedFunctionalScores = [
            ...addedFunctionalScores,
            {
                functionalScoreLabel: '',
                moduleVersionScoreLabelMap: new Map(),
            },
        ]
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            addedFunctionalScores: updatedAddedFunctionalScores,
        })
    }

    const changeModuleScore = (
        index: number,
        functionalScore: FunctionalScoreDTO,
        moduleScore: string
    ) => {
        const updatedFunctionalScores = [...addedFunctionalScores]
        const updatedFunctionalScore = {
            ...functionalScore,
            moduleVersionScoreLabelMap: functionalScore.moduleVersionScoreLabelMap.set(
                props.defaultModuleVersionId,
                moduleScore
            ),
        }

        updatedFunctionalScores.splice(index, 1, updatedFunctionalScore)
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            addedFunctionalScores: updatedFunctionalScores,
        })
    }

    const updateFunctionalScoreLabel = (
        index: number,
        functionalScore: FunctionalScoreDTO,
        value: string
    ) => {
        const updatedFunctionalScores = [...addedFunctionalScores]
        const updatedFunctionalScore = {
            ...functionalScore,
            functionalScoreLabel: value || '',
        }
        updatedFunctionalScores.splice(index, 1, updatedFunctionalScore)
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            addedFunctionalScores: updatedFunctionalScores,
        })
    }

    const deleteFunctionalScore = (index: number) => {
        const updatedFunctionalScores = [...addedFunctionalScores]
        updatedFunctionalScores.splice(index, 1)
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            addedFunctionalScores: updatedFunctionalScores,
        })
    }

    const getAvailableModuleScores = () => {
        return props.defaultModuleScores && props.defaultModuleScores.length > 0
            ? props.defaultModuleScores.filter((score) => !selectedModuleScores.includes(score))
            : []
    }

    const getFooterLabel = (functionalScoreLabel: string) => {
        return removedScores.includes(functionalScoreLabel)
            ? 'This score has been deleted'
            : undefined
    }

    const getSelectValue = (functionalScore: FunctionalScoreDTO) => {
        const defaultModuleScore = getDefaultModuleScore(functionalScore)
        const isScoreRemoved = removedScores.includes(functionalScore.functionalScoreLabel)
        return !isScoreRemoved ? replacedScores.get(defaultModuleScore) || '' : 'Deleted'
    }

    const replaceFunctionalScore = (newValue: string, oldDefaultScore: string) => {
        const replaceMap = new Map(replacedScores).set(oldDefaultScore, newValue)
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            replacedScores: replaceMap,
        })
    }

    const handleFunctionalScoreCheckboxChange = (checked: boolean, oldDefaultScore: string) => {
        const replaceMap = new Map(replacedScores)
        checked ? replaceMap.delete(oldDefaultScore) : replaceMap.set(oldDefaultScore, '')
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            replacedScores: replaceMap,
        })
    }

    const shouldDisabledCheckbox = (functionalScore: FunctionalScoreDTO) => {
        const defaultModuleScore = getDefaultModuleScore(functionalScore)
        return (
            Array.from(replacedScores.values()).includes(defaultModuleScore) ||
            addedFunctionalScores
                .map((funcScore) => getDefaultModuleScore(funcScore))
                .includes(defaultModuleScore)
        )
    }

    const removeExistingFunctionalScore = (functionalScore: FunctionalScoreDTO) => {
        const defaultModuleScore = getDefaultModuleScore(functionalScore)
        const replaceMap = new Map(replacedScores)
        const updatedRemovedScores = [...removedScores]
        updatedRemovedScores.push(functionalScore.functionalScoreLabel)
        if (replaceMap.has(defaultModuleScore)) {
            replaceMap.delete(defaultModuleScore)
        }
        props.setEditInformationSummary({
            ...props.editInformationSummary,
            removedScores: [...updatedRemovedScores],
            replacedScores: replaceMap,
        })
    }

    const newDefaultScoreSelectIsDisabled = (functionalScore: FunctionalScoreDTO) => {
        return (
            removedScores.includes(functionalScore.functionalScoreLabel) ||
            (!isNewDefaultModule && !replacedScores.has(getDefaultModuleScore(functionalScore)))
        )
    }

    const clearOldErrorValidations = (index: number) => {
        if (
            props.setEditPECValidations &&
            props.editPECValidations &&
            props.editPECValidations.functionalEquivalency
        ) {
            const updatedFuncEquivalency = new Map(
                props.editPECValidations.functionalEquivalency
            ).set(index, '')
            props.setEditPECValidations({
                ...props.editPECValidations,
                functionalEquivalency: updatedFuncEquivalency,
            })
        }
        props.setValidationMessage('')
    }

    return (
        <Col gridGap='S400'>
            <Row gridGap='S600'>
                <Text fontSize='S200'>Functional Score?</Text>
                <ToggleSwitch
                    checked={props.functionalScoresEnabled}
                    onChange={(isToggled: boolean) => props.setFunctionalScoresEnabled(isToggled)}
                    data-test-id='functional-score-toggle'
                    disabled={props.defaultModuleScores.length === 0}
                />
            </Row>
            {props.defaultModuleScores.length === 0 && (
                <MessageBanner type={MessageBannerType.Warning}>
                    The selected Default module does not have any scores
                </MessageBanner>
            )}
            {!props.functionalScoresEnabled &&
                props.functionalScores &&
                props.functionalScores.length > 0 && (
                    <MessageBanner
                        type={MessageBannerType.Warning}
                        dataTestId='remove-func-score-warning'
                    >
                        Disabling the functional scores will delete all the score equivalencies you
                        set up in the module group once you save. Click cancel or re-enable them to
                        reset.
                    </MessageBanner>
                )}
            {props.functionalScoresEnabled && (
                <Col gridGap='S400'>
                    <ul>
                        <Col gridGap='S300'>
                            {props.functionalScores.map(
                                (functionalScore: FunctionalScoreDTO, index: number) => (
                                    <li
                                        key={`functional-${functionalScore.functionalScoreLabel}`}
                                        aria-label={`Edit functional score ${index + 1}`}
                                    >
                                        <Col gridGap='S300'>
                                            <Row gridGap='S200'>
                                                <View flex={1}>
                                                    <InputWrapper
                                                        id={`old-functional-score-wrapper-${functionalScore.functionalScoreLabel}`}
                                                        labelText={`Functional Score Name ${
                                                            index + 1
                                                        }`}
                                                        warning={
                                                            !!getFooterLabel(
                                                                functionalScore.functionalScoreLabel
                                                            )
                                                        }
                                                        footer={getFooterLabel(
                                                            functionalScore.functionalScoreLabel
                                                        )}
                                                    >
                                                        {(inputProps) => (
                                                            <Input
                                                                {...inputProps}
                                                                value={
                                                                    functionalScore.functionalScoreLabel
                                                                }
                                                                disabled
                                                            />
                                                        )}
                                                    </InputWrapper>
                                                </View>
                                                <Col>
                                                    <Spacer height='S500' />
                                                    <Button
                                                        icon={<IconBin title='Delete item' />}
                                                        isDestructive
                                                        dataTestId={`edit-score-${functionalScore.functionalScoreLabel}-delete`}
                                                        variant={ButtonVariant.Tertiary}
                                                        onClick={() => {
                                                            removeExistingFunctionalScore(
                                                                functionalScore
                                                            )
                                                            clearOldErrorValidations(index)
                                                        }}
                                                        aria-disabled={removedScores.includes(
                                                            functionalScore.functionalScoreLabel
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gridGap='S300' flex={1}>
                                                <View flex={1}>
                                                    <InputWrapper
                                                        id={`old-default-score-wrapper-${functionalScore.functionalScoreLabel}`}
                                                        labelText={`Current Default Module Score ${
                                                            index + 1
                                                        }`}
                                                    >
                                                        {(inputProps) => (
                                                            <Input
                                                                {...inputProps}
                                                                value={functionalScore.moduleVersionScoreLabelMap.get(
                                                                    originalDefaultModule
                                                                )}
                                                                disabled
                                                            />
                                                        )}
                                                    </InputWrapper>
                                                </View>
                                                <Col flex={1} gridGap='S100'>
                                                    <InputWrapper
                                                        id={`new-default-score-wrapper-${functionalScore.functionalScoreLabel}`}
                                                        labelText={`New Default Module Score ${
                                                            index + 1
                                                        }`}
                                                        required={
                                                            !newDefaultScoreSelectIsDisabled(
                                                                functionalScore
                                                            )
                                                        }
                                                        error={
                                                            !!props.editPECValidations?.functionalEquivalency?.get(
                                                                index
                                                            )
                                                        }
                                                        footer={
                                                            props.editPECValidations?.functionalEquivalency?.get(
                                                                index
                                                            )
                                                                ? props.editPECValidations?.functionalEquivalency?.get(
                                                                      index
                                                                  )
                                                                : undefined
                                                        }
                                                    >
                                                        {(inputProps) => (
                                                            <Select
                                                                {...inputProps}
                                                                options={[
                                                                    '',
                                                                    ...props.defaultModuleScores.filter(
                                                                        (primaryScore) =>
                                                                            !selectedModuleScores?.includes(
                                                                                primaryScore
                                                                            )
                                                                    ),
                                                                ]}
                                                                value={getSelectValue(
                                                                    functionalScore
                                                                )}
                                                                onChange={(value: string) => {
                                                                    replaceFunctionalScore(
                                                                        value,
                                                                        getDefaultModuleScore(
                                                                            functionalScore
                                                                        )
                                                                    )
                                                                    clearOldErrorValidations(index)
                                                                }}
                                                                disabled={newDefaultScoreSelectIsDisabled(
                                                                    functionalScore
                                                                )}
                                                                renderOption={
                                                                    ModuleGroupRenderOption
                                                                }
                                                            />
                                                        )}
                                                    </InputWrapper>
                                                    {!isNewDefaultModule &&
                                                        !removedScores.includes(
                                                            functionalScore.functionalScoreLabel
                                                        ) && (
                                                            <InputWrapper
                                                                id={`keep-score-checkbox-${functionalScore.functionalScoreLabel}`}
                                                                labelText='Keep the same'
                                                                labelPosition={
                                                                    LabelPosition.Trailing
                                                                }
                                                            >
                                                                {(inputProps) => (
                                                                    <Checkbox
                                                                        dataTestId={`keep-score-${functionalScore.functionalScoreLabel}`}
                                                                        name={`keep-score-${functionalScore.functionalScoreLabel}`}
                                                                        checked={
                                                                            !replacedScores.has(
                                                                                getDefaultModuleScore(
                                                                                    functionalScore
                                                                                )
                                                                            )
                                                                        }
                                                                        onChange={(e) => {
                                                                            handleFunctionalScoreCheckboxChange(
                                                                                e.target.checked,
                                                                                getDefaultModuleScore(
                                                                                    functionalScore
                                                                                )
                                                                            )
                                                                            clearOldErrorValidations(
                                                                                index
                                                                            )
                                                                        }}
                                                                        {...inputProps}
                                                                        disabled={shouldDisabledCheckbox(
                                                                            functionalScore
                                                                        )}
                                                                    />
                                                                )}
                                                            </InputWrapper>
                                                        )}
                                                </Col>
                                            </Row>
                                            <Hr size='wide' />
                                        </Col>
                                    </li>
                                )
                            )}
                        </Col>
                    </ul>
                    <FunctionalScoreCreationList
                        functionalScores={addedFunctionalScores}
                        updateFunctionalScoreLabel={updateFunctionalScoreLabel}
                        availableModuleScores={getAvailableModuleScores()}
                        defaultModuleVersionId={props.defaultModuleVersionId}
                        changeModuleScore={changeModuleScore}
                        deleteFunctionalScore={deleteFunctionalScore}
                        addFunctionalScore={addFunctionalScore}
                        isAddDisabled={
                            selectedModuleScores.length === props.defaultModuleScores.length
                        }
                        isEditMode={true}
                        pecValidations={props.editPECValidations}
                        setPecValidations={props.setEditPECValidations}
                        setValidationMessage={props.setValidationMessage}
                    />
                </Col>
            )}
        </Col>
    )
}
