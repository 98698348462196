import { cloneDeep } from 'lodash'

import {
    defaultLikertGroupDTO,
    defaultLikertStimulusDTO,
    defaultStimulusReferenceDTO,
    LikertGroupDTO,
    LikertItemDTO,
} from 'src/models/dto/items/LikertItemDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, Stores } from './../../Store'

const itemType = ItemType.LikertGroup

export class LikertGroupHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => LikertGroupHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<LikertGroupDTO>
    }

    static create(): LikertGroupDTO {
        return defaultLikertGroupDTO()
    }

    private static assign(id: string, obj: Partial<LikertGroupDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateStatement(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.header.statementI18N[locale] = nextValue
        })
    }

    static updateScale(id: string, nextValue: LocalizedAttribute<string>[]) {
        this.store().produce(id, (entity) => {
            entity.header.scaleI18N = nextValue
        })
    }

    static updateScores(id: string, index: number, nextValue: string[]) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.stimulus)
            entity.stimulus[index].stimulusV2DTO.scores = nextValue
        })
    }

    static addStimulus(id: string) {
        this.store().produce(id, (entity) => {
            entity.stimulus.push(defaultStimulusReferenceDTO())
        })
    }

    private static checkArrayBounds<T>(index: number, arr: T[]) {
        if (arr && (index < 0 || index >= arr.length)) {
            throw new Error(`Index out of bounds: Index: ${index}, Size: ${arr.length}`)
        }
    }

    static updateStimulus(id: string, index: number, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.stimulus)
            entity.stimulus[index].stimulusV2DTO.stimulusI18N[locale] = nextValue
        })
    }

    static updateStimulusLabel(id: string, index: number, nextValue: string) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.stimulus)
            entity.stimulus[index].stimulusV2DTO.label = nextValue
        })
    }

    static updateStimulusOptional(id: string, index: number, nextValue: boolean) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.stimulus)
            entity.stimulus[index].optional = nextValue
        })
    }

    static deleteStimulus(id: string, index: number) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.stimulus)
            entity.stimulus.splice(index, 1)
        })
    }

    static updateGroupSize(id: string, groupSize: number | undefined) {
        this.assign(id, { groupSize })
    }

    static mapOldLikert(likertDTO: LikertItemDTO): LikertGroupDTO {
        const likertGroupDTO = defaultLikertGroupDTO()
        ;(likertGroupDTO.optional = likertDTO.optional), (likertGroupDTO.label = likertDTO.label)
        likertGroupDTO.locale = likertDTO.locale
        likertGroupDTO.localeWiseMedia = cloneDeep(likertDTO.localeWiseMedia)
        likertGroupDTO.groupSize = likertDTO.rowStatementObjects.length
        likertGroupDTO.header.statementI18N = cloneDeep(likertDTO.statementI18N)
        likertGroupDTO.header.scaleI18N = likertDTO.scales.map((scale) => cloneDeep(scale.nameI18N))
        likertGroupDTO.stimulus = likertDTO.rowStatementObjects.map((rowStatement) => {
            const stimulusDTO = defaultLikertStimulusDTO()
            stimulusDTO.label = cloneDeep(rowStatement.rowStatementLabel)
            stimulusDTO.stimulusI18N = cloneDeep(rowStatement.rowStatementI18N)
            stimulusDTO.scores = rowStatement.rowStatementScores.map((row) => row.score.toString())
            return {
                stimulusV2DTO: stimulusDTO,
                optional: false,
            }
        })

        return likertGroupDTO
    }
}
