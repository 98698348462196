import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useActivityEntity, useEntity } from 'src/hooks/useEntity'
import { ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import {
    ConditionalBranchingType,
    ItemResponseTriggersDTO,
} from 'src/models/dto/ConditionalBranchingLogicDTO'
import { Locale } from 'src/models/dto/Locale'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    CellType,
    ItemEditorTable,
    ItemEditorTableColumns,
} from 'src/pages/module-builder/item-editors/ItemEditorTable'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export interface BranchingConditionsProps {
    pageIndex: number
    locale: Locale
    moduleEntityId: string
    workflowEntityId: string
    showValidationError?: boolean
    editDisabled?: boolean
}

export const BranchingConditions = ({
    pageIndex,
    locale,
    moduleEntityId,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: BranchingConditionsProps) => {
    if (!ValidationErrorEntityService.has(workflowEntityId)) {
        ValidationErrorEntityService.create(workflowEntityId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: workflowEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const triggerResponseColumns = [
        {
            header: (
                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'left'}>
                    <Text textAlign='center'>Item label</Text>
                    <IconWithTooltip
                        title='Show tooltip'
                        tooltipText={
                            'Only Multiple Choice and Likert items from previous pages in this module can be used to trigger conditional pages. ' +
                            'To use MC items, enter in the Unique Item ID of the item as the item label in this table. ' +
                            'For Likert items, enter in the Unique Item ID of the specific Likert Stimulus as the item label in this table.'
                        }
                        color='primary70'
                    />
                </Flex>
            ),
            headerLabel: 'Item label',
            width: '30%',
            disabled: !isDefaultLocale(locale) || editDisabled,
            cellType: CellType.TextInput,
            placeHolder: 'Item label',
            accessor: (data) => data.itemLabel,
            setValue: (nextValue: string, index: number) =>
                ItemGroupHandler.updateConditionalBranchingItemLabel(
                    workflowEntityId,
                    index,
                    nextValue
                ),
        },
        {
            header: (
                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'left'}>
                    <Text>Item response triggers</Text>
                    <IconWithTooltip
                        title='Show tooltip'
                        tooltipText={
                            'If the item label refers to a MC item, the valid response triggers will be the response labels ' +
                            'of that MC item. If the item label refers to a Likert item, the valid response triggers will be the scale responses ' +
                            'of the likert item (which answer(s) of the scale will trigger the conditional page).'
                        }
                        color='primary70'
                    />
                </Flex>
            ),
            headerLabel: 'Item response triggers',
            width: '40%',
            disabled: !isDefaultLocale(locale) || editDisabled,
            cellType: CellType.TextInput,
            placeHolder: 'Item response triggers (comma separated list)',
            accessor: (data) => data.responseLabels.join(', '),
            setValue: (nextValue: string, index: number) =>
                ItemGroupHandler.updateConditionalBranchingItemResponseLabels(
                    workflowEntityId,
                    index,
                    nextValue.split(',').map((element) => element.trim())
                ),
        },
        {
            header: <ScreenReaderOnly>Delete action</ScreenReaderOnly>,
            headerLabel: 'Delete',
            width: '5%',
            disabled: !isDefaultLocale(locale) || editDisabled,
            cellType: CellType.DeleteButton,
            setValue: (nextValue: never, index: number) =>
                ItemGroupHandler.deleteItemResponseLabels(workflowEntityId, index),
        },
    ] as ItemEditorTableColumns<ItemResponseTriggersDTO>

    const { entity } = useActivityEntity<ItemGroupEntity>({
        workflowEntityId,
        moduleEntityId,
    })

    return (
        <Col gridGap='S300' dataTestId={`page-${pageIndex}-branching-conditions`}>
            <InputWrapper
                labelText='Select Boolean Operation'
                id={`page-${pageIndex}-boolean-operation`}
                tooltipText={`Specify the boolean operation you want to apply to the conditions.
                          \n\n OR means any condition must be true to trigger this page.
                          \n\n AND means all conditions must be true to trigger this page.`}
            >
                {(props) => (
                    <Select
                        options={Object.values(ConditionalBranchingType)}
                        width={'30%'}
                        disabled={editDisabled}
                        dataTestId={`page-${pageIndex}-boolean-operation`}
                        placeholder='Select one'
                        {...props}
                        value={
                            entity.branchingConditions?.branchingType ?? ConditionalBranchingType.OR
                        }
                        onChange={(type: string) => {
                            ItemGroupHandler.updateBranchingType(
                                workflowEntityId,
                                type as ConditionalBranchingType
                            )
                        }}
                    />
                )}
            </InputWrapper>

            <Col width={'100%'}>
                <ItemEditorTable
                    itemId={`page-${pageIndex}-branching-conditions-labels`}
                    columns={triggerResponseColumns}
                    data={entity.branchingConditions?.itemConditions ?? []}
                    dataTestId={`page-${pageIndex}-branching-conditions-labels`}
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors.branchingConditions ?? []
                    ).join(', ')}
                    showError={showValidationError}
                />
                <Row justifyContent={'flex-end'}>
                    <Button
                        dataTestId={`page-${pageIndex}-add-branching-condition`}
                        icon={<IconPlus aria-hidden={true} />}
                        variant={ButtonVariant.Tertiary}
                        aria-disabled={!isDefaultLocale(locale) || editDisabled}
                        onClick={() => ItemGroupHandler.addItemResponseLabel(workflowEntityId)}
                    >
                        Add new row
                    </Button>
                </Row>
            </Col>
        </Col>
    )
}
