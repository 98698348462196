import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { applyChange, Change } from 'src/hooks/DTOEditor'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement, Medias } from 'src/models/dto/items/MediaDTO'
import {
    getDefaultMediaListDTO,
    getDefaultSingleMediaDTO,
    getDefaultTextWrappedMediaDTO,
    StaticContentLayoutType,
} from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale } from 'src/models/dto/Locale'
import { Store, STORE_ACTION } from '../Store'

export const ITEM_ENTITY_STORE_SELECTOR = 'ItemEntity'

/**
 *
 * @returns base parameters of an ItemEntity with a randomly generated itemVersionId
 */
export const item = (): Omit<ItemDTO, 'itemType'> => {
    const newId = uuidv4()
    return {
        id: newId,
        name: newId,
        label: '',
        ppt: '', // legacy field, we don't use this in the BE
        locale: Locale.en_US,
        optional: false,
        preserveOrder: false,
    }
}

/**
 * A Map of factories for generating default ItemEntity by specific type
 */
export const factories = new Map<ItemType, () => ItemDTO>()

export class ItemEntityService {
    static store: Store<ItemDTO>
    static init() {
        this.store = new Store<ItemDTO>(ITEM_ENTITY_STORE_SELECTOR)
    }

    static get(entityId: string): ItemDTO {
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    /**
     * Creates an Item Entity, delegates to specific creator if it exists, then dispatch action to create
     * the entity in the Store
     * @param itemType ITEM_TYPES
     * @returns the created ItemEntity
     */
    static create(itemType: ItemType | StaticContentLayoutType): ItemDTO {
        let factory: () => ItemDTO

        if (itemType === StaticContentLayoutType.STATIC_TEXT_WRAPPED_MEDIA) {
            factory = getDefaultTextWrappedMediaDTO
        } else if (itemType === StaticContentLayoutType.STATIC_MEDIA_LIST) {
            factory = getDefaultMediaListDTO
        } else if (itemType === StaticContentLayoutType.STATIC_SINGLE_MEDIA) {
            factory = getDefaultSingleMediaDTO
        } else {
            factory = factories.has(itemType as ItemType)
                ? (factories.get(itemType) as () => ItemDTO)
                : () => ({
                      ...item(),
                      itemType,
                  })
        }

        const entity: ItemDTO = factory()
        this.insert(entity)
        return entity
    }

    static insert(entity: ItemDTO) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static preformAction(entity: ItemDTO, action: STORE_ACTION) {
        this.store.dispatch({
            action: action,
            entityId: entity.id,
            payload: entity,
        })
    }

    static remove(entityId: string) {
        const entity = this.store.get(entityId)
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_DELETE,
            entityId,
            payload: entity,
        })
    }

    static duplicateItem(id: string) {
        const itemDTO = this.store.get(id)
        const duplicated: ItemDTO = cloneDeep(itemDTO)
        duplicated.id = uuidv4()
        duplicated.name = duplicated.id
        this.insert(duplicated)
        return duplicated
    }

    static duplicateItems(itemIds: string[]) {
        return itemIds.map((id) => this.duplicateItem(id))
    }

    /**
     * WARNING: this function is inherently unsafe
     * TODO: make this function private, refactor anywhere that calls it to call the
     * appropriate field update function
     * @param itemDTO
     */
    static update(itemDTO: ItemDTO) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: itemDTO.id,
            payload: itemDTO,
        })
    }

    /**
     * WARNING: this function is inherently unsafe
     * TODO: make this function private, refactor anywhere that calls it to call the
     * appropriate field update function
     * @param id The item ID for the affected item
     * @param change The update function to apply the change
     * @deprecated
     */
    static updateWithChange(id: string, change: (prev: ItemDTO) => ItemDTO) {
        const itemDTO = this.get(id)
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: itemDTO.id,
            payload: change(itemDTO),
        })
    }

    private static assign<T extends ItemDTO = ItemDTO>(id: string, obj: Partial<T>) {
        this.store.assign(id, obj)
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateLocaleWiseMediaOpt(id: string, change: Change<Medias | undefined>) {
        this.store.produce<ItemDTO & MediaElement>(id, (entity) => {
            entity.localeWiseMedia = applyChange(entity.localeWiseMedia, change)
        })
    }
}
