import React, { useCallback, useMemo, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Label } from '@amzn/stencil-react-components/dist/submodules/text'
import { InputFooter } from '@amzn/stencil-react-components/form'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { RowData, Table, TableColumn } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MarkdownEditor } from 'src/components/MarkdownEditor'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    LikertGroupDTO,
    LikertStimulusDTO,
    StimulusReferenceDTO,
} from 'src/models/dto/items/LikertItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorTextInput,
    LabelSize,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import {
    ButtonCell,
    getRenderCardForRow,
    ItemEditorCheckbox,
    StimulusTableProps,
    TextCell,
} from 'src/pages/module-builder/item-editors/ItemEditorTable'
import { LikertGroupHandler } from 'src/services/EntityServices/ItemUpdateHandlers/LikertGroupHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

const STIMULUS_PLACEHOLDERS = [
    'E.g. Do you like cats?',
    'E.g. Do you like cats better than dogs?',
    'E.g. Cats are better than everything?',
]

interface StimulusTablePropsWithValidation<T> extends Omit<StimulusTableProps<T>, 'errorMessage'> {
    entityId: string
    validationErrorKey: string
}

const TextCellWithValidation = ({
    data: { entityId, validationErrorKey, ...props },
    index,
}: {
    data: StimulusTablePropsWithValidation<string>
    index: number
}) => {
    if (!ValidationErrorEntityService.has(entityId)) {
        ValidationErrorEntityService.create(entityId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    return (
        <TextCell
            data={{
                errorMessage: (
                    validationErrorEntity.validationErrors[validationErrorKey] ?? []
                ).join(', '),
                ...props,
            }}
            index={index}
        />
    )
}

export const LikertGroupItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<LikertGroupDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [likertScale, setLikertScale] = useState(
        itemDTO.header.scaleI18N.map((scale) => scale[locale]).join(', ') ?? ''
    )

    const { matches } = useBreakpoints()

    const recoveredScale = useMemo(
        () => itemDTO.header.scaleI18N.map((scale) => scale[locale]).join(', ') ?? '',
        [itemDTO.header.scaleI18N, locale]
    )

    if (recoveredScale !== likertScale && !likertScale.startsWith(recoveredScale)) {
        const extras = likertScale.substring(recoveredScale.length)
        if (extras.trim() !== ',') {
            setLikertScale(recoveredScale)
        }
    }

    const updateLikertScale = useCallback(
        (nextValue: string) => {
            setLikertScale(nextValue)
            const mapped = nextValue
                .split(',')
                .filter((value) => value.length > 0)
                .map((value, index) => ({
                    ...itemDTO.header.scaleI18N[index],
                    [locale]: value.trim(),
                }))

            LikertGroupHandler.updateScale(itemDTO.id, mapped)
        },
        [itemDTO.header.scaleI18N, itemDTO.id, locale]
    )

    const updateLikertRowScores = useCallback(
        (index: number, nextValue: string) => {
            const mapped = nextValue
                .split(',')
                .filter((value) => value.length > 0)
                .map((value) => value.trim())

            LikertGroupHandler.updateScores(itemDTO.id, index, mapped)
        },
        [itemDTO.id]
    )

    const tableColumns = useMemo(() => {
        return [
            {
                header: 'Stimulus statements',
                width: '60%',
                cellComponent: TextCellWithValidation,
                accessor: ({ data, index }: RowData<StimulusReferenceDTO>) => {
                    return {
                        id: itemDTO.id,
                        type: 'stimulus',
                        value: data.stimulusV2DTO.stimulusI18N[locale] ?? '',
                        disabled: editDisabled,
                        entityId: data.stimulusV2DTO.id,
                        validationErrorKey: 'statementI18N',
                        placeholder: STIMULUS_PLACEHOLDERS[index % STIMULUS_PLACEHOLDERS.length],
                        showError: { showValidationError },
                        labelText: `Stimulus statement ${index + 1}`,
                        setValue: (nextValue: string) =>
                            LikertGroupHandler.updateStimulus(itemDTO.id, index, locale, nextValue),
                    }
                },
            },
            {
                header: 'Unique Item ID',
                width: '30%',
                cellComponent: TextCellWithValidation,
                accessor: ({ data, index }: RowData<StimulusReferenceDTO>) => {
                    return {
                        id: itemDTO.id,
                        type: 'label',
                        value: data.stimulusV2DTO.label,
                        disabled: !isDefaultLocale(locale) || editDisabled,
                        placeholder: 'Unique Item ID',
                        entityId: data.stimulusV2DTO.id,
                        validationErrorKey: 'label',
                        labelText: `Unique Item ID ${index + 1}`,
                        showError: { showValidationError },
                        setValue: (nextValue: string) =>
                            LikertGroupHandler.updateStimulusLabel(itemDTO.id, index, nextValue),
                    }
                },
            },
            {
                header: 'Scores',
                width: '30%',
                cellComponent: TextCell,
                accessor: ({ data, index }: RowData<StimulusReferenceDTO>) => ({
                    id: itemDTO.id,
                    type: 'scores',
                    value: data.stimulusV2DTO.scores.join(', '),
                    disabled: !isDefaultLocale(locale) || editDisabled,
                    errorMessage: 'Please enter a score for each scale separated by a comma',
                    placeholder: '1.0, 2.0',
                    labelText: `Scores ${index + 1}`,
                    setValue: (nextValue: string) => updateLikertRowScores(index, nextValue),
                }),
            },
            {
                header: 'Optional',
                width: '5%',
                cellComponent: ItemEditorCheckbox,
                accessor: ({ data, index }: RowData<StimulusReferenceDTO>) => ({
                    id: itemDTO.id,
                    type: 'optional',
                    value: data.optional,
                    disabled: !isDefaultLocale(locale) || editDisabled,
                    errorMessage: '',
                    labelText: `Optional ${index + 1}`,
                    setValue: (nextValue: boolean) =>
                        LikertGroupHandler.updateStimulusOptional(itemDTO.id, index, nextValue),
                }),
            },
            {
                header: <ScreenReaderOnly>Delete</ScreenReaderOnly>,
                width: '5%',
                cellComponent: ButtonCell,
                accessor: ({ data, index }: RowData<StimulusReferenceDTO>) => ({
                    id: itemDTO.id,
                    type: 'delete',
                    disabled:
                        itemDTO.stimulus.length <= 1 || !isDefaultLocale(locale) || editDisabled,
                    value: data.optional,
                    labelText: `Delete stimulus ${index + 1}`,
                    setValue: () => LikertGroupHandler.deleteStimulus(itemDTO.id, index),
                }),
            },
        ] as unknown as TableColumn<
            LikertStimulusDTO,
            StimulusTableProps<unknown> | StimulusTablePropsWithValidation<unknown>
        >[]
    }, [
        editDisabled,
        itemDTO.id,
        itemDTO.stimulus.length,
        locale,
        showValidationError,
        updateLikertRowScores,
    ])

    const renderCardForRow = useMemo(() => getRenderCardForRow(tableColumns), [tableColumns])

    // const errorStatementIsEmpty = itemDTO.header.statementI18N[locale]?.length === 0
    const errorStimulusIsEmpty = itemDTO.stimulus.length === 0

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Col gridGap={'S300'}>
                <Row gridGap={'S200'}>
                    <Col width={'80%'}>
                        <ItemEditorTextInput
                            inputId='unique-item-id'
                            dataTestId={'unique-item-id'}
                            value={itemDTO.label}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            placeholder='Some human readable label'
                            itemId={itemDTO.id}
                            setValue={(nextValue: string) =>
                                LikertGroupHandler.updateLabel(itemDTO.id, nextValue)
                            }
                            validationErrorMessage={(
                                validationErrorEntity.validationErrors.label ?? []
                            ).join(', ')}
                            showError={showValidationError}
                            labelText='Unique Item ID'
                        />
                    </Col>
                    <Col gridGap={'S200'} padding={{ top: `${matches.s ? '8px' : '29px'}` }}>
                        <Row alignItems={'center'}>
                            <ItemEditorCheckboxInput
                                dataTestId={'preserve-order-checkbox'}
                                inputId={'preserve-order'}
                                itemId={itemDTO.id}
                                labelText={'Preserve order'}
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                value={itemDTO.preserveOrder}
                                setValue={(nextValue: boolean) => {
                                    LikertGroupHandler.updatePreserveOrder(itemDTO.id, nextValue)
                                }}
                            />
                            <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                        </Row>
                    </Col>
                </Row>
                <Col width={'100%'}>
                    <Label>
                        <Text fontSize={'T100'} color={'neutral70'}>
                            Statement
                        </Text>
                    </Label>
                    <Spacer height='S100' />
                    <MarkdownEditor
                        dataTestId='statement-input'
                        locale={locale}
                        value={itemDTO.header.statementI18N[locale] ?? ''}
                        disabled={editDisabled}
                        id={'statement-input'}
                        onChange={(nextValue: string) =>
                            LikertGroupHandler.updateStatement(itemDTO.id, locale, nextValue)
                        }
                    />
                    {validationErrorEntity.validationErrors['header.statementI18N'] && (
                        <InputFooter error={true} id={'likert-group-stimulus-table-error-footer'}>
                            {validationErrorEntity.validationErrors['header.statementI18N']}
                        </InputFooter>
                    )}
                </Col>
                <Col width={'100%'}>
                    <ItemEditorTextInput
                        inputId='likert-scale'
                        dataTestId='likert-scale'
                        value={likertScale}
                        placeholder='E.g. Strongly agree, Agree, Neutral, Disagree, Strongly disagree'
                        itemId={itemDTO.id}
                        disabled={editDisabled}
                        setValue={updateLikertScale}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors['header.scaleI18N'] ?? []
                        ).join(', ')}
                        labelText='Likert Scale'
                        showError={showValidationError}
                        labelSize={LabelSize.Normal}
                    />
                </Col>
                <Col width={'100%'}>
                    <ItemEditorNumberInput
                        inputId='likert-group-size'
                        dataTestId='likert-group-size'
                        value={itemDTO.groupSize ?? null}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        placeholder='Number of items displayed per a single page'
                        itemId={itemDTO.id}
                        setValue={(nextValue: number | null) =>
                            LikertGroupHandler.updateGroupSize(itemDTO.id, nextValue ?? undefined)
                        }
                        labelText='Group Size'
                        labelSize={LabelSize.Normal}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.groupSize ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col width={'100%'}>
                    <Label htmlFor={`likert-group-stimulus-table-label-${itemDTO.id}`}>
                        <Text fontSize={'T300'} color={'neutral90'} fontWeight={'bold'}>
                            Stimulus
                        </Text>
                    </Label>
                    <Spacer height='S100' />
                    <Table
                        aria-labelledby={`likert-group-stimulus-table-label-${itemDTO.id}`}
                        columns={tableColumns}
                        data={itemDTO.stimulus}
                        dataTestId='likert-group-stimulus-table'
                        renderCardForRow={renderCardForRow}
                    />
                    {errorStimulusIsEmpty && (
                        <InputFooter error={true} id={'likert-group-stimulus-table-error-footer'}>
                            Please add at least one stimulus
                        </InputFooter>
                    )}
                    <Row justifyContent={'flex-end'}>
                        <Button
                            icon={<IconPlus aria-hidden={true} />}
                            dataTestId={'add-stimulus'}
                            aria-disabled={!isDefaultLocale(locale) || editDisabled}
                            variant={ButtonVariant.Tertiary}
                            onClick={() => LikertGroupHandler.addStimulus(itemDTO.id)}
                        >
                            Add response option
                        </Button>
                    </Row>
                </Col>
            </Col>
        </>
    )
}
