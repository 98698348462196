import {
    RandomSelectionDTO,
    RandomSelectionGroupEntity,
} from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'

const activityType = ActivityType.LaunchRandomSelection

export class RandomSelectionGroupHandler {
    static init() {
        factories.set(activityType, () => RandomSelectionGroupHandler.create())
    }

    static get(entityId: string): RandomSelectionGroupEntity {
        return ActivityEntityService.get(entityId) as RandomSelectionGroupEntity
    }

    /**
     *
     * @returns an RandomSelectionGroupEntity but does not add it to the Store
     */
    static create(): RandomSelectionGroupEntity {
        return {
            ...activity(),
            name: 'RandomSelectionGroupName',
            ppt: ActivityType.LaunchRandomSelection,
            type: ActivityType.LaunchRandomSelection,
            groupSize: 5,
            numberOfItemsToPick: 20,
            poolVersionId: '',
        }
    }

    private static assign(id: string, obj: Partial<RandomSelectionDTO>) {
        ActivityEntityService.store.assign(id, obj)
    }

    static updateItemPool(entityId: string, poolVersionId: string) {
        this.assign(entityId, { poolVersionId })
    }

    static updateGroupSize(entityId: string, groupSize: number | null) {
        this.assign(entityId, { groupSize })
    }

    static updateNoOfItemsToPick(entityId: string, numberOfItemsToPick: number | null) {
        this.assign(entityId, { numberOfItemsToPick })
    }
}
