import { isEqual } from 'lodash'
import { v4 } from 'uuid'

import { applyChange, Change } from 'src/hooks/DTOEditor'
import {
    ContextBoxDTO,
    ContextBoxEntity,
    ContextType,
    IconType,
    RequestType,
} from 'src/models/dto/ContextBoxDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { Store, STORE_ACTION } from '../Store'

export const CONTEXT_BOX_ENTITY_STORE_SELECTOR = 'ContextBoxEntity'

export class ContextBoxEntityService {
    static store: Store<ContextBoxEntity>

    static init() {
        this.store = new Store<ContextBoxEntity>(CONTEXT_BOX_ENTITY_STORE_SELECTOR)
    }

    static getRequestType(entity: ContextBoxEntity) {
        switch (entity.contextType) {
            case ContextType.AUDIO:
                return RequestType.Audio
            case ContextType.CHAT:
                if (entity.iconType === IconType.CALL) {
                    return RequestType.Call
                } else {
                    return RequestType.Message
                }
            case ContextType.SITUATION:
                return RequestType.Situation
        }
    }

    static get(entityId: string): ContextBoxEntity {
        return this.store.get(entityId)
    }

    static create(): ContextBoxEntity {
        const entity: ContextBoxEntity = {
            id: v4(),
            contextType: ContextType.CHAT,
            contentI18N: LocalizeDefault<string>(''),
            iconType: IconType.MESSAGE,
            itemType: ItemType.ContextBox,
        }

        this.insert(entity)
        return entity
    }

    static insert(entity: ContextBoxEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    private static update(entity: ContextBoxEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static setContent(entityId: string, locale: Locale, content: string) {
        this.store.produce(entityId, (entity) => {
            entity.contentI18N[locale] = content
        })
    }

    static setMaxPlays(entityId: string, maxPlays?: number) {
        this.store.assign(entityId, { maxPlays })
    }

    static setContextType(
        entityId: string,
        contextType: ContextType,
        iconTypeForChat = IconType.MESSAGE
    ) {
        // update is required since we need to erase all other fields
        switch (contextType) {
            case ContextType.AUDIO:
                this.update({
                    id: entityId,
                    contextType: ContextType.AUDIO,
                    contentI18N: LocalizeDefault(''),
                    itemType: ItemType.ContextBox,
                })
                break
            case ContextType.CHAT:
                this.update({
                    id: entityId,
                    contextType: ContextType.CHAT,
                    contentI18N: LocalizeDefault(''),
                    iconType: iconTypeForChat,
                    itemType: ItemType.ContextBox,
                })
                break
            case ContextType.SITUATION:
                this.update({
                    id: entityId,
                    contextType: ContextType.SITUATION,
                    contentI18N: LocalizeDefault(''),
                    itemType: ItemType.ContextBox,
                })
                break
        }
    }

    static setRequestType(entityId: string, requestType: RequestType) {
        switch (requestType) {
            case RequestType.Call:
                return this.setContextType(entityId, ContextType.CHAT, IconType.CALL)
            case RequestType.Message:
                return this.setContextType(entityId, ContextType.CHAT, IconType.MESSAGE)
            case RequestType.Situation:
                return this.setContextType(entityId, ContextType.SITUATION)
            case RequestType.Audio:
                return this.setContextType(entityId, ContextType.AUDIO)
        }
    }

    static setIconType(entityId: string, iconType: IconType) {
        this.store.assign(entityId, { iconType })
    }

    static setAudio(id: string, change: Change<Medias | undefined>) {
        this.store.produce(id, (entity) => {
            entity.localeWiseMedia = applyChange(entity.localeWiseMedia, change)
        })
    }

    static hasChangedFromDefault(id: string) {
        const contextBoxEntity = this.get(id)
        return !isEqual(contextBoxEntity, {
            id: id,
            contextType: contextBoxEntity.contextType,
            contentI18N: LocalizeDefault(''),
            itemType: ItemType.ContextBox,
            ...(contextBoxEntity.contextType === ContextType.CHAT
                ? {
                      iconType: contextBoxEntity.iconType,
                  }
                : {}),
        })
    }

    static duplicateContextBox(id: string) {
        const duplicated: ContextBoxEntity = { ...this.store.get(id), id: v4() }
        this.insert(duplicated)
        return duplicated
    }

    static toDTO(contextBoxId: string): ContextBoxDTO {
        return {
            ...this.get(contextBoxId),
            ppt: 'ContextBox',
            name: contextBoxId,
            label: contextBoxId,
            itemType: ItemType.ContextBox,
        }
    }
}
