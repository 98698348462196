import { Draft } from 'immer'

import { applyChange, Change } from 'src/hooks/DTOEditor'
import {
    BucketAndCupSelectionStepDTO,
    BucketDTO,
    BucketsAndCupsGroupGroupEntity,
    BucketSelectionRule,
    CupSelectionRule,
    enforceBucketSelectionRuleValidation,
    PagePattern,
} from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'

const activityType = ActivityType.LaunchBucketsAndCups

export class BucketsAndCupsGroupHandler {
    static init() {
        factories.set(activityType, () => BucketsAndCupsGroupHandler.create())
    }

    static get(entityId: string): BucketsAndCupsGroupGroupEntity {
        return ActivityEntityService.get(entityId) as BucketsAndCupsGroupGroupEntity
    }

    /**
     *
     * @returns an BucketsAndCupsGroupEntity but does not add it to the Store
     */
    static create(): BucketsAndCupsGroupGroupEntity {
        const newActivity = activity()
        return {
            ...newActivity,
            type: ActivityType.LaunchBucketsAndCups,
            ppt: ActivityType.LaunchBucketsAndCups,
            name: newActivity.id,
            buckets: [],
            bucketAndCupSelectionStep: [this.defaultSelectionStep()],
            poolVersionId: '',
            generateScoringTemplate: false,
            endOfModule: false,
            updatingCsvFile: true,
            timingEnabled: false,
        }
    }

    private static assign(entityId: string, obj: Partial<BucketsAndCupsGroupGroupEntity>) {
        ActivityEntityService.store.assign(entityId, obj)
    }

    private static produce(
        entityId: string,
        func: (draft: Draft<BucketsAndCupsGroupGroupEntity>) => void
    ) {
        ActivityEntityService.store.produce(entityId, func)
    }

    static toggleGenerateScoringTemplate(entityId: string) {
        this.produce(entityId, (entity) => {
            entity.generateScoringTemplate = !entity.generateScoringTemplate
        })
    }

    static addSelectionStep(entityId: string) {
        this.produce(entityId, (entity) => {
            entity.bucketAndCupSelectionStep.push(this.defaultSelectionStep())
        })
    }

    static addSelectionStepAtIndex(entityId: string, index: number) {
        this.produce(entityId, (entity) => {
            entity.bucketAndCupSelectionStep.splice(index + 1, 0, this.defaultSelectionStep())
        })
    }

    static moveSelectionStepUp(entityId: string, index: number) {
        if (index === 0) return

        this.produce(entityId, ({ bucketAndCupSelectionStep: steps }) => {
            ;[steps[index], steps[index - 1]] = [steps[index - 1], steps[index]]
        })
    }

    static moveSelectionStepDown(entityId: string, index: number) {
        this.produce(entityId, ({ bucketAndCupSelectionStep: steps }) => {
            if (index === steps.length - 1) return
            ;[steps[index], steps[index + 1]] = [steps[index + 1], steps[index]]
        })
    }

    static setItemPoolVersionId(entityId: string, poolVersionId: string) {
        this.assign(entityId, { poolVersionId })
    }

    static setBuckets(entityId: string, buckets: BucketDTO[]) {
        this.assign(entityId, { buckets, updatingCsvFile: false })
    }

    static updateSelectionStep(
        entityId: string,
        index: number,
        bucketAndCupSelectionStepDTO: BucketAndCupSelectionStepDTO
    ) {
        const rank0 = bucketAndCupSelectionStepDTO.bucketRank
        this.produce(entityId, (current) => {
            const { timingEnabled } = current
            const cupSelectionRuleUnderSameRank =
                (rank0
                    ? current.bucketAndCupSelectionStep.find(
                          (s, i) => i < index && s.bucketRank === rank0
                      )?.cupSelectionRule
                    : undefined) ?? bucketAndCupSelectionStepDTO.cupSelectionRule
            current.bucketAndCupSelectionStep.forEach((b, i, ref) => {
                if (i > index && b.bucketRank === rank0) {
                    ref[i] = enforceBucketSelectionRuleValidation(
                        b,
                        timingEnabled,
                        cupSelectionRuleUnderSameRank
                    )
                } else if (i === index) {
                    ref[i] = enforceBucketSelectionRuleValidation(
                        bucketAndCupSelectionStepDTO,
                        timingEnabled,
                        cupSelectionRuleUnderSameRank
                    )
                }
            })
        })
    }

    static updatePagePatterns(
        entityId: string,
        index: number,
        pagePatterns: Change<PagePattern[] | undefined>
    ) {
        const current = this.get(entityId).bucketAndCupSelectionStep[index]
        if (!current) {
            return
        }

        this.updateSelectionStep(entityId, index, {
            ...current,
            pagePatterns: applyChange(current.pagePatterns, pagePatterns) ?? undefined,
        })
    }

    static deleteSelectionStep(entityId: string, index: number) {
        this.produce(entityId, (entity) => {
            entity.bucketAndCupSelectionStep.splice(index, 1)
        })
    }

    static setUpdatingCSV(entityId: string, updatingCsvFile: boolean) {
        this.assign(entityId, { updatingCsvFile })
    }

    static setTimingEnabled(entityId: string, timingEnabled: boolean) {
        this.produce(entityId, (entity) => {
            entity.timingEnabled = timingEnabled
            if (!timingEnabled) {
                return
            }

            for (const step of entity.bucketAndCupSelectionStep) {
                step.cupRank = 1
                step.numberOfItemsToPickFromCup = 1
                enforceBucketSelectionRuleValidation(step, timingEnabled, undefined, true)
            }
        })
    }

    private static defaultSelectionStep(): BucketAndCupSelectionStepDTO {
        return {
            bucketRank: 1,
            cupRank: 1,
            bucketSelectionRule: BucketSelectionRule.RANDOM,
            cupSelectionRule: CupSelectionRule.RANDOM,
            groupSize: 1,
            numberOfCupsToPickFromBucket: 1,
            numberOfItemsToPickFromCup: 1,
        }
    }
}
