import { Draft } from 'immer'
import _, { uniqBy } from 'lodash'

import { Locale, LocalizedAttribute, LocalizeDefault } from '../../../models/dto/Locale'
import { factories, item, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemType } from './../../../models/dto/items/ItemDTO'
import {
    RankingCellSubType,
    RankingCellType,
    RankingCellValue,
    RankingColumn,
    RankingRatingScale,
    RankingResponse,
    RankingResponseCell,
    RankingResponseScore,
    RankingResponseTableItemDTO,
    RankingTable,
} from './../../../models/dto/items/RankingItemDTO'
import { Store, Stores } from './../../Store'

const itemType = ItemType.RankingResponseTable

export class RankingResponseTableHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => RankingResponseTableHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<RankingResponseTableItemDTO>
    }

    private static getEntity(id: string): RankingResponseTableItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id)
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): RankingResponseTableItemDTO {
        const rankingTable: RankingTable = this.generateEmptyTable(4, 5, Locale.en_US)

        return {
            ...item(),
            itemType,
            statementI18N: LocalizeDefault<string>(''),
            ppt: 'RankingResponseTable',
            responseOrderRandomizationEnabled: true,
            rankingTable,
        }
    }

    private static assign(id: string, obj: Partial<RankingResponseTableItemDTO>) {
        this.store().assign(id, obj)
    }

    private static produce(id: string, func: (draft: Draft<RankingResponseTableItemDTO>) => void) {
        this.store().produce<RankingResponseTableItemDTO>(id, func)
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    /**
     * Updates the statement of the ranking item
     * @param id
     * @param statementI18N
     */
    static updateStatementI18N(id: string, statementI18N: LocalizedAttribute<string>) {
        this.assign(id, { statementI18N })
    }

    /**
     * Private function, Updates the table in an arbitrary way
     * @param id
     * @param rankingTable
     */
    private static updateRankingTable(id: string, rankingTable: RankingTable) {
        this.assign(id, { rankingTable })
    }

    /**
     * Updates parameter for if response order is randomized
     * @param id
     * @param responseOrderRandomizationEnabled
     */
    static updateResponseOrderRandomizationEnabled(
        id: string,
        responseOrderRandomizationEnabled: boolean
    ) {
        this.assign(id, { responseOrderRandomizationEnabled })
    }

    // /**
    //  * Updates the set of excluded ranking response orders
    //  * @param id
    //  * @param excludedRankingResponseOrders
    //  */
    // static updateExcludedRankingResponseOrders(
    //     id: string,
    //     excludedRankingResponseOrders: ExcludedRankingResponseOrders
    // ) {
    //     const entity = this.getEntity(id)
    //     const payload = {
    //         ...entity,
    //         excludedRankingResponseOrders,
    //     }
    //     this.update(payload)
    // }

    static addTableColumn(id: string, column: RankingColumn) {
        const entity = this.getEntity(id)
        const table = {
            ...entity.rankingTable,
            columns: [...entity.rankingTable.columns, column],
            responseRows: entity.rankingTable.responseRows.map((row) => {
                return {
                    ...row,
                    responseCells: [
                        ...row.responseCells,
                        {
                            values: [],
                        },
                    ],
                    responseScores: [...row.responseScores],
                }
            }),
        }

        this.updateRankingTable(id, table)
    }

    static sliceTableColumn(id: string, numToKeep: number) {
        const entity = this.getEntity(id)
        const table = {
            ...entity.rankingTable,
            columns: [...entity.rankingTable.columns].slice(0, numToKeep),
            responseRows: entity.rankingTable.responseRows.map((row) => {
                return {
                    ...row,
                    responseCells: [...row.responseCells].slice(0, numToKeep),
                }
            }),
        }

        this.updateRankingTable(id, table)
    }

    static addResponseRow(id: string, row: RankingResponse) {
        const entity = this.getEntity(id)
        const expectedScoringLength = entity.rankingTable.responseRows.length + 1
        if (row.responseScores.length > expectedScoringLength) {
            row = {
                ...row,
                responseScores: [...row.responseScores].slice(0, expectedScoringLength),
            }
        } else if (row.responseScores.length < expectedScoringLength) {
            row = {
                ...row,
                responseScores: [
                    ...row.responseScores,
                    ..._.range(expectedScoringLength - row.responseScores.length).map(() => ({
                        score: 0,
                    })),
                ],
            }
        }

        const expectedColsLength = entity.rankingTable.columns.length
        if (row.responseCells.length > expectedColsLength) {
            row = {
                ...row,
                responseCells: [...row.responseCells].slice(0, expectedColsLength),
            }
        } else if (row.responseCells.length < expectedColsLength) {
            row = {
                ...row,
                responseCells: [
                    ...row.responseCells,
                    ..._.range(expectedColsLength - row.responseCells.length).map(() => ({
                        values: [],
                    })),
                ],
            }
        }

        const table = {
            ...entity.rankingTable,
            responseRows: [
                ...entity.rankingTable.responseRows.map((row0) => ({
                    ...row0,
                    responseScores: [...row0.responseScores, { score: 0 }],
                })),
                row,
            ],
        }

        this.updateRankingTable(id, table)
    }

    static sliceResponseRow(id: string, numToKeep: number) {
        const entity = this.getEntity(id)
        const table = {
            ...entity.rankingTable,
            responseRows: [...entity.rankingTable.responseRows].slice(0, numToKeep).map((row0) => ({
                ...row0,
                responseScores: [...row0.responseScores].slice(0, numToKeep),
            })),
        }

        this.updateRankingTable(id, table)
    }

    static generateEmptyTable(numColumns: number, numRows: number, locale: Locale): RankingTable {
        return {
            columns: _.range(numColumns).map(() => ({
                headerI18N: LocalizeDefault<string>('', locale),
            })),
            ratingScales: [],
            responseRows: _.range(numRows).map(() =>
                this.generateEmptyResponseRow(numColumns, numRows)
            ),
        }
    }

    static generateEmptyResponseRow(numColumns: number, numRows: number): RankingResponse {
        return {
            responseCells: _.range(numColumns).map(() => this.generateEmptyRankingResponseCell()),
            responseScores: _.range(numRows).map(() => ({ score: 0 })),
            responseLabel: '',
        }
    }

    static generateEmptyRankingResponseCell(): RankingResponseCell {
        return {
            values: [],
        }
    }

    static toggleSymbol(
        id: string,
        type: RankingCellType.HarveyBall | RankingCellType.Arrow,
        toggle: boolean
    ) {
        this.produce(id, ({ rankingTable }) => {
            let ratingScales = [...rankingTable.ratingScales]
            let responseRows = rankingTable.responseRows

            if (toggle) {
                let toAdd: RankingCellValue[] = []

                switch (type) {
                    case RankingCellType.HarveyBall:
                        toAdd = [
                            {
                                type: RankingCellType.HarveyBall,
                                subType: RankingCellSubType.HarveyBallEmpty,
                                valueI18N: LocalizeDefault<string>('Low amount', Locale.en_US),
                            },
                            {
                                type: RankingCellType.HarveyBall,
                                subType: RankingCellSubType.HarveyBallHalfFull,
                                valueI18N: LocalizeDefault<string>('Moderate amount', Locale.en_US),
                            },
                            {
                                type: RankingCellType.HarveyBall,
                                subType: RankingCellSubType.HarveyBallFull,
                                valueI18N: LocalizeDefault<string>('High amount', Locale.en_US),
                            },
                        ]
                        break
                    case RankingCellType.Arrow:
                        toAdd = [
                            {
                                type: RankingCellType.Arrow,
                                subType: RankingCellSubType.ArrowUp,
                                valueI18N: LocalizeDefault<string>('Up', Locale.en_US),
                            },
                            {
                                type: RankingCellType.Arrow,
                                subType: RankingCellSubType.ArrowDown,
                                valueI18N: LocalizeDefault<string>('Down', Locale.en_US),
                            },
                        ]
                        break
                }

                // technique: use uniqBy to avoid duplicates
                ratingScales = uniqBy([...ratingScales, ...toAdd], 'subType')
            } else {
                // toggling off
                ratingScales = ratingScales.filter((s) => s.type !== type)

                // remove from cells that uses this
                responseRows = responseRows.map((response: RankingResponse) => {
                    return {
                        ...response,
                        responseCells: response.responseCells.map((cell) => ({
                            values: cell.values.filter((v) => v.type !== type),
                        })),
                    }
                })
            }

            rankingTable.ratingScales = ratingScales
            rankingTable.responseRows = responseRows
        })
    }

    static updateScaleValue(
        id: string,
        typeAndSubType: Omit<RankingRatingScale, 'valueI18N'> & { valueI18N?: unknown },
        locale: Locale,
        value: string
    ) {
        this.produce(id, ({ rankingTable }) => {
            const scales = rankingTable.ratingScales
            const scale = scales.find(
                ({ type, subType }) =>
                    type === typeAndSubType.type && subType === typeAndSubType.subType
            )

            if (scale) {
                scale.valueI18N[locale] = value
            }
            rankingTable.ratingScales = scales
        })
    }

    /**
     * Updates a cell value in a ranking table on a ranking response table
     * @param id
     * @param rowNum
     * @param colNum
     * @param values
     * @param scores
     * @param rowLabel
     */
    static updateCellValue(
        id: string,
        rowNum: number,
        colNum: number,
        values: RankingCellValue[],
        scores: RankingResponseScore[],
        rowLabel: string
    ) {
        this.produce(id, ({ rankingTable: { responseRows } }) => {
            const row: RankingResponse = responseRows[rowNum]
            const cell: RankingResponseCell = row.responseCells[colNum]
            row.responseScores = scores
            row.responseLabel = rowLabel
            cell.values = values
            responseRows[rowNum] = row
        })
    }

    static updateColumnHeader(id: string, index: number, headerText: string, locale: Locale): void {
        this.produce(id, (entity) => {
            entity.rankingTable.columns[index].headerI18N[locale] = headerText
        })
    }

    static fixSubtype(id: string) {
        this.produce(id, (entity) => {
            const subTypeMapper: Record<string, RankingCellSubType> = {}
            entity.rankingTable.ratingScales.forEach((scale) => {
                const key = scale.valueI18N[Locale.en_US] || 'unknown'
                subTypeMapper[key] = scale.subType
            })

            entity.rankingTable.responseRows.forEach((row) => {
                const cells = row.responseCells
                cells.forEach((cell) => {
                    const values = cell.values
                    values.forEach((v) => {
                        const key = v.valueI18N[Locale.en_US] || 'unknown'
                        const expectedSubtype = subTypeMapper[key]
                        if (expectedSubtype !== v.subType) {
                            v.subType = expectedSubtype
                        }
                    })
                })
            })
        })
    }
}
