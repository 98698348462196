import { ActivityEntity, ActivityType } from 'src/models/dto/ActivityDTO'

export type BucketsAndCupsGroupGroupEntity = ActivityEntity &
    BucketsAndCupsGroupDTO & {
        updatingCsvFile: boolean
        timingEnabled: boolean
    }

export interface BucketsAndCupsGroupDTO {
    name: string
    poolVersionId: string
    buckets: BucketDTO[]
    generateScoringTemplate: boolean
    bucketAndCupSelectionStep: BucketAndCupSelectionStepDTO[] // Missing a trailing S to be consistent with the backend
}

export interface BucketDTO {
    bucketId: string
    rank: number
    maxFrequency: number
    cups: CupDTO[]
}

export interface CupDTO {
    cupId: string
    itemIds: string[]
    maxFrequency: number
    rank: number
}

export interface BucketAndCupSelectionStepDTO {
    bucketRank?: number
    bucketSelectionRule: BucketSelectionRule
    cupRank?: number
    numberOfCupsToPickFromBucket?: number
    numberOfItemsToPickFromCup?: number
    cupSelectionRule: CupSelectionRule
    groupSize: number
    timerConfig?: TimerConfigDTO
    pagePatterns?: PagePattern[]
}

export interface PagePattern {
    pageVariable: string
    tags: PagePatternTag[]
}

export interface PagePatternTag {
    tag: string
    subTags: string[]
}

export interface BaseTimerConfigDTO {
    type: TimerEventType
}

export type TimerConfigDTO = UponSubmissionDTO | SinceMessageDeliveredDTO

export interface UponSubmissionDTO extends BaseTimerConfigDTO {
    type: TimerEventType.UPON_SUBMISSION
}

export interface SinceMessageDeliveredDTO extends BaseTimerConfigDTO {
    type: TimerEventType.SINCE_LAST_MESSAGE_DELIVERED
    elapsedTimeInSeconds: number
}

export enum TimerEventType {
    SINCE_LAST_MESSAGE_DELIVERED = 'SINCE_LAST_MESSAGE_DELIVERED',
    UPON_SUBMISSION = 'UPON_SUBMISSION',
}

export enum BucketSelectionRule {
    RANDOM = 'RANDOM',
    ROUND_ROBIN = 'ROUND_ROBIN',
    RESUME = 'RESUME',
}

export enum CupSelectionRule {
    RANDOM = 'RANDOM',
    HIGHEST_VIABLE_RANK = 'HIGHEST_VIABLE_RANK',
}

export const isBucketsAndCupsGroupEntity = (
    entity: ActivityEntity
): entity is BucketsAndCupsGroupGroupEntity => {
    return entity.type === ActivityType.LaunchBucketsAndCups
}

export function enforceBucketSelectionRuleValidation(
    selectionStep: BucketAndCupSelectionStepDTO,
    isTimerEnabled: boolean,
    enforcedCupSelectionRuleUnderResume?: CupSelectionRule,
    inPlace = false
) {
    const updated = inPlace ? selectionStep : { ...selectionStep }
    switch (selectionStep.bucketSelectionRule) {
        case BucketSelectionRule.RESUME:
            if (isTimerEnabled) {
                updated.numberOfCupsToPickFromBucket = 1
                if (enforcedCupSelectionRuleUnderResume) {
                    updated.cupSelectionRule = enforcedCupSelectionRuleUnderResume
                }
            }
            break
        default:
            if (!isTimerEnabled) {
                updated.timerConfig = undefined
            }
    }

    return updated
}

export function defaultTimerConfig<T extends TimerEventType>(
    type: T
): TimerConfigDTO & { type: T } {
    switch (type) {
        case TimerEventType.SINCE_LAST_MESSAGE_DELIVERED:
            return {
                type: TimerEventType.SINCE_LAST_MESSAGE_DELIVERED,
                elapsedTimeInSeconds: 0,
            } as SinceMessageDeliveredDTO as never
        case TimerEventType.UPON_SUBMISSION:
        default:
            return { type: TimerEventType.UPON_SUBMISSION } as UponSubmissionDTO as never
    }
}
