import type { Draft } from 'immer'

import {
    BaseTableDTO,
    TableCell,
    TableCellType,
    TableIconType,
    TableLegend,
    TableRow,
    TableSymbolType,
} from 'src/models/dto/items/BaseTableDTO'
import {
    getCellDataAfterDelete,
    replaceCellSymbol,
} from 'src/pages/module-builder/item-editors/table-editor/SymbolLegend'
import { Locale, LocalizeDefault } from '../../../models/dto/Locale'

export abstract class TableOperations<T extends BaseTableDTO> {
    protected abstract getTable(id: string): T

    protected abstract produceTable(id: string, update: (draft: Draft<T>) => void): void

    protected static createEmptyRows(numberOfRows: number, numberOfColumns: number): TableRow[] {
        return new Array(numberOfRows)
            .fill(1)
            .map(() => TableOperations.createEmptyRow(numberOfColumns))
    }

    protected static createEmptyRow(numberOfCells: number): TableRow {
        return {
            cells: new Array(numberOfCells).fill(1).map(() => undefined),
        }
    }

    protected static createEmptyHeaders(numberOfHeaders: number) {
        return new Array(numberOfHeaders).fill(1).map((_val, _ind) => ({
            headerI18N: LocalizeDefault<string>('', Locale.en_US),
        }))
    }

    public setNumberOfColumns(itemId: string, nextNumber: number) {
        this.produceTable(itemId, (entity) => {
            const numberOfColumns = entity.headers.length
            const difference = nextNumber - numberOfColumns
            if (difference > 0) {
                entity.headers.push(...TableOperations.createEmptyHeaders(difference))
                for (const { cells } of entity.rows) {
                    cells.push(...TableOperations.createEmptyRow(difference).cells)
                }
            } else {
                entity.headers = entity.headers.slice(0, nextNumber)
                for (const row of entity.rows) {
                    row.cells = row.cells.slice(0, nextNumber)
                }
            }
        })
    }

    public setNumberOfRows(itemId: string, nextNumber: number) {
        this.produceTable(itemId, (entity) => {
            const numberOfRows = entity.rows.length
            const difference = nextNumber - numberOfRows
            if (difference > 0) {
                entity.rows.push(
                    ...TableOperations.createEmptyRows(difference, entity.headers.length)
                )
            } else {
                entity.rows = entity.rows.slice(0, nextNumber)
            }
        })
    }

    public setColumnName(itemId: string, columnIndex: number, columnText: string, locale: Locale) {
        this.produceTable(itemId, (entity) => {
            entity.headers[columnIndex].headerI18N[locale] = columnText
        })
    }

    public setCellData(itemId: string, columnIndex: number, rowIndex: number, cellData: TableCell) {
        this.produceTable(itemId, (entity) => {
            if (
                rowIndex >= entity.rows.length ||
                columnIndex >= entity.rows[rowIndex].cells.length
            ) {
                return
            }
            entity.rows[rowIndex].cells[columnIndex] = cellData
        })
    }

    public toggleHarveyBallLegend(itemId: string, enabled: boolean) {
        this.produceTable(itemId, (entity) => {
            entity.harveyBallLegendEnabled = enabled
            if (this.getHarveyBallLegends(entity as T).length === 0 && enabled) {
                entity.legends.push(
                    {
                        iconType: TableIconType.HARVEY_BALL_EMPTY,
                        textI18N: LocalizeDefault('Low Amount'),
                    },
                    {
                        iconType: TableIconType.HARVEY_BALL_HALF_FULL,
                        textI18N: LocalizeDefault('Moderate Amount'),
                    },
                    {
                        iconType: TableIconType.HARVEY_BALL_FULL,
                        textI18N: LocalizeDefault('High Amount'),
                    }
                )
            } else if (!enabled) {
                entity.legends = this.getSymbolLegends(entity as T)
                for (const { cells } of entity.rows) {
                    cells.forEach((c, i) => {
                        if (c?.type === TableCellType.ICON) {
                            cells[i] = undefined
                        }
                    })
                }
            }
        })
    }

    public toggleSymbolLegend(itemId: string, enabled: boolean) {
        this.produceTable(itemId, (entity) => {
            entity.symbolLegendEnabled = enabled
            if (enabled) {
                entity.legends = this.getHarveyBallLegends(entity as T).concat({
                    iconType: undefined,
                    textI18N: LocalizeDefault(''),
                })
            } else {
                entity.legends = this.getHarveyBallLegends(entity as T)
                for (const { cells } of entity.rows) {
                    cells.forEach((c, i) => {
                        if (c?.type === TableCellType.SYMBOL) {
                            cells[i] = undefined
                        }
                    })
                }
            }
        })
    }

    public setScaleName(itemId: string, iconType: TableIconType, locale: Locale, value: string) {
        this.produceTable(itemId, (entity) => {
            for (const l of entity.legends) {
                if (l.iconType === iconType) {
                    l.textI18N[locale] = value
                }
            }
        })
    }

    public setSymbolType(
        itemId: string,
        symbolType: TableSymbolType,
        _unusedLocale: Locale,
        symbolLegendIndex: number
    ) {
        this.produceTable(itemId, (entity) => {
            if (symbolLegendIndex >= entity.legends.length) {
                return
            }

            const previousSymbol = entity.legends[symbolLegendIndex].iconType as TableSymbolType
            entity.legends[symbolLegendIndex].iconType = symbolType
            this.modifyExistingSymbolCellsData(entity, previousSymbol, symbolType)
        })
    }

    public setSymbolLegend(
        itemId: string,
        locale: Locale,
        symbolLegendText: string,
        symbolLegendIndex: number
    ) {
        this.produceTable(itemId, (entity) => {
            if (symbolLegendIndex >= entity.legends.length) {
                return
            }
            entity.legends[symbolLegendIndex].textI18N[locale] = symbolLegendText
        })
    }

    public deleteSymbolLegend(itemId: string, index: number) {
        this.produceTable(itemId, (entity) => {
            const newLegends = entity.legends
            const deletedLegend = newLegends.splice(index, 1)[0]
            entity.legends = newLegends
            if (deletedLegend.iconType && deletedLegend.textI18N) {
                const deletedSymbol: TableSymbolType = deletedLegend.iconType as TableSymbolType
                this.modifyExistingSymbolCellsData(entity, deletedSymbol)
            }
        })
    }

    public getSymbolLegends(itemIdOrEntity: string | T): TableLegend[] {
        const legends =
            typeof itemIdOrEntity === 'string'
                ? this.getTable(itemIdOrEntity).legends
                : itemIdOrEntity.legends
        return legends.filter((legend) => {
            return !legend.iconType || TableSymbolType[legend.iconType]
        })
    }

    public getHarveyBallLegends(itemIdOrEntity: string | T): TableLegend[] {
        const legends =
            typeof itemIdOrEntity === 'string'
                ? this.getTable(itemIdOrEntity).legends
                : itemIdOrEntity.legends
        return legends.filter((legend) => {
            return legend.iconType && TableIconType[legend.iconType]
        })
    }

    public createNewSymbolEmptyLegend(itemId: string) {
        this.produceTable(itemId, (entity) => {
            entity.legends.push({
                iconType: undefined,
                textI18N: LocalizeDefault(''),
            })
        })
    }

    public getValueMapForSymbols(itemId: string, locale: Locale): Map<TableSymbolType, string> {
        const valueMap: Map<TableSymbolType, string> = new Map()
        this.getSymbolLegends(itemId).forEach((legend) => {
            if (legend.iconType && legend.textI18N[locale]) {
                valueMap.set(legend.iconType as TableSymbolType, legend.textI18N[locale] || '')
            }
        })
        return valueMap
    }

    public hasValidSymbolLegends(itemId: string, locale: Locale): boolean {
        return this.getValueMapForSymbols(itemId, locale).size > 0
    }

    public getFirstValidSymbolLegend(itemId: string, locale: Locale): TableSymbolType {
        return Array.from(this.getValueMapForSymbols(itemId, locale).keys())[0]
    }

    /*
    This method looks through all the symbols of all locales of all the cells to remove or update removedSymbol
    from the cell data
     */
    private modifyExistingSymbolCellsData(
        entity: Draft<T>,
        updateSymbol: TableSymbolType,
        newSymbol?: TableSymbolType
    ) {
        entity.rows = entity.rows.map((row) => ({
            cells: row.cells.map((cell) => {
                if (cell?.type !== TableCellType.SYMBOL || !cell.dataI18N) {
                    return cell
                }
                Object.values<Locale>(Locale)
                    .filter((locale) => cell.dataI18N[locale])
                    .forEach((locale) => {
                        const symbolIndices: number[] | undefined = cell.dataI18N[locale]
                            ?.split(',')
                            .reduce((result: number[], symbol: string, index: number) => {
                                if (TableSymbolType[symbol] === updateSymbol) {
                                    result.push(index)
                                }
                                return result
                            }, [])
                        if (symbolIndices && symbolIndices.length > 0) {
                            let data = cell.dataI18N[locale]
                            //if this method is called with newSymbol then it's a legend update
                            //Otherwise it's a legend deletion
                            if (newSymbol) {
                                symbolIndices.forEach((index) => {
                                    data = replaceCellSymbol(data || '', index, newSymbol)
                                })
                            } else {
                                symbolIndices
                                    .reverse()
                                    .forEach(
                                        (index) =>
                                            (data = getCellDataAfterDelete(data || '', index))
                                    )
                            }
                            cell.dataI18N[locale] = data
                        }
                    })
                return cell
            }),
        }))
    }

    public static hasAtLeastOneHarveyBallLegend(legends: TableLegend[]) {
        const allHarveyBallTypes: string[] = Object.values(TableIconType)
        return legends.some((l) => (l.iconType ? allHarveyBallTypes.includes(l.iconType) : false))
    }

    public static hasAtLeastOneSymbolLegend(legends: TableLegend[]) {
        const allSymbolTypes: string[] = Object.values(TableSymbolType)
        return legends.some((l) => (l.iconType ? allSymbolTypes.includes(l.iconType) : false))
    }

    public getCellTypeDefault(id: string, cellType: TableCellType, locale: Locale) {
        switch (cellType) {
            case TableCellType.AVAILABILITY:
                return {
                    dataI18N: LocalizeDefault<string>('false', locale),
                    type: TableCellType.AVAILABILITY,
                }
            case TableCellType.ICON:
                return {
                    dataI18N: LocalizeDefault<string>('HARVEY_BALL_EMPTY', locale),
                    type: TableCellType.ICON,
                }
            case TableCellType.SYMBOL:
                if (this.getFirstValidSymbolLegend(id, locale)) {
                    return {
                        dataI18N: LocalizeDefault<string>(
                            this.getFirstValidSymbolLegend(id, locale),
                            locale
                        ),
                        type: TableCellType.SYMBOL,
                    }
                } else {
                    return {
                        dataI18N: LocalizeDefault<string>('', locale),
                        type: TableCellType.TEXT,
                    }
                }
            default:
                return {
                    dataI18N: LocalizeDefault<string>('', locale),
                    type: TableCellType.TEXT,
                }
        }
    }
}
