import {
    CompletionCodeItemDTO,
    defaultCompletionCodeItemDTO,
} from 'src/models/dto/items/CompletionCodeItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.CompletionCode

export class CompletionCodeHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => CompletionCodeHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<CompletionCodeItemDTO>
    }

    static create(): CompletionCodeItemDTO {
        return defaultCompletionCodeItemDTO()
    }

    private static assign(id: string, obj: Partial<CompletionCodeItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updateStatement(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.statementI18N[locale] = nextValue
        })
    }
}
