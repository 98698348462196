import { FreeTextDisplayType } from 'src/models/dto/items/FreeTextItemDTO'
import { LocalizedAttribute, LocalizeDefault } from '../../../models/dto/Locale'
import { factories, item, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { FreeTextItemDTO } from './../../../models/dto/items/FreeTextItemDTO'
import { ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, Stores } from './../../Store'

const itemType = ItemType.FreeText

export class FreetextHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => FreetextHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<FreeTextItemDTO>
    }

    static create(): FreeTextItemDTO {
        return {
            ...item(),
            itemType,
            freeTextDisplayType: FreeTextDisplayType.SingleLine,
            characterLimit: 50,
            questionI18N: LocalizeDefault<string>(''),
            ppt: 'FreeText',
        }
    }

    private static assign(id: string, obj: Partial<FreeTextItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateQuestionI18N(id: string, questionI18N: LocalizedAttribute<string>) {
        this.assign(id, { questionI18N })
    }

    static updateCharacterLimit(id: string, characterLimit: number) {
        this.assign(id, { characterLimit })
    }

    static updateFreeTextDisplayType(id: string, freeTextDisplayType: FreeTextDisplayType) {
        this.assign(id, { freeTextDisplayType })
    }
}
