import React, { useEffect, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconBin, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner } from '@amzn/stencil-react-components/message-banner'
import { Text } from '@amzn/stencil-react-components/text'
import { ToggleSwitch } from '@amzn/stencil-react-components/toggle-switch'

import { ModuleGroupRenderOption } from 'src/components/module-groups/ModuleGroupRenderOption'
import { ModuleGroupModuleMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { PECCreateEditValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'
import { FunctionalScoreDTO } from 'src/models/dto/module-groups/PECGroupDTO'
import { focusOnId } from 'src/pages/module-builder/focus'

export interface ToggleFunctionalScoreProps {
    functionalScoreEnabled: boolean
    setFunctionalScoreEnabled: (functionalScoreEnabled: boolean) => void
    functionalScores: FunctionalScoreDTO[]
    setFunctionalScores: (functionalScores: FunctionalScoreDTO[]) => void
    moduleScores: string[]
    defaultAssessmentModule?: ModuleGroupModuleMetadata
    setValidationMessage: (validationMessage: string) => void
    pecValidations?: PECCreateEditValidationDTO
    setPecValidations?: (pecValidations: PECCreateEditValidationDTO) => void
}

export interface FunctionalScoreCreationListProps {
    functionalScores: FunctionalScoreDTO[]
    updateFunctionalScoreLabel: (
        index: number,
        functionalScore: FunctionalScoreDTO,
        value: string
    ) => void
    availableModuleScores: string[]
    defaultModuleVersionId: string
    changeModuleScore: (
        index: number,
        functionalScore: FunctionalScoreDTO,
        selectedScore: string
    ) => void
    deleteFunctionalScore: (index: number) => void
    addFunctionalScore: () => void
    isAddDisabled: boolean
    isEditMode: boolean
    setValidationMessage: (validationMessage: string) => void
    pecValidations?: PECCreateEditValidationDTO
    setPecValidations?: (pecValidations: PECCreateEditValidationDTO) => void
}

export const ALPHA_NUMERIC_UNDERSCORE = /^[a-zA-Z0-9_]+$/

export const FunctionalScoreCreationList = (props: FunctionalScoreCreationListProps) => {
    const newFunctionalEquivalency = props.pecValidations?.newFunctionalEquivalency

    const clearModuleScoreError = (index: number) => {
        if (props.setPecValidations && newFunctionalEquivalency) {
            const updated = [...newFunctionalEquivalency]
            updated[index] = {
                scoreLabel: updated[index]?.scoreLabel ?? '',
                moduleScore: '',
            }
            props.setPecValidations({
                ...props.pecValidations,
                newFunctionalEquivalency: updated,
            })
            props.setValidationMessage('')
        }
    }

    const clearScoreLabelError = (index: number) => {
        if (props.setPecValidations && newFunctionalEquivalency) {
            const updated = [...newFunctionalEquivalency]
            updated[index] = {
                scoreLabel: '',
                moduleScore: updated[index] ? updated[index].moduleScore : '',
            }
            props.setPecValidations({
                ...props.pecValidations,
                newFunctionalEquivalency: updated,
            })
        }
        props.setValidationMessage('')
    }

    const deleteFunctionalScoreError = (index: number) => {
        if (props.setPecValidations && newFunctionalEquivalency) {
            const updated = [...newFunctionalEquivalency]
            updated.splice(index, 1)
            props.setPecValidations({
                ...props.pecValidations,
                newFunctionalEquivalency: updated,
            })
        }
        props.setValidationMessage('')
    }

    return (
        <>
            <ul>
                {props.functionalScores.map((functionalScore, index) => (
                    <li key={index} aria-label={`Added functional score ${index + 1}`}>
                        <Row gridGap='S200' padding={{ top: 'S200', bottom: 'S400' }}>
                            <View width='90%'>
                                <InputWrapper
                                    id='functional-score-wrapper'
                                    labelText={`Added Functional Score Name ${index + 1}`}
                                    required
                                    error={
                                        newFunctionalEquivalency && newFunctionalEquivalency[index]
                                            ? !!newFunctionalEquivalency[index].scoreLabel
                                            : undefined
                                    }
                                    footer={
                                        newFunctionalEquivalency &&
                                        newFunctionalEquivalency[index]?.scoreLabel
                                            ? newFunctionalEquivalency[index].scoreLabel
                                            : undefined
                                    }
                                >
                                    {(inputProps) => (
                                        <Input
                                            {...inputProps}
                                            key={index}
                                            required
                                            value={functionalScore.functionalScoreLabel}
                                            id={`functional-${index}-input`}
                                            name={`Functional Score Name ${index + 1}`}
                                            data-test-id={`functional-${index}-input`}
                                            onChange={(e) => {
                                                props.updateFunctionalScoreLabel(
                                                    index,
                                                    functionalScore,
                                                    e.target.value
                                                )
                                                clearScoreLabelError(index)
                                            }}
                                        />
                                    )}
                                </InputWrapper>
                                <InputWrapper
                                    id={`functional-${index}-wrapper`}
                                    labelText={`Module Score ${index + 1}`}
                                    required
                                    error={
                                        newFunctionalEquivalency && newFunctionalEquivalency[index]
                                            ? !!newFunctionalEquivalency[index].moduleScore
                                            : undefined
                                    }
                                    footer={
                                        newFunctionalEquivalency &&
                                        newFunctionalEquivalency[index]?.moduleScore
                                            ? newFunctionalEquivalency[index].moduleScore
                                            : undefined
                                    }
                                >
                                    {(inputProps) => (
                                        <Select
                                            {...inputProps}
                                            options={['', ...props.availableModuleScores]}
                                            isSearchable={true}
                                            value={
                                                functionalScore.moduleVersionScoreLabelMap.has(
                                                    props.defaultModuleVersionId
                                                )
                                                    ? functionalScore.moduleVersionScoreLabelMap.get(
                                                          props.defaultModuleVersionId
                                                      )
                                                    : ''
                                            }
                                            data-test-id={`functional-${index}-select`}
                                            onChange={(selectedScore: string) => {
                                                props.changeModuleScore(
                                                    index,
                                                    functionalScore,
                                                    selectedScore
                                                )
                                                clearModuleScoreError(index)
                                            }}
                                            renderOption={ModuleGroupRenderOption}
                                        />
                                    )}
                                </InputWrapper>
                            </View>
                            <Col>
                                <Spacer height='S500' />
                                <Button
                                    icon={<IconBin title='Delete item' />}
                                    isDestructive
                                    data-test-id={`functional-${index}-delete`}
                                    variant={ButtonVariant.Tertiary}
                                    onClick={() => {
                                        props.deleteFunctionalScore(index)
                                        focusOnId('add-functional-score-button')
                                        deleteFunctionalScoreError(index)
                                    }}
                                    aria-disabled={
                                        !props.isEditMode && props.functionalScores.length <= 1
                                    }
                                />
                            </Col>
                        </Row>
                        <Hr size='wide' />
                    </li>
                ))}
            </ul>
            {!props.isAddDisabled && (
                <View justifyContent='left'>
                    <Button
                        data-test-id='add-functional-score-button'
                        id='add-functional-score-button'
                        variant={ButtonVariant.Tertiary}
                        size={ButtonSize.Small}
                        onClick={() => props.addFunctionalScore()}
                        aria-disabled={props.isAddDisabled}
                    >
                        <Row gridGap='S300'>
                            <IconPlus title={'Add functional score'} />
                            <Text fontSize='T100' lineHeight={1.3}>
                                Add a Functional Score
                            </Text>
                        </Row>
                    </Button>
                </View>
            )}
        </>
    )
}

export const ToggleFunctionalScore = (props: ToggleFunctionalScoreProps) => {
    const [selectedModuleScores, setSelectedModuleScores] = useState<string[]>([])

    useEffect(() => {
        const alreadySelectedScores: string[] | undefined =
            props.functionalScores && props.functionalScores.length > 0
                ? props.functionalScores
                      ?.filter((functionalScore) =>
                          functionalScore.moduleVersionScoreLabelMap.get(
                              props.defaultAssessmentModule?.versionId as string
                          )
                      )
                      .map(
                          (functionalScore) =>
                              functionalScore.moduleVersionScoreLabelMap.get(
                                  props.defaultAssessmentModule?.versionId as string
                              ) as string
                      )
                : undefined
        setSelectedModuleScores(
            (alreadySelectedScores ?? []).filter((element) => {
                return element !== ''
            })
        )
    }, [props.defaultAssessmentModule?.versionId, props.functionalScores])

    const defaultFunctionalScore: FunctionalScoreDTO = {
        functionalScoreLabel: '',
        moduleVersionScoreLabelMap: new Map<string, string>(),
    }

    const onToggle = (newState: boolean) => {
        if (newState) {
            props.setFunctionalScores([defaultFunctionalScore])
        } else {
            props.setFunctionalScores([])
        }
        props.setFunctionalScoreEnabled(newState)
    }

    const addFunctionalScore = () => {
        props.setFunctionalScores([...props.functionalScores, defaultFunctionalScore])
    }

    const changeModuleScore = (
        index: number,
        functionalScore: FunctionalScoreDTO,
        moduleScore: string
    ) => {
        functionalScore = {
            ...functionalScore,
            moduleVersionScoreLabelMap: functionalScore.moduleVersionScoreLabelMap.set(
                props.defaultAssessmentModule?.versionId as string,
                moduleScore
            ),
        }

        const updatedFunctionalScores = [...props.functionalScores]
        updatedFunctionalScores.splice(index, 1, functionalScore)
        props.setFunctionalScores(updatedFunctionalScores)
    }

    const updateFunctionalScoreLabel = (
        index: number,
        functionalScore: FunctionalScoreDTO,
        value: string
    ) => {
        functionalScore = {
            ...functionalScore,
            functionalScoreLabel: value || '',
        }
        const updatedFunctionalScores = [...props.functionalScores]
        updatedFunctionalScores[index] = functionalScore
        props.setFunctionalScores(updatedFunctionalScores)
    }

    const deleteFunctionalScore = (index: number) => {
        const updatedFunctionalScores = [...props.functionalScores]
        updatedFunctionalScores.splice(index, 1)
        props.setFunctionalScores([...updatedFunctionalScores])
    }

    const getAvailableModuleScores = () => {
        return props.moduleScores && props.moduleScores.length > 0
            ? props.moduleScores.filter((score) => !selectedModuleScores.includes(score))
            : []
    }

    return (
        <Col gridGap='S300'>
            {props.defaultAssessmentModule && (
                <Col gridGap='S300'>
                    <Row gridGap='S600'>
                        <Text fontSize='S200'>Functional Score?</Text>
                        <ToggleSwitch
                            checked={props.functionalScoreEnabled}
                            onChange={(newState: boolean) => {
                                onToggle(newState)
                                focusOnId('functional-0-input')
                            }}
                            data-test-id='functional-score-toggle'
                            disabled={props.moduleScores.length === 0}
                        />
                    </Row>
                    <MessageBanner isDismissible>
                        If enabled: The PEC contains evaluation modules that have module level
                        scores that will function identically in the workflow level overall score
                        formula. These module level scores are not measurement equivalent and can
                        not be substituted with each other.
                    </MessageBanner>
                </Col>
            )}
            {props.defaultAssessmentModule && props.functionalScoreEnabled && (
                <>
                    <FunctionalScoreCreationList
                        functionalScores={props.functionalScores}
                        updateFunctionalScoreLabel={updateFunctionalScoreLabel}
                        availableModuleScores={getAvailableModuleScores()}
                        defaultModuleVersionId={props.defaultAssessmentModule.versionId}
                        changeModuleScore={changeModuleScore}
                        deleteFunctionalScore={deleteFunctionalScore}
                        addFunctionalScore={addFunctionalScore}
                        isAddDisabled={props.functionalScores.length === props.moduleScores.length}
                        isEditMode={false}
                        pecValidations={props.pecValidations}
                        setPecValidations={props.setPecValidations}
                        setValidationMessage={props.setValidationMessage}
                    />
                </>
            )}
        </Col>
    )
}
