import { produce } from 'immer'
import { cloneDeep, isUndefined } from 'lodash'
import { v4 } from 'uuid'

import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import {
    EvaluationScoreDTO,
    ModuleEntity,
    ModuleLayout,
    ModuleType,
    ModuleUserType,
} from 'src/models/dto/ModuleDTO'
import { ModuleService } from 'src/services/backend/ModuleService'
import { ActivityEntityService } from 'src/services/EntityServices/ActivityEntityService'
import { Store, STORE_ACTION } from '../Store'

const removeLocation = (obj: unknown, localeDiff: Locale[]) => {
    if (obj === null || obj === undefined) {
        return
    }

    if (typeof obj !== 'object') {
        return
    }

    if (Array.isArray(obj)) {
        const arr = obj as unknown[]
        for (let i = 0; i < arr.length; i++) {
            removeLocation(arr[i], localeDiff)
        }
        return
    }

    for (const key of Object.keys(obj)) {
        if (!key.includes('I18N')) {
            removeLocation(obj[key], localeDiff)
            continue
        }

        if (Array.isArray(obj[key])) {
            const arr = obj[key] as LocalizedAttribute<unknown>[]
            for (let j = 0; j < arr.length; j++) {
                for (let i = 0; i < localeDiff.length; i++) {
                    delete arr[j][localeDiff[i]]
                }
            }
            continue
        }

        const loc = obj[key] as LocalizedAttribute<unknown>
        for (let i = 0; i < localeDiff.length; i++) {
            delete loc[localeDiff[i]]
        }
    }
}

/**
 * ModuleEntityService is an example of a Service that serves a particular Entity
 *
 * There are 3 things we expect in such a service.
 * 1. The selector must be exported, this is so that we can reference the underlying store
 * 2. This service must implement "init", which creates the store
 * 3. This service must provide a create function which creates a valid Entity and stores it
 *
 * Unrequired but good practices
 * 1. The service provides means to evaluate and validate the entities based on the last action
 * 2. The service provides means to assemble a serialized output representing the Entity
 */
export const MODULE_ENTITY_STORE_SELECTOR = 'ModuleEntity'

export class ModuleEntityService {
    static store: Store<ModuleEntity>

    static init() {
        this.store = new Store<ModuleEntity>(MODULE_ENTITY_STORE_SELECTOR)
    }

    static get(entityId: string): ModuleEntity {
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    static create(name: string): ModuleEntity {
        const entity: ModuleEntity = {
            name: name,
            id: v4(),
            version: v4(),
            availableLocales: [Locale.en_US],
            autoProgressionOn: false,
            status: '',
            workflowIds: [],
            compositeScores: [],
            instructionalContentId: '',
            usingMediaManager: true,
        }

        this.insert(entity)
        return entity
    }

    static createBlank(): ModuleEntity {
        return this.create('')
    }

    static insert(entity: ModuleEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.version,
            payload: entity,
        })
    }

    //  we want to encourage usage of specific update methods
    static update(entity: ModuleEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.version,
            payload: entity,
        })
    }

    static updateName(entityId: string, name: string) {
        this.store.produce(entityId, (d) => (d.name = name))
    }

    static updateMUPPItemBankId(entityId: string, muppItemBankId: string) {
        this.store.produce(entityId, (d) => (d.muppItemBankId = muppItemBankId))
    }

    static updateWorkflow(entityId: string, workflowIds: string[]) {
        this.store.produce(entityId, (d) => (d.workflowIds = workflowIds))
    }

    static updateModuleType(entityId: string, moduleType: ModuleType) {
        this.store.produce(entityId, (d) => (d.moduleType = moduleType))
    }

    static updateLayout(entityId: string, layout: ModuleLayout) {
        this.store.produce(entityId, (d) => (d.layout = layout))
    }

    static updateUserType(entityId: string, userType: ModuleUserType) {
        this.store.produce(entityId, (d) => (d.userType = userType))
    }

    static updateEvaluationScores(entityId: string, evaluationModule: boolean) {
        let entity = this.store.get(entityId)

        if (!evaluationModule) {
            while (entity.compositeScores.length > 0) {
                entity = this.store.get(entityId)
                this.deleteEvaluationScore(entityId, 0)
            }
        } else {
            const newEvaluationScore: EvaluationScoreDTO = {
                compositeScoreLabel: '',
                compositeScoreExpression: '',
            }
            this.addEvaluationScore(entityId, newEvaluationScore)
        }
    }

    static updateAutoProgressionOn(entityId: string, autoProgressionOn: boolean) {
        this.store.produce(entityId, (d) => (d.autoProgressionOn = autoProgressionOn))
    }

    static updateMturkPaymentCode(entityId: string, mturkPaymentCodeItemId: string) {
        this.store.produce(entityId, (d) => (d.mturkPaymentCodeItemId = mturkPaymentCodeItemId))
    }

    static updateProgressBar(entityId: string, enableProgressBar: boolean) {
        this.store.produce(entityId, (d) => (d.evaluationModule = enableProgressBar))
    }

    static updateInstructionalContent(entityId: string, instructionId: string) {
        this.store.produce(entityId, (d) => (d.instructionalContentId = instructionId))
    }

    static addActivity(entityId: string, activityId: string, location?: number) {
        this.store.produce(entityId, (entity) => {
            if (isUndefined(location)) {
                entity.workflowIds.push(activityId)
            } else {
                entity.workflowIds.splice(location, 0, activityId)
            }
        })
    }

    static removeActivity(entityId: string, activityId: string) {
        this.store.produce(entityId, (entity) => {
            entity.workflowIds = entity.workflowIds.filter((id) => id !== activityId)
        })
    }

    static moveActivity(entityId: string, activityId: string, toIndex: number) {
        this.store.produce(entityId, (entity) => {
            const temp = entity.workflowIds.filter((id) => id !== activityId)
            entity.workflowIds = [...temp.slice(0, toIndex), activityId, ...temp.slice(toIndex)]
        })
    }

    static addAvailableLocale(entityId: string, locale: Locale) {
        this.store.produce(entityId, (entity) => {
            entity.availableLocales = [...entity.availableLocales, locale].sort()
        })
    }

    static setAvailableLocales(entityId: string, locales: Locale[]) {
        const { version: versionId, availableLocales: prevAvailableLocales } =
            this.store.get(entityId)
        this.store.produce(entityId, (draft) => {
            draft.availableLocales = [Locale.en_US, ...locales].sort()
        })

        const localeDiff = prevAvailableLocales.filter(
            (l) => !locales.includes(l) && l !== Locale.en_US
        )

        if (localeDiff.length === 0) {
            return
        }

        const dto = ModuleService.getFullModuleDTO(ModuleService.serializeModuleDTO(versionId))
        ModuleService.updateModule(produce(dto, (draft) => removeLocation(draft, localeDiff)))
    }

    static deleteAvailableLocale(entityId: string, locale: Locale) {
        this.store.produce(entityId, (entity) => {
            entity.availableLocales = entity.availableLocales.filter((l) => l != locale).sort()
        })
    }

    static duplicate(moduleId: string, newName: string | undefined = undefined): ModuleEntity {
        const entity = this.store.get(moduleId)

        const newId = v4()
        const newVersionId = v4()
        const duplicate = {
            ...cloneDeep(entity),
            id: newId,
            version: newVersionId,
            name: newName ? newName : `${entity.name}-${newId}`,
        }

        duplicate.workflowIds = duplicate.workflowIds.map(
            (id) => ActivityEntityService.duplicate(id).id
        )

        this.insert(duplicate)
        return duplicate
    }

    static addEvaluationScore(entityId: string, evaluationScore: EvaluationScoreDTO) {
        this.store.produce(entityId, (entity) => {
            if (!entity.compositeScores) {
                entity.compositeScores = [evaluationScore]
                return
            }

            entity.compositeScores.push(evaluationScore)
        })
    }

    static deleteEvaluationScore(entityId: string, scoreIndex: number) {
        this.store.produce(entityId, (entity) => {
            entity.compositeScores.splice(scoreIndex, 1)
        })
    }

    static updateEvaluationScoreLabel(entityId: string, scoreIndex: number, newValue: string) {
        this.store.produce(entityId, (entity) => {
            entity.compositeScores[scoreIndex].compositeScoreLabel = newValue
        })
    }

    static updateEvaluationScoreExpression(entityId: string, scoreIndex: number, newValue: string) {
        this.store.produce(entityId, (entity) => {
            entity.compositeScores[scoreIndex].compositeScoreExpression = newValue
        })
    }

    static moveCompositeScore(entityId: string, fromIndex: number, toIndex: number) {
        this.store.produce(entityId, (entity) => {
            ;[entity.compositeScores[toIndex], entity.compositeScores[fromIndex]] = [
                entity.compositeScores[fromIndex],
                entity.compositeScores[toIndex],
            ]
        })
    }

    static updateAssessmentMetadataEnabled(entityId: string, enabled: boolean) {
        this.store.produce(entityId, (entity) => {
            entity.assessmentMetadata = enabled ? entity.assessmentMetadata ?? {} : undefined
        })
    }

    static updateMinTimeToCompleteInMinutes(
        entityId: string,
        minTimeToCompleteModuleInMinutes?: number
    ) {
        this.store.produce(entityId, (entity) => {
            if (!entity.assessmentMetadata) {
                entity.assessmentMetadata = {}
            }
            entity.assessmentMetadata.minTimeToCompleteModuleInMinutes =
                minTimeToCompleteModuleInMinutes
        })
    }

    static updateMaxTimeToCompleteInMinutes(
        entityId: string,
        maxTimeToCompleteModuleInMinutes?: number
    ) {
        this.store.produce(entityId, (entity) => {
            if (!entity.assessmentMetadata) {
                entity.assessmentMetadata = {}
            }
            entity.assessmentMetadata.maxTimeToCompleteModuleInMinutes =
                maxTimeToCompleteModuleInMinutes
        })
    }

    static updateResultValidityInDays(entityId: string, resultValidityInDays?: number) {
        this.store.produce(entityId, (entity) => {
            if (!entity.assessmentMetadata) {
                entity.assessmentMetadata = {}
            }
            entity.assessmentMetadata.resultValidityInDays = resultValidityInDays
        })
    }

    static updateModuleTimerEnabled(entityId: string, enabled: boolean) {
        this.store.produce(entityId, (entity) => {
            entity.moduleTimerConfig = enabled ? entity.moduleTimerConfig ?? {} : undefined
        })
    }

    static updateTimeLimit(entityId: string, timeLimit?: number) {
        this.store.produce(entityId, (entity) => {
            if (!entity.moduleTimerConfig) {
                entity.moduleTimerConfig = {}
            }
            entity.moduleTimerConfig.timeLimit = timeLimit
        })
    }

    static updateWarningTimerEnabled(entityId: string, enabled: boolean) {
        this.store.produce(entityId, (entity) => {
            if (!entity.moduleTimerConfig) {
                entity.moduleTimerConfig = {}
            }
            entity.moduleTimerConfig.warningConfigs = enabled
                ? entity.moduleTimerConfig?.warningConfigs ?? []
                : undefined
        })
    }

    static addWarningTimer(entityId: string, value: number) {
        this.store.produce(entityId, (entity) => {
            if (!entity.moduleTimerConfig) {
                entity.moduleTimerConfig = { warningConfigs: [] }
            }
            if (!entity.moduleTimerConfig.warningConfigs) {
                entity.moduleTimerConfig.warningConfigs = []
            }
            entity.moduleTimerConfig.warningConfigs.push({ timeRemaining: value })
        })
    }

    static updateWarningTimer(entityId: string, index: number, nextValue: number) {
        this.store.produce(entityId, (entity) => {
            if (!entity.moduleTimerConfig) {
                entity.moduleTimerConfig = { warningConfigs: [] }
            }
            if (!entity.moduleTimerConfig.warningConfigs) {
                entity.moduleTimerConfig.warningConfigs = []
            }
            entity.moduleTimerConfig.warningConfigs[index].timeRemaining = nextValue
        })
    }

    static getModuleWarningConfig(entityId: string, index: number) {
        const entity = this.store.get(entityId)
        const warningConfigs = entity.moduleTimerConfig?.warningConfigs
        if (warningConfigs && index >= 0 && warningConfigs.length > index) {
            return warningConfigs[index]
        }
        return { timeRemaining: 0 }
    }

    static updateUsingMediaManager(entityId: string, usingMediaManager: boolean) {
        this.store.produce(entityId, (d) => (d.usingMediaManager = usingMediaManager))
    }
}
