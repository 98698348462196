import { ContentItemDTO, defaultContentItemDTO } from 'src/models/dto/items/ContentItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.Content

export class ContentHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => ContentHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ContentItemDTO>
    }

    static create(): ContentItemDTO {
        return defaultContentItemDTO()
    }

    private static assign(id: string, obj: Partial<ContentItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateText(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.textI18N[locale] = nextValue
        })
    }

    static updateTitle(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            if (!entity.titleI18N) {
                entity.titleI18N = LocalizeDefault(nextValue, locale)
            } else {
                entity.titleI18N[locale] = nextValue
            }
        })
    }
}
