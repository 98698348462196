import { Draft } from 'immer'

import {
    AdaptiveEngineItemParametersDTO,
    AdaptiveEngineSelectionGroupEntity,
    ItemPoolDistributionDTO,
    LastStandardErrorThresh,
} from 'src/models/dto/activities/AdaptiveEngineSelectionGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'

const activityType = ActivityType.LaunchAdaptiveEngine

export enum TerminationCriteriaMode {
    StandardErrorThreshold = 'StandardErrorThreshold',
    MaxLength = 'MaxLength',
}

export class AdaptiveEngineHandler {
    static init() {
        factories.set(activityType, () => AdaptiveEngineHandler.create())
    }

    static get(entityId: string): AdaptiveEngineSelectionGroupEntity {
        return ActivityEntityService.get(entityId) as AdaptiveEngineSelectionGroupEntity
    }

    static create(): AdaptiveEngineSelectionGroupEntity {
        const newActivity = activity()
        return {
            ...newActivity,
            type: activityType,
            ppt: activityType,
            name: newActivity.id,
            adaptiveEngineItemParameters: [],
            itemPoolDistributions: [
                {
                    itemPoolId: '',
                    percentage: 0,
                    minItems: 0,
                },
            ],
            experimentalItemPoolID: '',
            examTerminationCriteria: {
                maxLength: 0,
                standardErrorThresh: null,
            },
        } as AdaptiveEngineSelectionGroupEntity
    }

    private static assign(id: string, obj: Partial<AdaptiveEngineSelectionGroupEntity>) {
        ActivityEntityService.store.assign(id, obj)
    }

    private static produce(
        id: string,
        update: (draft: Draft<AdaptiveEngineSelectionGroupEntity>) => void
    ) {
        ActivityEntityService.store.produce(id, update)
    }

    static setParameters(
        entityId: string,
        adaptiveEngineItemParameters: AdaptiveEngineItemParametersDTO[]
    ) {
        this.assign(entityId, { adaptiveEngineItemParameters })
    }

    private static changeItemPoolDistribution(
        entityId: string,
        index: number,
        update: (draft: Draft<ItemPoolDistributionDTO>) => void
    ) {
        this.produce(entityId, ({ itemPoolDistributions }) => {
            /* istanbul ignore next */
            if (index >= itemPoolDistributions.length) {
                return
            }

            update(itemPoolDistributions[index])
        })
    }

    static setItemPoolId(entityId: string, index: number, itemPoolId: string) {
        this.changeItemPoolDistribution(entityId, index, (draft) => {
            draft.itemPoolId = itemPoolId
        })
    }

    static setItemPoolDistributionPercentage(entityId: string, index: number, percentage: number) {
        this.changeItemPoolDistribution(entityId, index, (draft) => {
            draft.percentage = percentage
        })
    }

    static setItemPoolDistributionMinItems(entityId: string, index: number, minItems: number) {
        this.changeItemPoolDistribution(entityId, index, (draft) => {
            draft.minItems = minItems
        })
    }

    static addItemPool(entityId: string, itemPoolId = '') {
        this.produce(entityId, (entity) => {
            entity.itemPoolDistributions.push({
                itemPoolId,
                percentage: 0,
                minItems: 0,
            })
        })
    }

    static removeItemPool(entityId: string, index: number) {
        this.produce(entityId, (entity) => {
            if (index < 0 || index >= entity.itemPoolDistributions.length) {
                return
            }
            entity.itemPoolDistributions.splice(index, 1)
        })
    }

    static getTerminationCriteriaMode(
        entity: AdaptiveEngineSelectionGroupEntity
    ): TerminationCriteriaMode {
        return typeof entity.examTerminationCriteria.standardErrorThresh === 'number'
            ? TerminationCriteriaMode.StandardErrorThreshold
            : TerminationCriteriaMode.MaxLength
    }

    static setTerminationCriteriaMode(entityId: string, mode: TerminationCriteriaMode) {
        this.produce(entityId, (entity) => {
            const {
                maxLength,
                standardErrorThresh,
                [LastStandardErrorThresh]: lastStandardErrorThresh,
            } = entity.examTerminationCriteria
            entity.examTerminationCriteria =
                mode === TerminationCriteriaMode.MaxLength
                    ? {
                          maxLength: maxLength ?? 0,
                          standardErrorThresh: null,
                          [LastStandardErrorThresh]: standardErrorThresh ?? null,
                      }
                    : {
                          maxLength: maxLength ?? 0,
                          standardErrorThresh: standardErrorThresh ?? lastStandardErrorThresh ?? 0,
                      }
        })
    }

    static setStandardErrorThreshold(entityId: string, standardErrorThresh: number) {
        this.produce(entityId, (entity) => {
            entity.examTerminationCriteria.standardErrorThresh = standardErrorThresh
        })
    }

    static setMaxLength(entityId: string, maxLength: number) {
        this.produce(entityId, (entity) => {
            entity.examTerminationCriteria.maxLength = maxLength
        })
    }

    static setExperimentalItemPoolId(entityId: string, itemPoolId: string) {
        this.assign(entityId, { experimentalItemPoolID: itemPoolId })
    }

    static inferItemPoolId({
        itemPoolDistributions: dists,
        experimentalItemPoolID: experimental,
    }: AdaptiveEngineSelectionGroupEntity) {
        return dists.length > 0 ? dists[0].itemPoolId : experimental
    }
}
