import {
    AudioEvaluationItemDTO,
    defaultAudioEvaluationDTO,
    ScoringMLModelType,
    TranscriptionMLModelType,
} from 'src/models/dto/items/AudioEvaluationItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.AudioEvaluation

export class AudioEvaluationHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => AudioEvaluationHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<AudioEvaluationItemDTO>
    }

    static create(): AudioEvaluationItemDTO {
        return defaultAudioEvaluationDTO()
    }

    private static assign(id: string, obj: Partial<AudioEvaluationItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateExperimental(id: string, experimental: boolean) {
        this.assign(id, { experimental })
    }

    static updateMaxNumberOfAttempts(id: string, maxNumberOfAttempts: number) {
        this.assign(id, { maxNumberOfAttempts })
    }

    static updateMaxRecordingTimeInSeconds(id: string, maxRecordingTimeInSeconds: number) {
        this.assign(id, { maxRecordingTimeInSeconds })
    }

    static updateScoringMlModel(id: string, scoringMLModel: ScoringMLModelType) {
        this.assign(id, { scoringMLModel })
    }

    static updateScoringMlModelVersion(id: string, scoringMLModelVersion: string) {
        this.assign(id, { scoringMLModelVersion })
    }

    static updateTranscriptionMlModel(id: string, transcriptionMLModel: TranscriptionMLModelType) {
        this.assign(id, { transcriptionMLModel })
    }

    static updateTranscriptionMlModelVersion(id: string, transcriptionMLModelVersion: string) {
        this.assign(id, { transcriptionMLModelVersion })
    }

    static updateTask(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.taskI18N[locale] = nextValue
        })
    }

    static updateSituation(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.situationI18N[locale] = nextValue
        })
    }
}
