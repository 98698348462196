import {
    defaultRatingItem,
    defaultRatingScale,
    MAX_RATING_SCALE,
    RatingItemDTO,
} from 'src/models/dto/items/RatingItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, Stores } from './../../Store'

const itemType = ItemType.Rating

export class RatingHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => RatingHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<RatingItemDTO>
    }

    static create(): RatingItemDTO {
        return defaultRatingItem()
    }

    private static assign(id: string, obj: Partial<RatingItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateQuestion(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.questionI18N[locale] = nextValue
        })
    }

    private static checkArrayBounds<T>(index: number, arr: T[]) {
        if (arr && (index < 0 || index >= arr.length)) {
            throw new Error(`Index out of bounds: Index: ${index}, Size: ${arr.length}`)
        }
    }

    static addScale(id: string) {
        this.store().produce(id, (entity) => {
            if (entity.scales.length >= MAX_RATING_SCALE) {
                throw new Error('Rating scales can only be added up to six scales')
            }

            entity.scales.push(defaultRatingScale())
        })
    }

    static updateScaleName(id: string, index: number, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.scales)
            entity.scales[index].nameI18N[locale] = nextValue
            if (locale === Locale.en_US) {
                entity.scales[index].name = nextValue
            }
        })
    }

    static updateScaleScore(id: string, index: number, nextValue: number) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.scales)
            entity.scales[index].score = nextValue
        })
    }

    static deleteScale(id: string, index: number) {
        this.store().produce(id, (entity) => {
            this.checkArrayBounds(index, entity.scales)
            entity.scales.splice(index, 1)
        })
    }
}
