import { ChatItemDTO, defaultChatItemDTO } from 'src/models/dto/items/ChatItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { Store, Stores } from 'src/services/Store'

const itemType = ItemType.Chat

export class ChatHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => ChatHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ChatItemDTO>
    }

    static create(): ChatItemDTO {
        return defaultChatItemDTO()
    }

    private static assign(id: string, obj: Partial<ChatItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateMessageHeader(id: string, locale: Locale, index: number, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.chatMessages[index].messageHeaderI18N[locale] = nextValue
        })
    }

    static updateMessage(id: string, locale: Locale, index: number, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.chatMessages[index].messageI18N[locale] = nextValue
        })
    }

    static updateChatHeader(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            if (entity.chatHeader) {
                entity.chatHeader.headerStringI18N[locale] = nextValue
            } else {
                entity.chatHeader = { headerStringI18N: LocalizeDefault(nextValue, locale) }
            }
        })
    }

    static deleteMessage(id: string, index: number) {
        this.store().produce(id, (entity) => {
            entity.chatMessages.splice(index, 1)
        })
    }

    static addMessage(id: string) {
        this.store().produce(id, (entity) => {
            entity.chatMessages.push({
                messageHeaderI18N: LocalizeDefault(''),
                messageI18N: LocalizeDefault(''),
            })
        })
    }
}
