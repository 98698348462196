import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import {
    IconAlertCircleFill,
    IconCheckCircleFill,
    IconCross,
} from '@amzn/stencil-react-components/icons'
import { Col } from '@amzn/stencil-react-components/layout'
import { Link, LinkProps } from '@amzn/stencil-react-components/link'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { SortDirection, Table, useSort } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'
import { withTooltip } from '@amzn/stencil-react-components/tooltip'

import {
    LAYOUT_DIRECTION,
    ModuleDateCell,
    useLayoutDirection,
} from 'src/components/ModuleDisplayTable'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleMetaData } from 'src/models/SearchResultResponse'
import {
    getModuleMetaData,
    NOT_ITEM_POOL_ALLOWED_STATUS,
} from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ManualItemPoolBuilder'
import { moduleViewerRoute } from 'src/pages/module-review'
import { ModuleService } from 'src/services/backend/ModuleService'

const BasicModuleCell = ({ data }: { data: string }) => <Text textAlign='left'> {data} </Text>

// ignoring this for now because there is no known fix for this issue
// @ts-ignore: Expression produces a union type that is too complex to represent error
const LinkWithTooltip = withTooltip<Omit<LinkProps, 'ref'>>({
    useTriggerWrapper: false,
})(Link)
const ModuleNameCell = ({ data }: { data: ModuleMetaData }) => {
    return (
        <Col dataTestId={'ModuleNameCell'}>
            <Text textAlign='left' color='#0077D9'>
                {' '}
                <RouterLink to={moduleViewerRoute({ moduleVersionId: data.moduleVersionId })}>
                    <strong>{data.moduleName}</strong>
                </RouterLink>{' '}
            </Text>
            <Text textAlign='left' color='neutral70' fontSize='T100'>
                Owner:{' '}
                <LinkWithTooltip
                    target='_blank'
                    href={`https://phonetool.amazon.com/users/${data.owner}`}
                    tooltipText='Opens user in Phone Tool'
                >
                    {data.owner}
                </LinkWithTooltip>
            </Text>
        </Col>
    )
}

export interface ManualItemPoolBuilderPoolTableProps {
    itemPoolResults: ModuleMetaData[]
    removeClick: (itemContainer: ModuleMetaData) => void
    updateModule: (oldVersionId: string, newVersion: ModuleMetaData) => void
    addError: (errorMessage: string) => void
}
export const ManualItemPoolBuilderPoolTable = ({
    itemPoolResults,
    removeClick,
    updateModule,
    addError,
}: ManualItemPoolBuilderPoolTableProps) => {
    const [replacements, setReplacements] = useState(new Map<string, ModuleMetaData>())

    const updateReplacements = (key: string, value: ModuleMetaData) => {
        setReplacements((prevState) => new Map(prevState).set(key, value))
    }

    useEffect(() => {
        try {
            void (async () => {
                for (const itemContainer of itemPoolResults) {
                    const newestModuleVersion = await ModuleService.getNewestModuleVersion(
                        itemContainer.moduleId,
                        NOT_ITEM_POOL_ALLOWED_STATUS
                    )
                    if (newestModuleVersion === '') {
                        console.log('NO MODULE WITH SPECIFICATION FOUND')
                        return
                    }
                    if (itemContainer.moduleVersionId !== newestModuleVersion) {
                        const replacementModuleMetaData = await getModuleMetaData(
                            newestModuleVersion
                        )
                        updateReplacements(itemContainer.moduleVersionId, replacementModuleMetaData)
                    }
                }
            })()
        } catch (e) {
            addError('Error when accessing newest module version')
            console.error(e)
        }
    }, [addError, itemPoolResults])

    const layout = useLayoutDirection()

    const RemoveButton = ({ data }: { data: ModuleMetaData }) => {
        return (
            <Button
                variant={ButtonVariant.Secondary}
                icon={<IconCross title='Remove module' />}
                dataTestId={`remove-button-${data.moduleVersionId}`}
                onClick={() => {
                    removeClick(data)
                }}
            />
        )
    }

    const ModuleReplaceButton = ({ data }: { data: ModuleMetaData }) => {
        if (replacements.has(data.moduleVersionId)) {
            return (
                <Button
                    variant={ButtonVariant.Secondary}
                    icon={<IconAlertCircleFill title={'newer version available'} />}
                    dataTestId={`replace-button-${data.moduleVersionId}`}
                    onClick={() =>
                        updateModule(
                            data.moduleVersionId,
                            replacements.get(data.moduleVersionId) as ModuleMetaData
                        )
                    }
                >
                    Replace
                </Button>
            )
        } else {
            return (
                <Button
                    variant={ButtonVariant.Secondary}
                    icon={<IconCheckCircleFill title={'newest version'} />}
                    dataTestId={`replace-button-${data.moduleVersionId}`}
                    disabled={true}
                >
                    Newest
                </Button>
            )
        }
    }

    const TABLE_COLUMNS = [
        {
            header: 'Status',
            accessor: ({ data }: { data: ModuleMetaData }) =>
                ModuleStatus[data.status as keyof typeof ModuleStatus],
            cellComponent: BasicModuleCell,
            sortId: 'status',
            width: '10%',
        },
        {
            header: 'Module Name',
            cellComponent: ModuleNameCell,
            sortId: 'moduleName',
            width: '20%',
        },
        {
            header: 'Last Modified',
            cellComponent: ModuleDateCell,
            sortId: 'modifiedAt',
            width: '20%',
        },
        {
            header: <ScreenReaderOnly>Replace action</ScreenReaderOnly>,
            cellComponent: ModuleReplaceButton,
            width: '15%',
        },
        {
            header: <ScreenReaderOnly>Remove action</ScreenReaderOnly>,
            cellComponent: RemoveButton,
            width: '5%',
        },
        {
            header: 'Status*',
            accessor: ({ data }: { data: ModuleMetaData }) =>
                replacements.has(data.moduleVersionId)
                    ? ModuleStatus[
                          replacements.get(data.moduleVersionId)
                              ?.status as keyof typeof ModuleStatus
                      ]
                    : ModuleStatus[data.status as keyof typeof ModuleStatus],
            cellComponent: BasicModuleCell,
            width: '10%',
        },
        {
            header: 'Last Modified*',
            accessor: ({ data }: { data: ModuleMetaData }) =>
                replacements.has(data.moduleVersionId)
                    ? replacements.get(data.moduleVersionId)
                    : data,
            cellComponent: ModuleDateCell,
            width: '20%',
        },
    ]
    const TABLE_COLUMNS_MOBILE = [
        {
            header: 'Module Name',
            cellComponent: ModuleNameCell,
            sortId: 'moduleName',
            width: '70%',
        },
        {
            header: <ScreenReaderOnly>Replace action</ScreenReaderOnly>,
            cellComponent: ModuleReplaceButton,
            width: '20%',
        },
        {
            header: <ScreenReaderOnly>Edit action</ScreenReaderOnly>,
            cellComponent: RemoveButton,
            width: '10% ',
        },
    ]

    const columns = layout === LAYOUT_DIRECTION.MOBILE ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS

    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: SortDirection.Descending,
        defaultActiveSortId: 'modifiedAt',
    })

    const compare = (a: ModuleMetaData, b: ModuleMetaData) => {
        const dataA: string | boolean = a[activeSortId as keyof ModuleMetaData]
        const dataB: string | boolean = b[activeSortId as keyof ModuleMetaData]
        return dataA < dataB ? -1 : 1
    }

    const sortedData =
        sortDirection === SortDirection.Descending
            ? itemPoolResults.sort(compare).reverse()
            : itemPoolResults.sort(compare)

    return (
        <Table
            columns={columns}
            activeSortId={activeSortId}
            sortDirection={sortDirection}
            maxHeight={750}
            hasStickyHeader={true}
            onSort={onSort}
            data={sortedData}
            dataTestId='item-pool-results-table'
        />
    )
}
