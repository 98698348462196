import { applyChange, Change } from 'src/hooks/DTOEditor'
import {
    defaultInformationImage,
    defaultInformationImagesItemDTO,
    InformationImagesItemDTO,
} from 'src/models/dto/items/InformationImagesItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.InformationImages

export class InformationImagesHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => InformationImagesHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<InformationImagesItemDTO>
    }

    static create(): InformationImagesItemDTO {
        return defaultInformationImagesItemDTO()
    }

    private static assign(id: string, obj: Partial<InformationImagesItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateImage(id: string, change: Change<Medias | undefined>, imageIndex: number) {
        this.store().produce(id, (entity) => {
            const { media } = entity.images[imageIndex]
            entity.images[imageIndex].media = applyChange(media, change)
        })
    }

    static updateInformationTitle(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.informationTitle[locale] = nextValue
        })
    }

    static updateImageLabel(id: string, imageIndex: number, labelValue: string, locale: Locale) {
        this.store().produce(id, (entity) => {
            entity.images[imageIndex].label[locale] = labelValue
        })
    }

    static deleteImage(id: string, index: number) {
        this.store().produce(id, (entity) => {
            entity.images.splice(index, 1)
        })
    }

    static addNewInformationImage(id: string) {
        this.store().produce(id, (entity) => {
            entity.images.push(defaultInformationImage())
        })
    }

    private static moveImage(id: string, fromIndex: number, toIndex: number) {
        this.store().produce(id, (entity) => {
            ;[entity.images[fromIndex], entity.images[toIndex]] = [
                entity.images[toIndex],
                entity.images[fromIndex],
            ]
        })
    }

    static moveImageDown(id: string, itemIndex: number) {
        this.moveImage(id, itemIndex, itemIndex + 1)
    }

    static moveImageUp(id: string, itemIndex: number) {
        this.moveImage(id, itemIndex, itemIndex - 1)
    }
}
