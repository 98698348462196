import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { Input, InputWrapper, TextArea } from '@amzn/stencil-react-components/form'
import { IconCheck, IconCheckCircleFill, IconPencil } from '@amzn/stencil-react-components/icons'
import { Col, Container, Flex, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H2, Text } from '@amzn/stencil-react-components/text'
import { px } from '@amzn/stencil-react-components/utils'

import { MastheadContainer } from 'src/components/MastheadContainer'
import { ModuleOptions, OptionValues } from 'src/components/ModuleOptions'
import { ADMIN_OVERRIDE_LIST, APP_CONFIG, Features } from 'src/config.app'
import { ModuleBuilderContext } from 'src/contexts/ModuleBuilderContext'
import { isFeatureEnabled } from 'src/helper/featureFlagHelper'
import { useEntity } from 'src/hooks/useEntity'
import { ErrorResponse, ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ModuleGroupStatus } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ModuleLastModifiedDTO } from 'src/models/dto/ModuleLastModifiedDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleReviewStatusDTO, ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'
import { TemplateValidationError } from 'src/models/dto/TemplateValidationError'
import { getEnumKeyByEnumValue } from 'src/models/EnumUtils'
import {
    CompleteTranslationRequest,
    CompleteTranslationResponse,
    IncompleteMediaListMember,
} from 'src/pages/asset-library/HttpClientModels'
import { Authenticator } from 'src/services/Authenticator'
import { ApiActionNames } from 'src/services/AxiosInterceptor'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ModuleService } from 'src/services/backend/ModuleService'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'

const MODULE_NAME_CHARACTER_LIMIT = 47
export const Subtitle = styledWithTheme(View)(
    ({ theme }) => `
    color: ${theme.selectors.color('neutral70')} !important;
    font-size: ${theme.selectors.fontSize('T200') ?? 'initial'} !important;
`
)

export interface TagStyleProps {
    backgroundColor?: string
    borderColor?: string
    color?: string
}

export const TagStyle = styledWithTheme<'span', TagStyleProps>('span')(
    ({ theme, borderColor, backgroundColor, color }) => `
    position: relative;
    height: 28px;
    top: 4px;
    display: inline-flex;
    align-items: center;
    padding: ${px(theme.values.space.S100)} ${px(theme.values.space.S200)} !important;
    border: 2px solid ${borderColor ?? theme.selectors.color('neutral30')};
    border-radius: ${px(theme.values.space.S100)};
    color: ${color ?? theme.selectors.color('neutral90')};
    background-color: ${backgroundColor ?? 'inherit'};
    font-size: ${theme.selectors.fontSize('T200') ?? 'inherit'} !important;
    font-weight: ${theme.selectors.fontWeight('bold') ?? 'inherit'};
`
)

const renderTagText = (status: ModuleStatus.UAT | ModuleStatus.PUBLISHED) => {
    if (status === ModuleStatus.UAT) {
        return 'UAT READY'
    }

    if (!isFeatureEnabled(Features.FAST_TRACK_APPROVAL) && status === ModuleStatus.PUBLISHED) {
        return 'PUBLISHED'
    }

    return status.toString().toUpperCase()
}

export function TagWorkflowReady({
    status,
}: {
    status: ModuleStatus.UAT | ModuleStatus.PUBLISHED
}) {
    return (
        <TagStyle color='white' backgroundColor='#23756B' borderColor='#237568'>
            <IconCheckCircleFill width='12px' height='12px' title='' aria-hidden />
            <Spacer width='0.5em' />
            <span data-test-id='tag-uat-ready'>{renderTagText(status)}</span>
        </TagStyle>
    )
}

export function TagDraft({ status }: { status: ModuleStatus | ModuleGroupStatus }) {
    const publishedNonFastTrack =
        !isFeatureEnabled(Features.FAST_TRACK_APPROVAL) &&
        status === getEnumKeyByEnumValue(ModuleStatus, ModuleStatus.PUBLISHED)

    return (
        <TagStyle>
            <IconPencil width='12px' height='12px' title='' aria-hidden />
            <Spacer width='0.5em' />
            <span data-test-id='tag-draft'>
                {publishedNonFastTrack
                    ? 'Published'
                    : ModuleStatus[status as keyof typeof ModuleStatus] ?? status}
            </span>
        </TagStyle>
    )
}

interface SaveDraftProps {
    saveDraft: (comments: string) => void
    isDraftSaved: boolean
    hasUnsavedChanges: boolean
    moduleStatus?: ModuleStatus
    lastModified: ModuleLastModifiedDTO
    buttonDataTestIdPrefix: string
    saveError?: boolean
    editDisabled?: boolean
}

export function SaveDraft({
    saveDraft,
    isDraftSaved,
    hasUnsavedChanges,
    moduleStatus,
    lastModified,
    buttonDataTestIdPrefix,
    saveError,
    editDisabled,
}: SaveDraftProps) {
    const [validateErrorMessage, setValidateErrorMessage] = useState<string | undefined>(undefined)
    const [comments, setComments] = useState('')
    const renderSaveDraftModal = useCallback(
        ({ close }: { close: () => void }) => {
            const save = () => {
                if (!comments) {
                    setValidateErrorMessage('Please enter a summary of your updates.')
                    return
                }

                saveDraft(comments)
                setComments('')
                setValidateErrorMessage(undefined)
                close()
            }

            const buttons = [
                <Button
                    key='cancel'
                    dataTestId={`${buttonDataTestIdPrefix}cancel-save-draft`}
                    variant={ButtonVariant.Secondary}
                    onClick={close}
                >
                    Cancel
                </Button>,
                // @ts-ignore: Expression produces a union type that is too complex to represent error
                <Button
                    key='save'
                    dataTestId={`${buttonDataTestIdPrefix}submit-save-draft`}
                    variant={ButtonVariant.Primary}
                    onClick={save}
                >
                    Save
                </Button>,
            ]
            return (
                <ModalContent titleText='Save draft' buttons={buttons}>
                    <Col gridGap='S400'>
                        {lastModified.status === ModuleStatus.DRAFT_VALIDATED &&
                            lastModified.author !== Authenticator.getDefaultUser() && (
                                <MessageBanner
                                    type={MessageBannerType.Warning}
                                    dataTestId={'ownership-warning-banner'}
                                >
                                    Before saving, please add notes confirming the transfer of
                                    ownership of this draft module.
                                </MessageBanner>
                            )}
                        {moduleStatus === ModuleStatus.TRANSLATION_REQUESTED && (
                            <MessageBanner
                                dataTestId='translation-warning'
                                type={MessageBannerType.Warning}
                            >
                                There is a translation for this module currently pending. Are you
                                sure you would like to request translations again. This will cancel
                                your current request and postpone translation completion.
                            </MessageBanner>
                        )}
                        <Text>
                            Before we save, add a few notes about your changes and why you made
                            them. This allows others to troubleshoot issues and track version
                            changes.
                        </Text>
                        <InputWrapper
                            id='save-draft-comments'
                            labelText='Describe your updates'
                            footer={validateErrorMessage}
                            error={typeof validateErrorMessage === 'string'}
                        >
                            {(props) => (
                                <TextArea
                                    dataTestId={`${buttonDataTestIdPrefix}save-draft-comments`}
                                    placeholder=''
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    {...props}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </ModalContent>
            )
        },
        [
            buttonDataTestIdPrefix,
            lastModified.status,
            lastModified.author,
            moduleStatus,
            validateErrorMessage,
            comments,
            saveDraft,
        ]
    )

    const buttonText = useMemo(() => {
        if (
            moduleStatus !== ModuleStatus.DRAFT_UNVALIDATED &&
            moduleStatus !== ModuleStatus.DRAFT_VALIDATED &&
            moduleStatus !== undefined
        ) {
            return 'Create new draft'
        } else if (isDraftSaved && !hasUnsavedChanges) {
            return 'Draft saved'
        } else {
            return 'Save draft'
        }
    }, [isDraftSaved, moduleStatus, hasUnsavedChanges])

    const saveDisabled = useMemo(
        () => editDisabled || !hasUnsavedChanges,
        [hasUnsavedChanges, editDisabled]
    )

    return (
        <WithModal renderModal={renderSaveDraftModal}>
            {({ open: openSaveDraft }) => {
                return (
                    <>
                        {saveError && (
                            <>
                                <MessageBanner
                                    type={MessageBannerType.Error}
                                    dataTestId={'save-draft-unexpected-error-banner'}
                                >
                                    Save error, please try again
                                </MessageBanner>
                                <Spacer width={'S200'} />
                            </>
                        )}
                        <Button
                            dataTestId={`${buttonDataTestIdPrefix}save-draft`}
                            disabled={saveDisabled}
                            variant={ButtonVariant.Primary}
                            onClick={() => openSaveDraft()}
                            icon={
                                isDraftSaved && !hasUnsavedChanges ? (
                                    <IconCheck title='' aria-hidden />
                                ) : undefined
                            }
                        >
                            {buttonText}
                        </Button>
                    </>
                )
            }}
        </WithModal>
    )
}

interface ModuleMastheadProps {
    moduleId: string
    moduleLastModified: ModuleLastModifiedDTO
    reviewStatus?: ModuleReviewStatusDTO
    moduleStatus?: ModuleStatus
    saveModuleDTO: (message: string, migrateMedia?: boolean) => Promise<boolean>
    isDraftSaved: boolean
    setIsDraftSaved: (value: ((prevState: boolean) => boolean) | boolean) => void
    isDraftSaving: boolean
    setIsDraftSaving: (value: ((prevState: boolean) => boolean) | boolean) => void
    isMigrated: boolean | undefined
    setIsMigrated: (
        value: ((prevState: boolean | undefined) => boolean | undefined) | boolean | undefined
    ) => void
    setIsSaveDraftError: (value: ((prevState: boolean) => boolean) | boolean) => void
    onErrorCheck?: (hasValidationError: boolean) => void
    onPreviewUnexpectedError?: () => void
    hasUnsavedChanges: boolean
    setHasUnsavedChanges: (value: ((prevState: boolean) => boolean) | boolean) => void
    duplicateModule: (versionId: string, afterSaveHook: () => void) => void
    loadModuleVersion: (moduleVersionId: string) => Promise<ModuleVersionDTO | undefined>
    editDisabled?: boolean
    onAdminOverride?: () => void
}

interface MigrateBannerProps {
    canMigrate: boolean
    isDraftSaving: boolean
    isMigrated: boolean | undefined
    saveDraft: (message: string, migrate?: boolean) => Promise<void>
    setIsMigrated: (
        value: ((prevState: boolean | undefined) => boolean | undefined) | boolean | undefined
    ) => void
}

export function LastModifiedSubtitle(props: { lastModified: ModuleLastModifiedDTO }) {
    return (
        <Subtitle>
            Last modified by{' '}
            <span data-test-id='last-modified-by'>{props.lastModified.author}</span> on{' '}
            <span data-test-id='last-modified-date'>
                {props.lastModified.date.toLocaleDateString()} at{' '}
                {props.lastModified.date.toLocaleTimeString()}
            </span>{' '}
            {props.lastModified.status !== ModuleStatus.ARCHIVED &&
            (props.lastModified.status === ModuleStatus.UAT ||
                props.lastModified.status === ModuleStatus.PUBLISHED) ? (
                <TagWorkflowReady status={props.lastModified.status} />
            ) : (
                <TagDraft status={props.lastModified.status} />
            )}
        </Subtitle>
    )
}

const MigrateBanner = ({
    canMigrate,
    isDraftSaving,
    isMigrated,
    saveDraft,
    setIsMigrated,
}: MigrateBannerProps) => {
    return (
        <>
            {canMigrate && !isDraftSaving && !isMigrated && (
                <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
                    <MessageBanner
                        key={'warning-message-migrate-media'}
                        dataTestId={'warning-message-migrate-media'}
                        type={MessageBannerType.Warning}
                    >
                        <Row gridGap={'S400'}>
                            <Col>
                                <Row padding={{ bottom: 'S200' }}>
                                    <Text>
                                        This module is set to use the legacy media editor. Please
                                        press this button to migrate to the asset library, which
                                        will:
                                    </Text>
                                </Row>
                                <Row padding={{ bottom: 'S200' }}>
                                    <Col>
                                        <ul>
                                            <li style={{ listStyleType: 'disc' }}>
                                                <Text>
                                                    Make the module English only. Translations will
                                                    need to be re-requested.
                                                </Text>
                                            </li>
                                            <li style={{ listStyleType: 'disc' }}>
                                                <Text>
                                                    Take any media assigned to the module and move
                                                    it to the asset library.
                                                </Text>
                                            </li>
                                            <li style={{ listStyleType: 'disc' }}>
                                                <Text>
                                                    You will be required to manually check the media
                                                    see if the migration process has chosen the
                                                    correct media.
                                                </Text>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Text>
                                        <strong>
                                            If you need to, you can revert to the the previous
                                            version of the module using the browser back button.
                                        </strong>
                                    </Text>
                                </Row>
                            </Col>
                            <Col justifyContent={'center'}>
                                <Button
                                    key='migrate-module'
                                    dataTestId='migrate-module'
                                    onClick={async () => {
                                        setIsMigrated(false)
                                        await saveDraft(
                                            'Updating Module to use Media Manager',
                                            true
                                        )
                                    }}
                                >
                                    Migrate
                                </Button>
                            </Col>
                        </Row>
                    </MessageBanner>
                </Container>
            )}
            {isMigrated && (
                <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
                    <MessageBanner
                        key={'success-message-migrate-media'}
                        dataTestId={'success-message-migrate-media'}
                        type={MessageBannerType.Success}
                        isDismissible
                    >
                        This module has been successfully migrated to the media manager. We
                        recommend that you preview the module in order to visually verify that the
                        media is still correct.
                    </MessageBanner>
                </Container>
            )}
        </>
    )
}

const IncompleteMediaList = ({
    incompleteMediaList,
}: {
    incompleteMediaList: IncompleteMediaListMember[]
}) => {
    return (
        <Row padding={{ bottom: 'S200', left: 'S400' }}>
            <Col>
                <ul>
                    {incompleteMediaList.map((media) => {
                        return (
                            <li style={{ listStyleType: 'disc' }} key={media.mediaId}>
                                <RouterLink
                                    to={`/asset-library/assets/${media.mediaId}`}
                                    target={'_blank'}
                                >
                                    {media.mediaName}
                                </RouterLink>
                            </li>
                        )
                    })}
                </ul>
            </Col>
        </Row>
    )
}

const MediaTranslateBanner = ({
    moduleVersionId,
    moduleStatus,
    loadModuleVersion,
}: {
    moduleVersionId: string
    moduleStatus: ModuleStatus | undefined
    loadModuleVersion: (moduleVersionId: string) => Promise<ModuleVersionDTO | undefined>
}) => {
    const [clickedComplete, setClickedComplete] = useState(false)
    const [{ error, loading, data }, executeCompleteMediaTranslation] = useAxios<
        CompleteTranslationResponse,
        CompleteTranslationRequest
    >(
        {
            method: 'POST',
            url: `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${moduleVersionId}/completeMediaTranslation`,
            apiActionName: ApiActionNames.CompleteMediaTranslation,
        },
        {
            manual: true,
        }
    )

    const checkMediaCompletion = useCallback(async () => {
        await executeCompleteMediaTranslation({
            data: {
                requester: Authenticator.getDefaultUser(),
                versionId: moduleVersionId,
            },
        }).catch(() => {})
        await loadModuleVersion(moduleVersionId)
    }, [executeCompleteMediaTranslation, loadModuleVersion, moduleVersionId])

    useEffect(() => {
        if (moduleStatus === ModuleStatus.MEDIA_TRANSLATION_REQUIRED) {
            void checkMediaCompletion()
        }
    }, [checkMediaCompletion, moduleStatus])

    const action = () => {
        if (loading) {
            return <Spinner dataTestId={'completing-transaction'} />
        }
        return (
            <Button
                onClick={async () => {
                    await checkMediaCompletion()
                    setClickedComplete(true)
                }}
            >
                Complete Translation
            </Button>
        )
    }

    if (error && clickedComplete) {
        return (
            <MessageBanner type={MessageBannerType.Error} dataTestId={'error-message-banner'}>
                Translation failed due to an error, please refresh the page and try again.
            </MessageBanner>
        )
    }

    if (data && !data.mediaTranslationComplete && clickedComplete) {
        return (
            <>
                <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
                    <MessageBanner
                        key={'error-message-translate-media'}
                        dataTestId={'error-message-translate-media'}
                        type={MessageBannerType.Error}
                    >
                        <Row gridGap={'S200'}>
                            <Col>
                                <Row padding={{ bottom: 'S200' }}>
                                    Translation validation failed. The following media still need to
                                    be updated:
                                </Row>
                                <IncompleteMediaList
                                    incompleteMediaList={data.incompleteMediaList}
                                />
                                <Row padding={{ bottom: 'S200' }}>
                                    After updating please try to complete the translation again.
                                </Row>
                            </Col>
                            <Col justifyContent={'center'}>{action()}</Col>
                        </Row>
                    </MessageBanner>
                </Container>
            </>
        )
    } else if (data && data.mediaTranslationComplete && clickedComplete) {
        return (
            <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
                <MessageBanner
                    dataTestId={'success-message-complete-translation'}
                    type={MessageBannerType.Success}
                    isDismissible
                >
                    This module has been successfully translated.
                </MessageBanner>
            </Container>
        )
    }

    return (
        <>
            {moduleStatus === ModuleStatus.MEDIA_TRANSLATION_REQUIRED && !clickedComplete && (
                <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
                    <MessageBanner
                        key={'warning-message-translate-media'}
                        dataTestId={'warning-message-translate-media'}
                        type={MessageBannerType.Warning}
                    >
                        <Row gridGap={'S200'}>
                            <Col>
                                <Row padding={{ bottom: 'S200' }}>
                                    This module contains media (images, videos, etc..) that you will
                                    need to manually update. Please update the media alt text and
                                    the media itself for each language. After this has been
                                    completed please click the Complete Translation button.
                                </Row>
                                {data && !error && (
                                    <>
                                        The following media need to be updated:
                                        <IncompleteMediaList
                                            incompleteMediaList={data.incompleteMediaList}
                                        />
                                    </>
                                )}
                            </Col>
                            <Col justifyContent={'center'}>{action()}</Col>
                        </Row>
                    </MessageBanner>
                </Container>
            )}
        </>
    )
}

export function ModuleMasthead({
    moduleId,
    moduleStatus,
    reviewStatus,
    saveModuleDTO,
    moduleLastModified,
    isDraftSaved,
    setIsDraftSaved,
    isDraftSaving,
    setIsDraftSaving,
    isMigrated,
    setIsMigrated,
    onErrorCheck,
    onPreviewUnexpectedError,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    duplicateModule,
    setIsSaveDraftError,
    loadModuleVersion,
    editDisabled,
    onAdminOverride,
}: ModuleMastheadProps) {
    const focusTargetRef = React.useRef<HTMLHeadingElement | null>(null)
    const [newModuleName, setNewModuleName] = useState('')
    const [nameValidationError, setNameValidationError] = useState(false)
    const [nameValidationErrorMessage, setNameValidationErrorMessage] = useState('')
    const {
        matches: { s: isSmall },
    } = useBreakpoints()
    const wide = !isSmall
    const MODULE_NAME_UNIQUE_ERROR_MESSAGE = 'Module Name must be unique.'
    const { moduleUsingMediaManager } = useContext(ModuleBuilderContext)

    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    const moduleNameUniquenessCheck = useCallback(async (name: string, moduleVersionId: string) => {
        function isModuleNameError(errors: TemplateValidationError[]) {
            return (
                errors.filter(
                    (e) =>
                        e.message === MODULE_NAME_UNIQUE_ERROR_MESSAGE && e.attributePath === 'name'
                ).length > 0
            )
        }

        try {
            const moduleDTO = ModuleService.serializeModuleDTO(moduleVersionId)
            await ErrorCheckService.moduleVersionErrorCheck(
                {
                    ...moduleDTO,
                    name: name,
                },
                Date.now(),
                true,
                true
            )

            return true
        } catch (e) {
            return !isModuleNameError(
                (
                    (e as ErrorResponseThrowable).errorResponse as ErrorResponse & {
                        errors: TemplateValidationError[]
                    }
                ).errors
            )
        }
    }, [])

    const validateModuleName = useCallback(
        async (name: string) => {
            return await moduleNameUniquenessCheck(name, moduleId)
        },
        [moduleId, moduleNameUniquenessCheck]
    )

    // This simulates saving the module then reloading its draft status.
    // Expect this code to be changed and/or deleted entirely when we integrate with the new backend.
    const saveDraft = useCallback(
        async (message: string, migrate = false) => {
            setIsDraftSaving(true)
            setIsSaveDraftError(false)
            const succeeded = await saveModuleDTO(message, migrate as boolean)
            if (succeeded) {
                if (migrate) setIsMigrated(true)
                setIsDraftSaving(false)
                setIsDraftSaved(true)
                setHasUnsavedChanges(false)
            } else {
                setIsDraftSaving(false)
                setIsSaveDraftError(true)
            }
        },
        [
            saveModuleDTO,
            setHasUnsavedChanges,
            setIsDraftSaved,
            setIsDraftSaving,
            setIsSaveDraftError,
            setIsMigrated,
        ]
    )

    const startDraft = useCallback(async () => {
        if (newModuleName.length > MODULE_NAME_CHARACTER_LIMIT) {
            setNameValidationError(true)
            setNameValidationErrorMessage('The module name must be shorter than 47 characters')
            return
        } else if (/\s/.test(newModuleName)) {
            // spaces
            setNameValidationError(true)
            setNameValidationErrorMessage('The module name cannot contain spaces')
            return
        } else if (/[^a-zA-Z0-9_-]/.test(newModuleName)) {
            // everything else
            setNameValidationError(true)
            setNameValidationErrorMessage(
                'The module name can only contain letters, numbers, hyphens and underscores'
            )
            return
        }
        const isNameValid = await validateModuleName(newModuleName)
        if (isNameValid) {
            ModuleEntityService.updateName(moduleId, newModuleName)
            if (moduleUsingMediaManager) {
                ModuleEntityService.updateUsingMediaManager(moduleId, true)
            }
            await saveDraft('Module creation started')
        } else {
            setNameValidationError(true)
            setNameValidationErrorMessage('This module name is already in use')
        }
    }, [newModuleName, moduleId, validateModuleName, saveDraft, moduleUsingMediaManager])

    const moduleHasName = moduleEntity?.name.trim().length ?? 0 > 0

    const dropDownOptions = new Set([
        OptionValues.LAUNCH_PREVIEW,
        OptionValues.OPEN_REVIEW,
        OptionValues.ERROR_CHECK,
        OptionValues.DUPLICATE_MODULE,
        OptionValues.SHARE_PREVIEW,
        OptionValues.COPY_MODULE_ID,
    ])

    const canMigrate =
        (moduleStatus === ModuleStatus.DRAFT_VALIDATED ||
            moduleStatus === ModuleStatus.DRAFT_UNVALIDATED ||
            !moduleStatus) &&
        !moduleEntity.usingMediaManager

    const warningMessage =
        'You are not the owner of this draft. If you save your changes the ownership of this draft will transfer to you.'
    const showWarningMessage =
        (moduleStatus === ModuleStatus.DRAFT_UNVALIDATED ||
            moduleStatus === ModuleStatus.DRAFT_VALIDATED) &&
        Authenticator.getDefaultUser() !== moduleLastModified.author

    const warningMessages = [] as string[]

    if (showWarningMessage) {
        warningMessages.push(warningMessage)
    }

    if (editDisabled) {
        if (moduleStatus === ModuleStatus.TRANSLATION_REQUESTED) {
            if (ADMIN_OVERRIDE_LIST.includes(Authenticator.getDefaultUser())) {
                dropDownOptions.add(OptionValues.ADMIN_OVERRIDE)
            }
            warningMessages.push(
                'Module features are heavily limited while there are translations pending'
            )
        } else if (moduleStatus === ModuleStatus.ARCHIVED) {
            warningMessages.push('Module features are heavily limited when archived')
        }
    }

    if (moduleHasName) {
        return (
            <>
                <MastheadContainer warningMessages={warningMessages}>
                    <View width='100%'>
                        <H2 fontSize='T500' ref={focusTargetRef}>
                            {moduleEntity.name}
                        </H2>
                        <LastModifiedSubtitle lastModified={moduleLastModified} />
                    </View>
                    <Flex
                        flexDirection={wide ? 'row' : 'column'}
                        gridGap='S200'
                        justifyContent='flex-end'
                        flex='1'
                        width='100%'
                    >
                        {isDraftSaving ? (
                            <Spinner />
                        ) : (
                            <>
                                <ModuleOptions
                                    moduleEntity={moduleEntity}
                                    moduleStatus={moduleLastModified.status}
                                    reviewStatus={reviewStatus}
                                    onErrorCheck={onErrorCheck}
                                    onPreviewUnexpectedError={onPreviewUnexpectedError}
                                    duplicateModule={duplicateModule}
                                    validateModuleName={moduleNameUniquenessCheck}
                                    optionsToInclude={dropDownOptions}
                                    editDisabled={editDisabled}
                                    onAdminOverride={onAdminOverride}
                                />
                                <SaveDraft
                                    editDisabled={editDisabled}
                                    moduleStatus={moduleStatus}
                                    saveDraft={saveDraft}
                                    isDraftSaved={isDraftSaved}
                                    hasUnsavedChanges={hasUnsavedChanges}
                                    lastModified={moduleLastModified}
                                    buttonDataTestIdPrefix={'masthead-'}
                                />
                            </>
                        )}
                    </Flex>
                </MastheadContainer>
                <MigrateBanner
                    isMigrated={isMigrated}
                    setIsMigrated={setIsMigrated}
                    canMigrate={canMigrate}
                    saveDraft={saveDraft}
                    isDraftSaving={isDraftSaving}
                />
                <MediaTranslateBanner
                    moduleStatus={moduleStatus}
                    moduleVersionId={moduleEntity.version}
                    loadModuleVersion={loadModuleVersion}
                />
            </>
        )
    }

    const validationError = newModuleName.length < 1

    return (
        <MastheadContainer warningMessages={warningMessages}>
            <View width='100%'>
                <Col width='100%'>
                    <InputWrapper
                        id='module-masthead-new-module-name'
                        labelText='Unique module name'
                        tooltipText='Once set, the module name cannot be changed or updated.'
                        required={true}
                        error={nameValidationError}
                        footer={nameValidationError ? nameValidationErrorMessage : undefined}
                    >
                        {(props) => (
                            <Input
                                dataTestId='module-masthead-new-module-name'
                                type='text'
                                value={newModuleName}
                                onChange={(e) => setNewModuleName(e.target.value)}
                                width='100%'
                                placeholder='Enter a module name'
                                {...props}
                            />
                        )}
                    </InputWrapper>
                    {wide && (
                        <>
                            <Spacer height='S400' />
                            <Spacer height='S200' />
                        </>
                    )}
                </Col>
            </View>
            <Flex
                flexDirection={wide ? 'row' : 'column'}
                gridGap='S200'
                justifyContent='flex-end'
                flex='1'
                width='100%'
            >
                <Button
                    dataTestId='start-draft-button'
                    variant={ButtonVariant.Primary}
                    onClick={startDraft}
                    disabled={validationError}
                >
                    Start Draft
                </Button>
            </Flex>
            <MigrateBanner
                isMigrated={isMigrated}
                setIsMigrated={setIsMigrated}
                canMigrate={canMigrate}
                saveDraft={saveDraft}
                isDraftSaving={isDraftSaving}
            />
        </MastheadContainer>
    )
}
