import { defaultEmailItemDTO, EmailContentItemDTO } from 'src/models/dto/items/EmailContentItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { Store, Stores } from 'src/services/Store'

const itemType = ItemType.EmailContent

export class EmailContentHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => EmailContentHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<EmailContentItemDTO>
    }

    static create(): EmailContentItemDTO {
        return defaultEmailItemDTO()
    }

    private static assign(id: string, obj: Partial<EmailContentItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateTo(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.toI18N[locale] = nextValue
        })
    }

    static updateFrom(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.fromI18N[locale] = nextValue
        })
    }

    static updateSubject(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.subjectI18N[locale] = nextValue
        })
    }

    static updateMessage(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.messageI18N[locale] = nextValue
        })
    }
}
