import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { Breadcrumbs } from '@amzn/stencil-react-components/breadcrumbs'
import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconCheckCircleFill, IconCopy } from '@amzn/stencil-react-components/icons'
import { Col, Container, Flex, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { PageContainer } from '@amzn/stencil-react-components/page'
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/components/ModuleDisplayTable'
import { ModuleGroupOptions, ModuleGroupOptionValues } from 'src/components/ModuleGroupOptions'
import { Subtitle, TagDraft, TagStyle } from 'src/components/ModuleMasthead'
import { ModuleReviewContext } from 'src/contexts/ModuleReviewContext'
import { useEntity, useModuleGroupEntity } from 'src/hooks/useEntity'
import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { CommentDTO, LocationId } from 'src/models/dto/approval/CommentDTO'
import { ReviewDTO } from 'src/models/dto/approval/ReviewDTO'
import { RevisionDTO } from 'src/models/dto/approval/RevisionDTO'
import { ApprovalStatus } from 'src/models/dto/ApprovalStatus'
import { MESGroupDTO } from 'src/models/dto/module-groups/MESGroupDTO'
import {
    isModuleGroupInDraftMode,
    ModuleGroupModuleMetadata,
    ModuleGroupStatus,
    ModuleGroupType,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleVersionRuleSetDTO, PECGroupDTO } from 'src/models/dto/module-groups/PECGroupDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ModuleGroupAddModuleModal } from 'src/pages/module-groups/ModuleGroupAddModuleModal'
import { ModuleGroupReviewPageButtons } from 'src/pages/module-groups/ModuleGroupReviewPageButtons'
import { ModuleGroupTable, ModuleTableTypeData } from 'src/pages/module-groups/ModuleGroupTable'
import { UATModuleGroupApproval } from 'src/pages/module-groups/UATModuleGroupApproval'
import { moduleGroupViewerRoute } from 'src/pages/module-review'
import { ModuleApprovalMessage } from 'src/pages/module-review/ModuleApprovalMessage'
import { ModuleReviewBox } from 'src/pages/module-review/ModuleReviewBox'
import { ModuleReviewButtonsWrapper } from 'src/pages/module-review/ModuleReviewButtonsWrapper'
import { ModuleReviewError } from 'src/pages/module-review/ModuleReviewError'
import { ModuleGroupUATApprovalBanner } from 'src/pages/module-review/UATApprovalComponents'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { Authenticator } from 'src/services/Authenticator'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ModuleGroupEntityService } from 'src/services/EntityServices/ModuleGroupEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'
import {
    ModuleGroupService,
    UpdateModuleGroupRequest,
} from 'src/services/module-groups/ModuleGroupService'

const HeaderBreadCrumb = ({ moduleGroupName }: { moduleGroupName: string }) => {
    return (
        // AA-22688 Div is used to reset the focus to the top of the page for accessibility reasons.
        <View id='module-header-bread-crumb'>
            <Breadcrumbs
                crumbs={[
                    {
                        title: 'Module Groups',
                        url: '/module-groups',
                    },
                    {
                        title: moduleGroupName,
                    },
                ]}
            />
        </View>
    )
}

const ModuleGroupMessageBanners = ({
    moduleGroupVersionId,
    hasErrors,
    setShowBanners,
}: {
    moduleGroupVersionId: string
    hasErrors: boolean
    setShowBanners: (value: boolean) => void
}) => {
    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: moduleGroupVersionId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const errorCount = ErrorCheckService.getErrorCount(moduleGroupVersionId)

    if (hasErrors && !errorCount) {
        return (
            <MessageBanner type={MessageBannerType.Error}>
                <Text>
                    Something went wrong while trying to validate module group. Please try again or
                    contact support if that does not solve the problem.
                </Text>
            </MessageBanner>
        )
    }

    return (
        <>
            {hasErrors ? (
                <>
                    <MessageBanner
                        type={MessageBannerType.Error}
                        dataTestId='error-count-banner'
                        dismissButtonAltText={'Hide errors'}
                        isDismissible
                        onDismissed={() => setShowBanners(false)}
                    >
                        There are {errorCount} errors to resolve
                    </MessageBanner>
                    {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && (
                        <MessageBanner type={MessageBannerType.Error}>
                            <ul>
                                {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                                    (message, index) => (
                                        <li key={index}>{message}</li>
                                    )
                                )}
                            </ul>
                        </MessageBanner>
                    )}
                </>
            ) : (
                <MessageBanner
                    type={MessageBannerType.Success}
                    dataTestId='no-validation-error-banner'
                    dismissButtonAltText={'Dismiss'}
                    isDismissible
                    onDismissed={() => setShowBanners(false)}
                >
                    <Text>There are no validation errors.</Text>
                </MessageBanner>
            )}
        </>
    )
}

interface tagUATStatusProps {
    status: ModuleGroupStatus
}

export const TagUATStatus = (props: tagUATStatusProps) => {
    const isPostUAT =
        ModuleGroupStatus[props.status as unknown as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT ||
        ModuleGroupStatus[props.status as unknown as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW ||
        ModuleGroupStatus[props.status as unknown as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW_APPROVED ||
        ModuleGroupStatus[props.status as unknown as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.PUBLISHED

    return isPostUAT ? (
        <TagStyle color='white' backgroundColor='#23756B' borderColor='#237568'>
            <IconCheckCircleFill width='12px' height='12px' title='' aria-hidden />
            <Spacer width='0.5em' />
            <span data-test-id='tag-uat-ready'>{ModuleGroupStatus[props.status] as string}</span>
        </TagStyle>
    ) : (
        <TagDraft status={props.status} />
    )
}

export const ModuleGroupViewerPage = () => {
    const { moduleGroupVersionId, reviewId = '', revisionNumber = '' } = useParams()
    const [isReviewPage, setIsReviewPage] = useState<boolean>(!!reviewId && !!revisionNumber)
    const [storeModuleGroupVersionId, setStoreModuleGroupVersionId] = useState<string>(
        () => ModuleGroupEntityService.create().versionId
    )
    const { entity: moduleGroup } = useModuleGroupEntity(storeModuleGroupVersionId)

    const layoutDirection = useLayoutDirection()
    const [showAddModuleModal, setShowAddModuleModal] = useState<boolean>(false)
    const [loadModuleGroupError, setLoadModuleGroupError] = useState<string | undefined>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [moduleGroupType, setModuleGroupType] = useState<ModuleGroupType | undefined>(undefined)
    const [moduleTableData, setModuleTableData] = useState<ModuleTableTypeData[]>([])
    const [deleteError, setDeleteError] = useState<string | undefined>(undefined)
    const [editModuleIndex, setEditModuleIndex] = useState<number | undefined>(undefined)
    const [duplicateModuleIndex, setDuplicateModuleIndex] = useState<number | undefined>(undefined)
    const [reviewDto, setReviewDto] = useState<ReviewDTO | undefined>(undefined)
    const [approvals, setApprovals] = useState<ApprovalDTO[]>([])
    const [refreshMessage, setRefreshMessage] = useState<boolean>(false)
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const [showBanners, setShowBanners] = useState<boolean>(false)
    const [draftState, setDraftState] = useState<boolean>(false)
    const [showEditWarningModal, setShowEditWarningModal] = useState<boolean>(false)
    const [reloads, reload] = useReducer((x: number) => x + 1, 0)
    const [comments, setComments] = useState<CommentDTO[]>()
    const [notLatestRevisionError, setNotLatestRevisionError] = useState<boolean>(false)

    const navigate = useNavigate()

    // Ensures isReviewPage state is updated at every tick of params changing.
    // Guards against unexpected behavior when back button is used to navigate to viewer page from review page.
    useEffect(() => {
        setIsReviewPage(!!reviewId && !!revisionNumber)
    }, [reviewId, revisionNumber])

    const isModuleGroupEditable = isModuleGroupInDraftMode(moduleGroup)
    const notLatestModuleGroupVersionId = !!(!isReviewPage && moduleGroup.successorVersionId)

    const readyToBeUATDeployed =
        ModuleGroupStatus[moduleGroup.status as keyof typeof ModuleGroupStatus] ===
        ModuleGroupStatus.REVIEW_APPROVED

    const isUATReviewStage =
        ModuleGroupStatus[moduleGroup.status as keyof typeof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW ||
        ModuleGroupStatus[moduleGroup.status as keyof typeof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW_APPROVED

    const isPostUATStage =
        isUATReviewStage ||
        ModuleGroupStatus[moduleGroup.status as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.PUBLISHED

    const dropDownOptions = new Set<ModuleGroupOptionValues>([
        ModuleGroupOptionValues.RUN_ERROR_CHECK,
    ])

    if (!notLatestModuleGroupVersionId) {
        dropDownOptions.add(ModuleGroupOptionValues.EDIT_GROUP_INFORMATION)
    }

    if (!isPostUATStage) {
        dropDownOptions.add(ModuleGroupOptionValues.OPEN_REVIEW)
    }

    if (readyToBeUATDeployed) {
        dropDownOptions.add(ModuleGroupOptionValues.DEPLOY_UAT)
    }

    useEffect(() => {
        if (moduleGroup) {
            setDraftState(isModuleGroupEditable)
        }
    }, [isModuleGroupEditable, moduleGroup])

    useEffect(() => {
        setLoadModuleGroupError(undefined)
        setModuleGroupType(undefined)
        if (moduleGroupVersionId) {
            setIsLoading(true)
            const getModuleDto = async () => {
                try {
                    const data = await ModuleGroupService.loadModuleGroupVersionDTO(
                        moduleGroupVersionId
                    )
                    ModuleGroupEntityService.insertOrUpdate(data)
                    setStoreModuleGroupVersionId(moduleGroupVersionId)
                } catch (e) {
                    setLoadModuleGroupError(
                        (e as Error).message ||
                            'An error occurred while loading your module group, please try again later'
                    )
                    console.error(e)
                } finally {
                    setIsLoading(false)
                }
            }
            void getModuleDto()
        }
    }, [reloads, moduleGroupVersionId])

    useEffect(() => {
        setModuleGroupType(ModuleGroupService.getModuleGroupType(moduleGroup))
    }, [reloads, moduleGroup])

    const getApprovalList = useCallback(async () => {
        try {
            const newReviewId = reviewId || moduleGroup.review?.reviewId
            const newRevNumber =
                revisionNumber || (moduleGroup.review?.revisionNumber as unknown as string)
            const approvalList = await ApprovalService.listApproval({
                reviewId: newReviewId || '',
                revisionNumber: newRevNumber || '',
            })
            if (approvalList) {
                setApprovals(
                    (approvalList ?? []).filter((a) => a.approvalStatus === ApprovalStatus.Approved)
                )
            }
        } catch (e) {
            setLoadModuleGroupError(
                (e as Error).message ||
                    'An error occurred while loading the approval list, please try again later'
            )
            console.error(e)
        }
    }, [moduleGroup.review?.reviewId, moduleGroup.review?.revisionNumber, reviewId, revisionNumber])

    useEffect(() => {
        if (reviewId || isUATReviewStage) {
            setIsLoading(true)
            setLoadModuleGroupError(undefined)

            const getReviewDto = async () => {
                try {
                    const reviewIdFetch = reviewId ? reviewId : moduleGroup.review?.reviewId || ''
                    const fetchedReviewDTO = await ApprovalService.getReview(reviewIdFetch)
                    if (fetchedReviewDTO) {
                        setReviewDto(fetchedReviewDTO)
                    }
                    if (reviewId) {
                        const revisionNotFound =
                            fetchedReviewDTO?.revisionList.findIndex(
                                (e) => `${e.revisionNumber}` === `${revisionNumber ?? ''}`
                            ) === -1

                        if (!revisionNumber || revisionNotFound) {
                            const latestRevision = _.last<RevisionDTO>(
                                _.sortBy(fetchedReviewDTO?.revisionList, 'createdAt')
                            )
                            if (latestRevision) {
                                navigate(
                                    `${
                                        new URL(latestRevision.reviewUrl || '').pathname
                                    }/${reviewId}/revisions/${latestRevision.revisionNumber}`
                                )
                                return
                            }
                        }
                    }

                    await getApprovalList()
                    setIsLoading(false)
                } catch (e) {
                    setLoadModuleGroupError(
                        (e as Error).message ||
                            'An error occurred while loading your module group review, please try again later'
                    )
                    console.error(e)
                    setIsLoading(false)
                }
            }
            void getReviewDto()
        }
    }, [
        getApprovalList,
        isUATReviewStage,
        moduleGroup.review?.reviewId,
        navigate,
        reviewId,
        revisionNumber,
    ])

    const [isCommentsLoading, setIsCommentsLoading] = useState(false)
    const [commentsError, setCommentsError] = useState<string>('')

    const reloadComments = useCallback(async () => {
        if (!reviewDto) {
            return
        }

        try {
            setIsCommentsLoading(true)
            const res = await ApprovalService.listAllCommentsForReview(reviewDto)
            setComments(res || [])
        } catch (e: unknown) {
            setCommentsError(
                (e as Error).message ||
                    'An error occurred while loading comments, please try again later'
            )
        } finally {
            setIsCommentsLoading(false)
        }
    }, [reviewDto])

    useEffect(() => {
        if (reviewId) {
            void reloadComments()
        }
    }, [reloadComments, reviewId])

    const commentsByLocation: Record<LocationId, CommentDTO[]> = React.useMemo(
        () => _.groupBy(comments, (x) => x.locationId ?? ''),
        [comments]
    )

    const context = useMemo(
        () => ({
            reviewId,
            revisionNumber,
            comments,
            commentsByLocation,
            isCommentsLoading,
            reloadComments,
        }),
        [reviewId, revisionNumber, comments, commentsByLocation, isCommentsLoading, reloadComments]
    )

    const approvers = useMemo(() => approvals.map((a) => a.reviewer), [approvals])

    const approve = useCallback(async () => {
        await ApprovalService.putApproval({
            reviewId,
            revisionNumber,
            reviewer: Authenticator.getDefaultUser(),
            approvalStatus: ApprovalStatus.Approved,
        })
        await getApprovalList()
        setRefreshMessage(true)
    }, [getApprovalList, reviewId, revisionNumber])

    const revoke = useCallback(async () => {
        await ApprovalService.putApproval({
            reviewId,
            revisionNumber,
            reviewer: Authenticator.getDefaultUser(),
            approvalStatus: ApprovalStatus.Revoked,
        })
        await getApprovalList()
        setRefreshMessage(true)
    }, [getApprovalList, reviewId, revisionNumber])

    const isApproved = useMemo(() => {
        return approvals.some(
            (a) =>
                a.approvalStatus === ApprovalStatus.Approved &&
                a.reviewer === Authenticator.getDefaultUser()
        )
    }, [approvals])

    useEffect(() => {
        if (!showAddModuleModal) {
            setEditModuleIndex(undefined)
            setDuplicateModuleIndex(undefined)
        }
    }, [showAddModuleModal])

    const harmonizeModuleGroupData = useCallback((): ModuleTableTypeData[] => {
        if (!moduleGroup.moduleMetadataList) {
            return []
        } else if (moduleGroup.moduleMappings.pec) {
            return moduleGroup.moduleMappings.pec.moduleVersionRuleSetList.map((ruleDef, index) => {
                const moduleMetadata: ModuleGroupModuleMetadata =
                    moduleGroup.moduleMetadataList.find(
                        (metadata) => metadata.versionId === ruleDef.moduleVersionId
                    ) as ModuleGroupModuleMetadata
                return {
                    priority: index + 1,
                    versionId: ruleDef.moduleVersionId,
                    metadata: ruleDef.ruleSet.rules,
                    moduleName: moduleMetadata?.name,
                    equivalencyReason: ruleDef?.equivalencyReason,
                    index: index,
                } as ModuleTableTypeData
            })
        } else if (moduleGroup.moduleMappings.mes) {
            const mesGroup: MESGroupDTO = moduleGroup.moduleMappings.mes
            return mesGroup.substituteModuleScores.map((moduleSubScore, index) => {
                return {
                    priority: index + 1,
                    versionId: moduleSubScore.substituteModuleVersion,
                    moduleName: moduleGroup.moduleMetadataList.find(
                        (module) => module.versionId === moduleSubScore.substituteModuleVersion
                    )?.name,
                    score: moduleSubScore.substituteScores
                        .map(
                            (subScore) =>
                                `${subScore.primaryScoreLabel}: ${subScore.substituteScoreLabel}`
                        )
                        .join('\n'),
                    equivalencyReason: moduleSubScore.equivalencyReason,
                    index: index,
                } as ModuleTableTypeData
            })
        } else {
            return []
        }
    }, [moduleGroup.moduleMappings, moduleGroup.moduleMetadataList])

    useEffect(() => {
        if (moduleGroup.moduleMappings) {
            setModuleTableData(harmonizeModuleGroupData())
        }
    }, [harmonizeModuleGroupData, moduleGroup])

    const onModuleDelete = async (moduleVersionId: string, index: number) => {
        setDeleteError(undefined)
        let updateRequest: UpdateModuleGroupRequest
        if (moduleGroup.moduleMappings.pec) {
            const updatedRuleSetList: ModuleVersionRuleSetDTO[] = [
                ...moduleGroup.moduleMappings.pec.moduleVersionRuleSetList,
            ]
            updatedRuleSetList.splice(index, 1)
            const isModuleStillInGroup =
                moduleGroup.moduleMappings.pec.moduleVersionRuleSetList.some(
                    (ruleSet) => ruleSet.moduleVersionId === moduleVersionId
                )
            let updatedFunctionalScores = moduleGroup.moduleMappings.pec.functionalScores
            if (!isModuleStillInGroup) {
                updatedFunctionalScores = moduleGroup.moduleMappings.pec.functionalScores?.map(
                    (functionalScore) => {
                        functionalScore.moduleVersionScoreLabelMap.delete(moduleVersionId)
                        return functionalScore
                    }
                )
            }
            updateRequest = {
                versionId: moduleGroup.versionId,
                lastKnownTimeToken: moduleGroup.savedTimeToken,
                moduleMappings: {
                    pec: {
                        ...moduleGroup.moduleMappings.pec,
                        moduleVersionRuleSetList: updatedRuleSetList,
                        functionalScores: updatedFunctionalScores,
                    } as PECGroupDTO,
                },
            }
        } else if (moduleGroup.moduleMappings.mes) {
            const mesGroup = moduleGroup.moduleMappings.mes
            const updatedSubstituteScores = [...mesGroup.substituteModuleScores]
            updatedSubstituteScores.splice(index, 1)
            updateRequest = {
                versionId: moduleGroup.versionId,
                lastKnownTimeToken: moduleGroup.savedTimeToken,
                moduleMappings: {
                    mes: {
                        primaryModuleVersionId: mesGroup.primaryModuleVersionId,
                        selectedPrimaryScoreLabels: mesGroup.selectedPrimaryScoreLabels,
                        substituteModuleScores: updatedSubstituteScores,
                    },
                },
            }
        } else return
        try {
            const updateResponseData = await ModuleGroupService.updateModuleGroupVersionDTO(
                updateRequest
            )
            ModuleGroupEntityService.insertOrUpdate(updateResponseData)
            if (updateResponseData && updateResponseData.versionId !== moduleVersionId) {
                navigate(
                    moduleGroupViewerRoute({
                        moduleGroupVersionId: updateResponseData.versionId,
                    })
                )
            }
            return true
        } catch (e) {
            console.error(e)
            setDeleteError(
                (e as Error).message ||
                    'An unexpected Error happened while updating your module group, please try again'
            )
            return false
        }
    }

    const changeRevision = useCallback(
        (rev: string, reviewUrl?: string) => {
            navigate(`${new URL(reviewUrl || '').pathname}/${reviewId}/revisions/${rev || ''}`)
        },
        [navigate, reviewId]
    )

    if (moduleGroupVersionId && !ValidationErrorEntityService.has(moduleGroupVersionId)) {
        ValidationErrorEntityService.create(moduleGroupVersionId)
    }

    const handleErrorCheck = (hasValidationError: boolean) => {
        setShowBanners(true)
        setHasErrors(hasValidationError)
    }

    if (loadModuleGroupError)
        return (
            <View width='100%' justifyContent='center'>
                <MessageBanner type={MessageBannerType.Error} dataTestId='load-error-message'>
                    {loadModuleGroupError}
                </MessageBanner>
            </View>
        )
    else if (isLoading || !moduleGroupType)
        return (
            <PageContainer>
                <View width='100%' justifyContent='center'>
                    <Spinner size={SpinnerSize.Large} dataTestId='module-group-loader-spinner' />
                </View>
            </PageContainer>
        )

    return (
        <Container paddingHorizontal={'S600'} paddingBottom={'S400'}>
            <Col gridGap='S300'>
                <HeaderBreadCrumb moduleGroupName={moduleGroup.name} />
                <Col gridGap='S400' margin={{ right: 'S400', left: 'S400' }}>
                    <Row justifyContent='space-between' gridGap='S300'>
                        <Col>
                            <H2 fontWeight='regular' dataTestId='module-name-title-header'>
                                {moduleGroup.name}
                            </H2>
                            <Subtitle>
                                Last modified by {moduleGroup.author} on{' '}
                                <span data-test-id='last-modified-date'>
                                    {new Date(moduleGroup.updatedAt ?? 0).toLocaleDateString()} at{' '}
                                    {new Date(moduleGroup.updatedAt ?? 0).toLocaleTimeString()}
                                </span>{' '}
                                {moduleGroup.status && (
                                    <TagUATStatus
                                        status={moduleGroup.status as ModuleGroupStatus}
                                    />
                                )}
                            </Subtitle>
                        </Col>
                        <View maxHeight='4rem'>
                            <ModuleReviewButtonsWrapper>
                                {!isReviewPage && (
                                    <UATModuleGroupApproval
                                        moduleGroupVersionId={moduleGroup.versionId}
                                        signOffDocument={moduleGroup.signOffDocument}
                                        reviewId={moduleGroup.review?.reviewId}
                                        moduleGroupName={moduleGroup.name}
                                        moduleGroupStatus={
                                            (moduleGroup.status as ModuleGroupStatus) ??
                                            ModuleGroupStatus.DRAFT_UNVALIDATED
                                        }
                                        uatApprovals={approvals}
                                        onApprovalChange={() => {
                                            reload()
                                            void getApprovalList()
                                        }}
                                        onDeployModalClose={() => {
                                            reload()
                                            void getApprovalList()
                                        }}
                                        revisionNumber={
                                            moduleGroup.review?.revisionNumber.toString() ?? '1'
                                        }
                                    />
                                )}

                                {!isReviewPage && (
                                    <ModuleGroupOptions
                                        moduleGroupEntity={moduleGroup}
                                        moduleGroupStatus={moduleGroup.status as ModuleGroupStatus}
                                        reviewStatus={moduleGroup.review}
                                        optionsToInclude={dropDownOptions}
                                        onErrorCheck={handleErrorCheck}
                                    />
                                )}
                                {isReviewPage && reviewDto && (
                                    <ModuleGroupReviewPageButtons
                                        moduleGroup={moduleGroup}
                                        changeRevision={changeRevision}
                                        approve={approve}
                                        revoke={revoke}
                                        review={reviewDto}
                                        revisionNumber={revisionNumber}
                                        isApproved={isApproved}
                                    />
                                )}
                            </ModuleReviewButtonsWrapper>
                        </View>
                    </Row>
                    <Text fontSize='T400' dataTestId='equivalency-type-text'>
                        {`${moduleGroupType} (${
                            Object.keys(ModuleGroupType)[
                                Object.values(ModuleGroupType).indexOf(moduleGroupType)
                            ]
                        })`}
                    </Text>
                </Col>
                {showBanners && (
                    <ModuleGroupMessageBanners
                        moduleGroupVersionId={moduleGroup.versionId}
                        hasErrors={hasErrors}
                        setShowBanners={setShowBanners}
                    />
                )}
                {notLatestModuleGroupVersionId && (
                    <MessageBanner
                        dataTestId='module-group-not-latest-revision'
                        type={MessageBannerType.Warning}
                    >
                        This is not the latest version of this module group. Please navigate to{' '}
                        <a
                            href={moduleGroupViewerRoute({
                                moduleGroupVersionId: moduleGroup.successorVersionId,
                            })}
                        >
                            this link
                        </a>{' '}
                        to view the next revision.
                    </MessageBanner>
                )}
                <Hr size='wide' />
                <Col gridGap='S300' margin={{ right: 'S400', left: 'S400' }}>
                    <Row gridGap='S200' alignItems='center'>
                        <Text fontSize='T300' fontWeight='bold'>
                            Module Group Version ID:
                        </Text>
                        <Text fontSize='T300'>{moduleGroupVersionId}</Text>
                        <Button
                            dataTestId='copy-module-group-version-id'
                            size={ButtonSize.Small}
                            variant={ButtonVariant.Tertiary}
                            icon={<IconCopy aria-hidden={true} />}
                            onClick={async () => {
                                await navigator.clipboard.writeText(moduleGroupVersionId ?? '')
                            }}
                        >
                            Copy
                        </Button>
                    </Row>
                    <Row gridGap='S200' alignItems='center'>
                        <Text fontSize='T300' fontWeight='bold'>
                            {moduleGroupType == ModuleGroupType.MES
                                ? 'Reference Module:'
                                : 'Default Module:'}
                        </Text>
                        <Text fontSize='T300'>
                            {moduleGroupType === ModuleGroupType.MES
                                ? (moduleGroup.moduleMappings.mes as MESGroupDTO)
                                      .primaryModuleVersionId
                                : (moduleGroup.moduleMappings.pec as PECGroupDTO)
                                      .defaultModuleVersionId}
                        </Text>
                        <Button
                            dataTestId='copy-module-version-id'
                            size={ButtonSize.Small}
                            variant={ButtonVariant.Tertiary}
                            icon={<IconCopy aria-hidden={true} />}
                            onClick={async () => {
                                await navigator.clipboard.writeText(
                                    moduleGroupType === ModuleGroupType.MES
                                        ? (moduleGroup.moduleMappings.mes as MESGroupDTO)
                                              .primaryModuleVersionId
                                        : (moduleGroup.moduleMappings.pec as PECGroupDTO)
                                              .defaultModuleVersionId
                                )
                            }}
                        >
                            Copy
                        </Button>
                    </Row>
                    <Text dataTestId='group-purpose'>{moduleGroup.purpose}</Text>
                </Col>
                <Hr size='wide' />
                {!isReviewPage && (
                    <ModuleGroupUATApprovalBanner
                        moduleGroupStatus={moduleGroup.status as ModuleGroupStatus}
                        uatApprovals={approvals}
                    />
                )}
                {isReviewPage && <Spacer height='S300' /> && (
                    <Col gridGap='S300'>
                        <ModuleReviewError
                            review={reviewDto}
                            error={loadModuleGroupError as string}
                            isLoading={isLoading}
                            revisionNumber={revisionNumber}
                        />
                        {refreshMessage && (
                            <MessageBanner
                                dataTestId='module-group-refresh'
                                type={MessageBannerType.Warning}
                            >
                                Please refresh the page to get the latest Module Group status.
                            </MessageBanner>
                        )}
                        {commentsError && (
                            <MessageBanner
                                dataTestId='comments-loading-error'
                                type={MessageBannerType.Warning}
                                isDismissible={true}
                            >
                                {commentsError}
                            </MessageBanner>
                        )}
                        {approvals?.length ? (
                            <ModuleApprovalMessage
                                approvalStatus={ApprovalStatus.Approved}
                                reviewers={approvers}
                            />
                        ) : null}
                    </Col>
                )}
                <Col gridGap='S300'>
                    {!isReviewPage && (
                        <Row justifyContent='space-between' gridGap='S300'>
                            <Row>
                                {deleteError && (
                                    <MessageBanner
                                        type={MessageBannerType.Error}
                                        dataTestId='delete-error-message'
                                    >
                                        {deleteError}
                                    </MessageBanner>
                                )}
                            </Row>
                            <Row justifyContent='right' gridGap={'S300'}>
                                {draftState ? (
                                    <Button
                                        dataTestId='add-module-button'
                                        variant={ButtonVariant.Primary}
                                        onClick={() => setShowAddModuleModal(!showAddModuleModal)}
                                    >
                                        Add Rule
                                    </Button>
                                ) : (
                                    <>
                                        {!notLatestModuleGroupVersionId && (
                                            <MessageBanner type={MessageBannerType.Informational}>
                                                Press this button to enable module edits
                                            </MessageBanner>
                                        )}
                                        {notLatestRevisionError && (
                                            <MessageBanner type={MessageBannerType.Error}>
                                                You cannot edit module group that is not the latest
                                                revision. Please navigate to the next revision using
                                                the link above.
                                            </MessageBanner>
                                        )}
                                        <Button
                                            variant={ButtonVariant.Primary}
                                            onClick={() => {
                                                if (notLatestModuleGroupVersionId) {
                                                    setNotLatestRevisionError(true)
                                                } else {
                                                    setShowEditWarningModal(true)
                                                }
                                            }}
                                        >
                                            Edit Rules
                                        </Button>
                                    </>
                                )}
                            </Row>
                        </Row>
                    )}

                    <ModuleReviewContext.Provider value={context}>
                        <Flex
                            gridGap='S400'
                            flexDirection={
                                layoutDirection === LAYOUT_DIRECTION.DESKTOP ? 'row' : 'column'
                            }
                            padding={
                                layoutDirection === LAYOUT_DIRECTION.DESKTOP
                                    ? { left: 'S100', right: 'S100' }
                                    : undefined
                            }
                        >
                            <View flex='1'>
                                {moduleGroup.moduleMetadataList &&
                                    moduleGroup.moduleMetadataList.length > 0 &&
                                    moduleTableData &&
                                    moduleTableData.length > 0 && (
                                        <Col gridGap='S300'>
                                            <ModuleGroupTable
                                                data={moduleTableData}
                                                tableType={
                                                    isReviewPage ? 'moduleGroupReview' : 'module'
                                                }
                                                totalRecords={moduleTableData.length}
                                                loading={false}
                                                onDelete={onModuleDelete}
                                                onEdit={(index: number) => {
                                                    setEditModuleIndex(index)
                                                    setShowAddModuleModal(true)
                                                }}
                                                onDuplicate={(index: number) => {
                                                    setDuplicateModuleIndex(index)
                                                    setShowAddModuleModal(true)
                                                }}
                                                editDisabled={!draftState}
                                            />
                                        </Col>
                                    )}
                            </View>
                            {isReviewPage && (
                                <View
                                    flex='1'
                                    maxWidth={
                                        layoutDirection === LAYOUT_DIRECTION.DESKTOP
                                            ? '320px'
                                            : '100%'
                                    }
                                >
                                    {reviewDto && (
                                        <ModuleReviewBox
                                            moduleVersionId={moduleGroup.versionId}
                                            review={reviewDto}
                                            revisionNumber={revisionNumber}
                                        />
                                    )}
                                </View>
                            )}
                        </Flex>
                    </ModuleReviewContext.Provider>
                </Col>
            </Col>
            {!isReviewPage && (
                <ModuleGroupAddModuleModal
                    showAddModuleModal={showAddModuleModal}
                    setShowAddModuleModal={setShowAddModuleModal}
                    moduleGroup={moduleGroup}
                    moduleGroupType={moduleGroupType}
                    editModuleIndex={editModuleIndex}
                    setEditModuleIndex={setEditModuleIndex}
                    duplicateModuleIndex={duplicateModuleIndex}
                    setDuplicateModuleIndex={setDuplicateModuleIndex}
                />
            )}
            {!isReviewPage && (
                <Modal
                    isOpen={showEditWarningModal}
                    close={() => setShowEditWarningModal(false)}
                    isScrollable={false}
                >
                    <ModalContent dataTestId='edit-module-modal' titleText='Enable Edit Rules'>
                        <Col gridGap='S300'>
                            <Text>
                                This will allow you to edit the module rules. Upon subsequence
                                changes, you will{' '}
                                <strong>create a new module group version id</strong>.
                            </Text>
                            <Text>Would you like to continue?</Text>

                            <Row gridGap='S300' justifyContent='right'>
                                <Button
                                    onClick={() => {
                                        setShowEditWarningModal(false)
                                        setDraftState(true)
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={ButtonVariant.Primary}
                                    onClick={() => setShowEditWarningModal(false)}
                                >
                                    No
                                </Button>
                            </Row>
                        </Col>
                    </ModalContent>
                </Modal>
            )}
        </Container>
    )
}
