import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { Store, STORE_ACTION } from 'src/services/Store'

export const VALIDATION_ERROR_ENTITY_STORE_SELECTOR = 'ValidationErrorEntity'

export class ValidationErrorEntityService {
    static store: Store<ValidationErrorEntity>
    static init() {
        this.store = new Store<ValidationErrorEntity>(VALIDATION_ERROR_ENTITY_STORE_SELECTOR)
    }

    static create(entityId: string): ValidationErrorEntity {
        const entity: ValidationErrorEntity = {
            id: entityId,
            validationErrors: {},
        }
        this.insert(entity)
        return entity
    }

    static get(entityId: string): ValidationErrorEntity {
        if (!this.has(entityId)) {
            this.create(entityId)
        }
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    static insert(entity: ValidationErrorEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static update(entity: ValidationErrorEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static swap(entityId: string, keys1: string[], keys2: string[]) {
        if (keys1.length !== keys2.length) {
            throw new Error('Both keys lists must have the same length')
        }
        this.store.produce(entityId, ({ validationErrors: errors }) => {
            for (let i = 0; i < keys1.length; i++) {
                const k1 = keys1[i]
                const k2 = keys2[i]
                ;[errors[k1], errors[k2]] = [errors[k2], errors[k1]]
            }
        })
    }
}
