import React, { useCallback, useEffect, useState } from 'react'
import { produce } from 'immer'

import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Row, View } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { ArrowValue } from 'src/components/CustomIcons/ArrowValue'
import { HarveyBallValue } from 'src/components/CustomIcons/HarveyBall'
import { Markdown } from 'src/components/Markdown'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import {
    RankingCellSubType,
    RankingCellType,
    RankingCellValue,
    RankingRatingScale,
    RankingResponse,
    RankingResponseCell,
    RankingResponseScore,
} from 'src/models/dto/items/RankingItemDTO'
import { Locale, Locales, LocalizeDefault } from 'src/models/dto/Locale'
import { NoDataCell } from 'src/pages/module-builder/item-editors/table-editor/TableCellEditor'
import { EditCellComponentProps } from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { ItemEditorTextArea, ItemEditorTextInput } from './ItemEditorInputs'
import { RatingIcon } from './RatingIcon'

export interface RankingCellContentOption {
    display: React.ReactNode
    value: RankingCellValue[]
    id: string
}

const subTypeLabelTexts = (subType: RankingCellSubType) => {
    switch (subType) {
        case RankingCellSubType.HarveyBallEmpty:
            return 'Harvey ball empty'
        case RankingCellSubType.HarveyBallHalfFull:
            return 'Harvey ball half full'
        case RankingCellSubType.HarveyBallFull:
            return 'Harvey ball full'
        case RankingCellSubType.ArrowUp:
            return 'Arrow up'
        case RankingCellSubType.ArrowDown:
            return 'Arrow down'
        default:
            return 'Text Only'
    }
}

const fullBall = {
    scale: {
        type: RankingCellType.HarveyBall,
        subType: RankingCellSubType.HarveyBallFull,
        valueI18N: LocalizeDefault<string>('High amount'),
    },
    text: subTypeLabelTexts(RankingCellSubType.HarveyBallFull),
}

const halfBall = {
    scale: {
        type: RankingCellType.HarveyBall,
        subType: RankingCellSubType.HarveyBallHalfFull,
        valueI18N: LocalizeDefault<string>('Moderate amount'),
    },
    text: subTypeLabelTexts(RankingCellSubType.HarveyBallHalfFull),
}

const emptyBall = {
    scale: {
        type: RankingCellType.HarveyBall,
        subType: RankingCellSubType.HarveyBallEmpty,
        valueI18N: LocalizeDefault<string>('Low amount'),
    },
    text: subTypeLabelTexts(RankingCellSubType.HarveyBallEmpty),
}

const arrowDown = {
    scale: {
        type: RankingCellType.Arrow,
        subType: RankingCellSubType.ArrowDown,
        valueI18N: LocalizeDefault<string>('Down'),
    },
    text: subTypeLabelTexts(RankingCellSubType.ArrowDown),
}

const arrowUp = {
    scale: {
        type: RankingCellType.Arrow,
        subType: RankingCellSubType.ArrowUp,
        valueI18N: LocalizeDefault<string>('Up'),
    },
    text: subTypeLabelTexts(RankingCellSubType.ArrowUp),
}

const harveyBalls = [emptyBall, halfBall, fullBall]

const arrows = [arrowUp, arrowDown]

// Build combos of HBs and Arrows
const combos: {
    scale: RankingRatingScale
    text: string
}[][] = []
harveyBalls.forEach((h) => {
    arrows.forEach((a) => {
        combos.push([h, a])
    })
})

export const cellContentOptions: RankingCellContentOption[] = [
    [
        {
            scale: {
                type: RankingCellType.Text,
                subType: RankingCellSubType.Text,
                valueI18N: LocalizeDefault<string>(''),
            },
            text: subTypeLabelTexts(RankingCellSubType.Text),
        },
    ],
    ...harveyBalls.map((s) => [s]),
    ...arrows.map((s) => [s]),
    ...combos,
].map((values) => {
    return {
        display: (
            <Row alignItems='center' gridGap='S200' dataTestId='ranking-cell-symbol-option'>
                {values.map((v: { scale: RankingRatingScale; text: string }, index) => {
                    return <RatingIcon key={index} value={v.scale} />
                })}
                <Text>{values.map((v) => v.text).join(', ')}</Text>
            </Row>
        ),
        value: values.map((v) => v.scale),
        id: values.map((v) => v.text).join(', '),
    }
})

export function stringifyResponseScores(scores?: RankingResponseScore[]): string {
    return (scores || []).map((s) => s.score).join(', ')
}

export function parseScoresStr(scoresStr?: string): RankingResponseScore[] {
    return (scoresStr || '').split(/\s*,\s*/).map((s: string) => {
        let sNum = parseFloat(s)
        // each s should be a number, otherwise it's 0
        if (isNaN(sNum)) {
            sNum = 0
        }
        return {
            score: sNum,
        }
    })
}

export function ValueCell({
    cell,
    locale,
    disabled,
}: {
    cell: RankingResponseCell
    locale: Locale
    disabled?: boolean
}) {
    if (cell.values.length === 0) {
        return <NoDataCell disabled={disabled} />
    }

    return (
        <Row
            alignItems='center'
            gridGap='S200'
            padding={{ top: 'S300', bottom: 'S300' }}
            height='100%'
            maxWidth={'420px'}
        >
            {cell.values.map((v: RankingCellValue, i: number) => {
                switch (v.type) {
                    case RankingCellType.HarveyBall:
                        return (
                            <React.Fragment key={i}>
                                <HarveyBallValue type={v.subType} />
                                <ScreenReaderOnly>{`${subTypeLabelTexts(
                                    v.subType
                                )},`}</ScreenReaderOnly>
                            </React.Fragment>
                        )
                    case RankingCellType.Arrow:
                        return (
                            <React.Fragment key={i}>
                                <ArrowValue type={v.subType} />
                                <ScreenReaderOnly>{`${subTypeLabelTexts(
                                    v.subType
                                )},`}</ScreenReaderOnly>
                            </React.Fragment>
                        )
                    default:
                        return (
                            <View key={i}>
                                <Text textAlign='left' tabIndex={0}>
                                    <Markdown markdown={v.valueI18N[locale] ?? ''} />
                                </Text>
                                <ScreenReaderOnly>
                                    {(v.valueI18N[locale] ?? '').length === 0
                                        ? ' (Blank content)'
                                        : ''}
                                </ScreenReaderOnly>
                            </View>
                        )
                }
            })}
        </Row>
    )
}

export const RankingResponseTableCellEditor = ({
    itemId,
    rowNum,
    colNum,
    nextCellData: cell,
    setNextCellData,
    options,
    responseRows,
    locale,
    setOnCellClear,
    setScores: setScoresForRow,
    setResponseLabel: setResponseLabelForRow,
}: {
    responseRows: RankingResponse[]
    options: RankingCellContentOption[]
    locale: Locale
    setOnCellClear: React.Dispatch<{ callback: (p: { rowNum: number; colNum: number }) => void }>
    setScores: (newScores: RankingResponseScore[]) => void
    setResponseLabel: (responseLabel: string) => void
} & EditCellComponentProps<RankingResponseCell>) => {
    const row: RankingResponse = responseRows[rowNum] ?? {
        responseCells: [],
        responseScores: responseRows.map(() => ({ score: 0 })),
        responseLabel: '',
    }
    const rowLabel = `Row ${rowNum + 1}`
    const idSuffix = `${rowNum}-${colNum}`
    const contentDropdownId = `cell-${itemId}-${idSuffix}`

    const findOptionIndex = useCallback(() => {
        return Math.max(
            options.findIndex((option) => {
                let matched = option.value[0]?.subType === cell.values[0]?.subType
                if (cell.values.length > 1 && cell.values[1]?.subType != RankingCellSubType.Text) {
                    matched = matched && option.value[1]?.subType === cell.values[1]?.subType
                }
                return matched
            }),
            0
        )
    }, [cell, options])

    const findCustomText = useCallback(() => {
        return (
            row.responseCells[colNum].values.find((v) => v.type === RankingCellType.Text)
                ?.valueI18N[locale] ?? ''
        )
    }, [colNum, locale, row.responseCells])

    const [scoresStr, setScoresStr] = useState(() => stringifyResponseScores(row.responseScores))
    const [responseLabelStr, setResponseLabelStr] = useState(row.responseLabel ?? '')
    const [selected, setSelected] = useState(() => findOptionIndex())
    const [customText, setCustomText] = useState(() => findCustomText())

    const updateValues = useCallback(
        ({
            selected: newSelected,
            customText: newCustomText,
        }: {
            selected?: number
            customText?: string
        }) => {
            // Reconstructing values from form changes
            let values: RankingCellValue[] = [...options[newSelected ?? selected].value]
            const customText1 = newCustomText ?? customText

            if (customText1.trim().length > 0) {
                const textValueFound = cell.values.find((c) => c.type === RankingCellType.Text) ?? {
                    type: RankingCellType.Text,
                    subType: RankingCellSubType.Text,
                    valueI18N: LocalizeDefault<string>(''),
                }
                const textValue = produce(textValueFound, ({ valueI18N }) => {
                    valueI18N[locale] = customText1
                })

                values.push(textValue)
            }

            // remove any values where the text is blank
            values = values.filter(
                (v) =>
                    v.type !== RankingCellType.Text ||
                    Locales.some((l: Locale) => (v?.valueI18N?.[l] ?? '').length > 0)
            )

            setNextCellData((s) => ({ ...s, values }))
        },
        [cell.values, customText, locale, options, selected, setNextCellData]
    )

    useEffect(() => {
        if (!cell.values || cell.values.length === 0) {
            setSelected(findOptionIndex())
            setCustomText(findCustomText())
        }
    }, [cell, findCustomText, findOptionIndex])

    const updateScores = useCallback(
        (newScoresStr?: string) => {
            const numRows = responseRows.length
            let scores: RankingResponseScore[] = parseScoresStr(newScoresStr ?? scoresStr)
            if (scores.length < numRows) {
                // too few scores in string
                const diff = numRows - scores.length
                for (let i = 0; i < diff; i++) {
                    scores.push({
                        score: 0,
                    })
                }
            } else if (scores.length > numRows) {
                // too many
                scores = scores.slice(0, numRows)
            }

            setScoresForRow(scores)
        },
        [responseRows.length, scoresStr, setScoresForRow]
    )

    useEffect(() => {
        setOnCellClear({
            callback: (p) => {
                if (p.rowNum === rowNum && p.colNum === colNum) {
                    setScoresStr(
                        responseRows
                            .map(() => {
                                return 0
                            })
                            .join(',')
                    )
                    setResponseLabelStr('')
                }
            },
        })
    }, [colNum, responseRows, row.responseLabel, row.responseScores, rowNum, setOnCellClear])

    useEffect(() => {
        setResponseLabelForRow(responseLabelStr)
    }, [responseLabelStr, setResponseLabelForRow])

    const setCustomTextAndUpdateValues = useCallback(
        (newCustomText: string) => {
            setCustomText(newCustomText)
            updateValues({ customText: newCustomText })
        },
        [setCustomText, updateValues]
    )

    const setScoresStrAndUpdateScores = useCallback(
        (newScoresStr: string) => {
            setScoresStr(newScoresStr)
            updateScores(newScoresStr)
        },
        [setScoresStr, updateScores]
    )

    const onSelectedChange = useCallback(
        (value: RankingCellContentOption) => {
            const ind = options.findIndex((opt) => opt.id === value.id)
            setSelected(ind)
            updateValues({ selected: ind })
        },
        [options, setSelected, updateValues]
    )

    const [initialized, setInitialized] = useState(false)
    useEffect(() => {
        if (!initialized) {
            setScoresForRow(responseRows[rowNum].responseScores)
            setScoresStr(stringifyResponseScores(responseRows[rowNum].responseScores))
            setResponseLabelStr(responseRows[rowNum].responseLabel)
            setInitialized(true)
        }
    }, [initialized, responseRows, rowNum, setScoresForRow])

    return (
        <>
            <Col>
                <InputWrapper labelText='Content Type' id={contentDropdownId}>
                    {(props) => (
                        <Select
                            {...props}
                            options={options}
                            renderOption={(option: RankingCellContentOption) => option.display}
                            value={options[selected]}
                            valueAccessor={(option: RankingCellContentOption) => option.id}
                            onChange={onSelectedChange}
                            disabled={!isDefaultLocale(locale)}
                            dataTestId={'value-type-selector'}
                        />
                    )}
                </InputWrapper>
            </Col>

            <Col>
                <ItemEditorTextArea
                    inputId={`cell-content-editor-custom-text-${idSuffix}`}
                    dataTestId={'cell-text-input'}
                    itemId={itemId}
                    labelText='Cell Text (optional)'
                    validationErrorMessage=''
                    disabled={false}
                    locale={locale}
                    value={customText}
                    setValue={setCustomTextAndUpdateValues}
                />
            </Col>

            <Col>
                <ItemEditorTextInput
                    inputId={`cell-content-editor-response-label-${idSuffix}`}
                    dataTestId={'row-response-label'}
                    itemId={itemId}
                    labelText={`${rowLabel}, Response label`}
                    validationErrorMessage=''
                    placeholder=''
                    disabled={!isDefaultLocale(locale)}
                    value={responseLabelStr}
                    setValue={setResponseLabelStr}
                />
            </Col>

            <Col>
                <ItemEditorTextInput
                    inputId={`cell-content-editor-scoring-${idSuffix}`}
                    itemId={itemId}
                    labelText={`${rowLabel}, scoring`}
                    validationErrorMessage=''
                    disabled={!isDefaultLocale(locale)}
                    placeholder=''
                    value={scoresStr}
                    setValue={setScoresStrAndUpdateScores}
                />
            </Col>
        </>
    )
}
