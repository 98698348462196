import { Draft } from 'immer'
import { v4 } from 'uuid'

import { ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import { ConditionalBranchingType } from 'src/models/dto/ConditionalBranchingLogicDTO'
import { ItemDTO } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { activity, ActivityEntityService, factories } from '../ActivityEntityService'
import { ItemEntityService } from '../ItemEntityService'

const activityType = ActivityType.LaunchItemGroup

export class ItemGroupHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(activityType, () => ItemGroupHandler.create())
    }

    static get(entityId: string): ItemGroupEntity {
        return ActivityEntityService.get(entityId) as ItemGroupEntity
    }

    /**
     *
     * @returns an ItemGroupEntity but does not add it to the Store
     */
    static create(): ItemGroupEntity {
        const newActivity = activity()

        return {
            ...newActivity,
            name: newActivity.id,
            description: '',
            ppt: ActivityType.LaunchItemGroup,
            type: ActivityType.LaunchItemGroup,
            itemIds: [] as string[],
            randomizationEnabled: false,
        }
    }

    private static assign(id: string, obj: Partial<ItemGroupEntity>) {
        ActivityEntityService.store.assign(id, obj)
    }

    private static produce(id: string, update: (draft: Draft<ItemGroupEntity>) => void) {
        ActivityEntityService.store.produce(id, update)
    }

    static updateDescription(entityId: string, description: string) {
        this.assign(entityId, { description })
    }

    static updateLabels(entityId: string, labels: string[]) {
        this.assign(entityId, { labels })
    }

    static updateRandomization(entityId: string, randomizationEnabled: boolean) {
        this.assign(entityId, { randomizationEnabled })
    }

    static addBranchingConditions(entityId: string) {
        this.assign(entityId, {
            branchingConditions: {
                itemConditions: [
                    {
                        itemLabel: '',
                        responseLabels: [''],
                    },
                ],
                branchingType: ConditionalBranchingType.OR,
            },
        })
    }

    static removeBranchingConditions(entityId: string) {
        this.assign(entityId, { branchingConditions: undefined })
    }

    static updateBranchingType(entityId: string, type: ConditionalBranchingType) {
        this.produce(entityId, (entity) => {
            if (!entity.branchingConditions) {
                entity.branchingConditions = { itemConditions: [], branchingType: type }
                return
            }
            entity.branchingConditions.branchingType = type
        })
    }

    static updateConditionalBranchingItemLabel(entityId: string, index: number, itemLabel: string) {
        this.produce(entityId, (entity) => {
            if (
                !entity.branchingConditions ||
                index >= entity.branchingConditions.itemConditions.length
            ) {
                return
            }
            entity.branchingConditions.itemConditions[index].itemLabel = itemLabel
        })
    }

    static updateConditionalBranchingItemResponseLabels(
        entityId: string,
        index: number,
        responseLabels: string[]
    ) {
        this.produce(entityId, (entity) => {
            if (
                !entity.branchingConditions ||
                index >= entity.branchingConditions.itemConditions.length
            ) {
                return
            }
            entity.branchingConditions.itemConditions[index].responseLabels = responseLabels
        })
    }

    static deleteItemResponseLabels(entityId: string, index: number) {
        this.produce(entityId, (entity) => {
            if (
                !entity.branchingConditions ||
                index >= entity.branchingConditions.itemConditions.length
            ) {
                return
            }
            entity.branchingConditions.itemConditions.splice(index, 1)
        })
    }

    static addItemResponseLabel(entityId: string) {
        this.produce(entityId, (entity) => {
            if (!entity.branchingConditions) {
                entity.branchingConditions = {
                    itemConditions: [],
                    branchingType: ConditionalBranchingType.OR,
                }
            }
            entity.branchingConditions.itemConditions.push({
                itemLabel: '',
                responseLabels: [''],
            })
        })
    }

    static updateItems(entityId: string, itemIds: string[]) {
        this.assign(entityId, { itemIds })
    }

    static removeFromItems(entityId: string, itemIndex: number) {
        this.produce(entityId, (entity) => {
            entity.itemIds.splice(itemIndex, 1)
        })
    }

    static addItem(workflowEntityId: string, itemVersionId: string, position?: number) {
        this.produce(workflowEntityId, (entity) => {
            const len = entity.itemIds.length
            const index = Math.min(len, position ?? len)
            entity.itemIds.splice(index, 0, itemVersionId)
        })
    }

    static removeItem(workflowEntityId: string, itemVersionId: string) {
        this.produce(workflowEntityId, (entity) => {
            entity.itemIds = entity.itemIds.filter((id) => id !== itemVersionId)
        })
    }

    static duplicate(workflowEntityId: string) {
        const duplicatedWorkflowEntity = { ...this.get(workflowEntityId), id: v4() }
        const duplicatedItems: ItemDTO[] = ItemEntityService.duplicateItems(
            duplicatedWorkflowEntity.itemIds
        )
        duplicatedWorkflowEntity.itemIds = duplicatedItems.map((i) => i.id)
        return duplicatedWorkflowEntity
    }

    static moveUp(itemGroupId: string, itemEntityId: string) {
        this.produce(itemGroupId, (entity) => {
            const currentIndex = entity.itemIds.indexOf(itemEntityId)
            if (currentIndex > 0) {
                entity.itemIds = this.moveItemId(entity.itemIds, itemEntityId, currentIndex - 1)
            }
        })
    }

    public static moveDown(itemGroupId: string, itemEntityId: string) {
        this.produce(itemGroupId, (entity) => {
            const currentIndex = entity.itemIds.indexOf(itemEntityId)
            if (currentIndex < entity.itemIds.length - 1) {
                entity.itemIds = this.moveItemId(entity.itemIds, itemEntityId, currentIndex + 1)
            }
        })
    }

    private static moveItemId(itemIds: string[], itemId: string, toIndex: number) {
        const next = itemIds.filter((i) => i !== itemId)
        return [...next.slice(0, toIndex), itemId, ...next.slice(toIndex)]
    }

    static updateName(entityId: string, name: string) {
        this.assign(entityId, { name })
    }

    static updateHeader(entityId: string, locale: Locale, pageHeader: string) {
        this.produce(entityId, (itemGroupEntity) => {
            // initialize header if it does not exist on the item group
            if (!itemGroupEntity.headerI18N)
                itemGroupEntity.headerI18N = LocalizeDefault<string>('')
            itemGroupEntity.headerI18N[locale] = pageHeader
        })
    }

    static updateBody(entityId: string, locale: Locale, pageBody: string) {
        this.produce(entityId, (itemGroupEntity) => {
            // initialize body if it does not exist on the item group
            if (!itemGroupEntity.bodyI18N) itemGroupEntity.bodyI18N = LocalizeDefault<string>('')
            itemGroupEntity.bodyI18N[locale] = pageBody
        })
    }

    static setContextBoxId(entityId: string, contextBoxId: string) {
        this.assign(entityId, { contextBoxId })
    }

    static removeContextBox(entityId: string) {
        this.assign(entityId, { contextBoxId: undefined })
    }
}
