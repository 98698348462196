import { Draft, freeze, produce } from 'immer'
import isUndefined from 'lodash/isUndefined'
import { filter, Observable, Subject } from 'rxjs'

/**
 * Anything that modifies data must go through a Store Action
 */
export enum STORE_ACTION {
    DESTROYED = 'DESTROYED',
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    UPDATED = 'UPDATED',
    REQUEST_DESTRUCT = 'REQUEST_DESTRUCT',
    REQUEST_DELETE = 'REQUEST_DELETE',
    REQUEST_UPDATE = 'REQUEST_UPDATE',
    REQUEST_CREATE = 'REQUEST_CREATE',
    FAILED_CREATE = 'FAILED_CREATE',
    FAILED_UPDATE = 'FAILED_UPDATE',
    FAILED_DELETE = 'FAILED_DELETE',
}

export interface StoreEvent {
    action: STORE_ACTION
    entityId?: string
    payload: unknown
}

/**
 * This static class
 */
export class Stores {
    private static stores = new Map<string, Store<unknown>>()

    static list() {
        return Array.from(this.stores.keys())
    }

    static add(selector: string, s: Store<unknown>) {
        this.stores.set(selector, s)
    }

    static remove(selector: string) {
        if (!this.stores.has(selector)) {
            throw new Error(`store ${selector} does not exist, did you already remove it?`)
        }
        this.stores.delete(selector)
    }

    static get(selector: string) {
        if (!this.stores.has(selector)) {
            throw new Error(`store ${selector} does not exist, did you forget to add it?`)
        }

        return this.stores.get(selector)
    }

    /**
     * dispatch function is how we interact with each of the stores instead dof d
     * @param selector
     * @param payload
     */
    static dispatch(selector: string, payload: StoreEvent) {
        const store = this.get(selector) as Store<unknown>
        store.dispatch(payload)
    }
}

export interface ChildChangeEvent {
    entityId: string
    child: { entityId: string; selector: string }
    payload: unknown
}

const updateAssign =
    <T>(obj: Partial<T>) =>
    (draft: Draft<T>) => {
        for (const [key, value] of Object.entries(obj as Record<string, unknown>)) {
            draft[key] = value
        }
    }

export class Store<T> {
    private records = new Map<string, T>()
    private orderedIds: string[] = []

    private subject$ = new Subject<StoreEvent>()

    private dispatch$ = new Subject<StoreEvent>()

    private readonly child$ = new Subject<ChildChangeEvent>()

    private readonly selector: string

    constructor(s: string) {
        this.selector = s
        Stores.add(this.selector, this)

        this.dispatch$.subscribe((evt) => this.reduce(evt))
    }

    /**
     * This destroys the store and removes the subject, closing any subscriptions to it
     */
    destroy() {
        this.subject$.next({
            action: STORE_ACTION.DESTROYED,
            payload: {
                selector: this.selector,
            },
        })

        this.subject$.complete() // remove all subscriptions
        Stores.remove(this.selector)
    }

    /**
     * reduce function, meant to be used from within this class
     * @param evt a store event that this store can action on
     */
    private reduce(evt: StoreEvent) {
        switch (evt.action) {
            case STORE_ACTION.REQUEST_CREATE:
                try {
                    this.create(evt.entityId as string, evt.payload as T)
                } catch (err) {
                    this.subject$.next({
                        action: STORE_ACTION.FAILED_CREATE,
                        entityId: evt.entityId,
                        payload: err,
                    })
                }
                break
            case STORE_ACTION.REQUEST_UPDATE:
                try {
                    this.update(evt.entityId as string, evt.payload as T)
                } catch (err) {
                    this.subject$.next({
                        action: STORE_ACTION.FAILED_UPDATE,
                        entityId: evt.entityId,
                        payload: err,
                    })
                }
                break
            case STORE_ACTION.REQUEST_DELETE:
                try {
                    this.delete(evt.entityId as string)
                } catch (err) {
                    this.subject$.next({
                        action: STORE_ACTION.FAILED_DELETE,
                        entityId: evt.entityId,
                        payload: err,
                    })
                }
                break
            case STORE_ACTION.REQUEST_DESTRUCT:
                this.destroy()
                break
        }
    }

    /**
     * Function for this store's reducer to respond to requests
     * @param evt
     */
    dispatch(evt: StoreEvent) {
        this.dispatch$.next(evt)
    }

    dispatchChildChange(
        id: string,
        child: { entityId: string; selector: string },
        payload: unknown
    ) {
        this.child$.next({ entityId: id, child, payload })
    }

    list(): Readonly<T[]> {
        return this.orderedIds
            .map((id) => this.records.get(id))
            .filter((entity) => !isUndefined(entity)) as Readonly<T[]>
    }

    observeId(id: string): Observable<StoreEvent> {
        function isRelevant(msg: StoreEvent): boolean {
            return !!msg.entityId && msg.entityId === id
        }
        return this.subject$.pipe(filter(isRelevant))
    }

    observeChildChanges(id: string): Observable<ChildChangeEvent> {
        function isRelevant(msg: ChildChangeEvent): boolean {
            return !!msg.entityId && msg.entityId === id
        }
        return this.child$.pipe(filter(isRelevant))
    }

    observe(): Observable<StoreEvent> {
        return this.subject$
    }

    has(id: string) {
        return this.records.has(id)
    }

    /**
     * Gets the deep clone of an entity by ID.
     * In handler code, use `produce` instead when making changes.
     * @param id The entity ID.
     * @throws Error If the entity does not exist.
     */
    get(id: string) {
        if (!this.records.has(id)) {
            throw new Error(`entityId ${id} does not exist in the ${this.selector} store`)
        }
        return freeze(this.records.get(id), true) as Readonly<T>
    }

    /**
     * Performs an update on a specific entity by ID using Immer's `produce`.
     * @param id The entity ID to update
     * @param update The Immer recipe function to perform the update on the draft.
     * The return value is always ignored so it can't be used to return a replacement.
     *
     * The return value will be ignored.
     * @throws Error When the entity does not exist.
     * @see https://immerjs.github.io/immer/produce/
     */
    produce<T1 extends T = T>(id: string, update: (draft: Draft<T1>) => void) {
        if (!this.records.has(id)) {
            throw new Error(`entity ${id} does not exist in ItemEntity`)
        }

        this.update(
            id,
            produce<T1>(this.records.get(id) as T1, (draft: Draft<T1>) => {
                update(draft)
            })
        )
    }

    assign(id: string, update: Partial<T>) {
        this.produce(id, updateAssign(update))
    }

    private create(id: string, record: T) {
        if (typeof id !== 'string' || id.length === 0) {
            throw new Error('a truthy id must be provided for create')
        }
        if (this.records.has(id)) {
            throw new Error(`entityId ${id} already exists in the ${this.selector} store`)
        }
        this.records.set(id, record)
        this.orderedIds = [...this.orderedIds, id]

        this.subject$.next({
            action: STORE_ACTION.CREATED,
            entityId: id,
            payload: record,
        })
    }

    private update(id: string, record: T) {
        if (!this.records.has(id)) {
            throw new Error(`entityId ${id} does not exist in the ${this.selector} store`)
        }
        if (this.records.get(id) === record) {
            return
        }

        this.records.set(id, record)
        this.subject$.next({
            action: STORE_ACTION.UPDATED,
            entityId: id,
            payload: record,
        })
    }

    private delete(id: string) {
        if (!this.records.has(id)) {
            throw new Error(`entityId ${id} does not exist in the ${this.selector} store`)
        }
        this.subject$.next({
            action: STORE_ACTION.DELETED,
            entityId: id,
            payload: this.records.get(id),
        })
        this.records.delete(id)
        this.orderedIds = this.orderedIds.filter((_id) => _id !== id)
    }
}
