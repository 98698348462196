import {
    AudioCheckItemDTO,
    defaultAudioCheckDTO,
    SoundCheckMLModelType,
} from 'src/models/dto/items/AudioCheckItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.AudioCheck

export class AudioCheckHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => AudioCheckHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<AudioCheckItemDTO>
    }

    static create(): AudioCheckItemDTO {
        return defaultAudioCheckDTO()
    }

    private static assign(id: string, obj: Partial<AudioCheckItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateMaxNumberOfAttempts(id: string, maxNumberOfAttempts: number) {
        this.assign(id, { maxNumberOfAttempts })
    }

    static updateMaxRecordingTimeInSeconds(id: string, maxRecordingTimeInSeconds: number) {
        this.assign(id, { maxRecordingTimeInSeconds })
    }

    static updateSoundCheckMlModel(id: string, soundCheckMLModel: SoundCheckMLModelType) {
        this.assign(id, { soundCheckMLModel })
    }

    static updateSoundCheckMlModelVersion(id: string, soundCheckMLModelVersion: string) {
        this.assign(id, { soundCheckMLModelVersion })
    }

    static updatePrompt(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.promptI18N[locale] = nextValue
        })
    }

    static updateSentence(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.sentenceI18N[locale] = nextValue
        })
    }
}
