import { ItemType } from 'src/models/dto/items/ItemDTO'
import {
    defaultMediaStimulusItemDTO,
    MediaStimulusItemDTO,
} from 'src/models/dto/items/MediaStimulusItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.MediaStimulus

export class MediaStimulusHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => MediaStimulusHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<MediaStimulusItemDTO>
    }

    static create(): MediaStimulusItemDTO {
        return defaultMediaStimulusItemDTO()
    }

    private static assign(id: string, obj: Partial<MediaStimulusItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updatePreserveOrder(id: string, preserveOrder: boolean) {
        this.assign(id, { preserveOrder })
    }

    static updateTitle(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.titleI18N[locale] = nextValue
        })
    }

    static updateLimitPlayback(id: string, limitPlayback: boolean) {
        this.assign(id, { limitPlayback })
    }

    static updateMaxPlaybackCount(id: string, maxPlaybackCount: number | undefined) {
        this.assign(id, { maxPlaybackCount })
    }
}
