import { applyChange, Change } from 'src/hooks/DTOEditor'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import {
    AvatarDTO,
    defaultVirtualMeetingDTO,
    VirtualMeetingDTO,
} from 'src/models/dto/items/VirtualMeetingDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { Store, Stores } from 'src/services/Store'

const itemType = ItemType.VirtualMeeting

export class VirtualMeetingHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => this.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<VirtualMeetingDTO>
    }

    static create(): VirtualMeetingDTO {
        return defaultVirtualMeetingDTO()
    }

    private static assign(id: string, obj: Partial<VirtualMeetingDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateOptional(id: string, optional: boolean) {
        this.assign(id, { optional })
    }

    static updateTitle(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.titleI18N[locale] = nextValue
        })
    }

    static deleteAvatar(id: string, index: number) {
        this.store().produce(id, (entity) => {
            entity.avatars.splice(index, 1)
        })
    }

    static updateAvatarFirstName(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        this.store().produce(id, (entity) => {
            entity.avatars[avatarIndex].firstNameI18N[locale] = nextValue
        })
    }

    static updateAvatarLastName(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        this.store().produce(id, (entity) => {
            entity.avatars[avatarIndex].lastNameI18N[locale] = nextValue
        })
    }

    static updateAvatarInfoLine1(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        this.store().produce(id, (entity) => {
            entity.avatars[avatarIndex].informationLine1I18N[locale] = nextValue
        })
    }

    static updateAvatarInfoLine2(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        this.store().produce(id, (entity) => {
            entity.avatars[avatarIndex].informationLine2I18N[locale] = nextValue
        })
    }

    static addAvatar(id: string) {
        this.store().produce(id, (entity) => {
            entity.avatars.push({
                firstNameI18N: LocalizeDefault<string>(''),
                lastNameI18N: LocalizeDefault<string>(''),
                informationLine1I18N: LocalizeDefault<string>(''),
                informationLine2I18N: LocalizeDefault<string>(''),
            } as AvatarDTO)
        })
    }

    static updateAvatarLocaleWiseMediaOpt(
        id: string,
        change: Change<Medias | undefined>,
        avatarIndex: number
    ) {
        this.store().produce(id, (entity) => {
            const { localeWiseMedia } = entity.avatars[avatarIndex]
            entity.avatars[avatarIndex].localeWiseMedia = applyChange(localeWiseMedia, change)
        })
    }

    static updatePlaybackLimit(id: string, nextValue: number | null) {
        this.store().produce(id, (entity) => {
            if (nextValue === null) {
                entity.playbackLimit = undefined
            } else {
                entity.playbackLimit = nextValue
            }
        })
    }
}
