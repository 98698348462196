import {
    defaultFlyoutInstructionContentItemDTO,
    FlyoutInstructionalContentItemDTO,
} from 'src/models/dto/items/FlyoutInstructionalContentItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, Stores } from './../../Store'

const itemType = ItemType.FlyoutInstructionalContentItem

export class FlyoutInstructionalContentHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => FlyoutInstructionalContentHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<FlyoutInstructionalContentItemDTO>
    }

    static create(): FlyoutInstructionalContentItemDTO {
        return defaultFlyoutInstructionContentItemDTO()
    }

    private static assign(id: string, obj: Partial<FlyoutInstructionalContentItemDTO>) {
        this.store().assign(id, obj)
    }

    static updateLabel(id: string, label: string) {
        this.assign(id, { label })
    }

    static updateSectionType(id: string, sectionType: string) {
        this.assign(id, { sectionType })
    }

    static updateSectionName(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.sectionNameI18N[locale] = nextValue
        })
    }

    static updateText(id: string, locale: Locale, nextValue: string) {
        this.store().produce(id, (entity) => {
            entity.textI18N[locale] = nextValue
        })
    }
}
