import { Draft } from 'immer'

import { TableItemDTO } from 'src/models/dto/items/TableItemDTO'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'
import { factories, item, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, Stores } from './../../Store'

const itemType = ItemType.Table

export class TableHandler extends TableOperations<TableItemDTO> {
    public static readonly INSTANCE = new TableHandler()

    private constructor() {
        super()
    }

    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => TableHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<TableItemDTO>
    }

    public static create(): TableItemDTO {
        const defaultNumberOfColumns = 4
        const defaultNumberOfRows = 5
        return {
            ...item(),
            itemType,
            ppt: 'Table',
            headers: TableOperations.createEmptyHeaders(defaultNumberOfColumns),
            rows: TableOperations.createEmptyRows(defaultNumberOfRows, defaultNumberOfColumns),
            legends: [],
            symbolLegendEnabled: false,
            harveyBallLegendEnabled: false,
        }
    }

    private getEntity(id: string): TableItemDTO {
        if (TableHandler.store().has(id)) {
            return TableHandler.store().get(id)
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    protected getTable(id: string): TableItemDTO {
        return this.getEntity(id)
    }

    protected produceTable(id: string, update: (draft: Draft<TableItemDTO>) => void) {
        TableHandler.store().produce(id, update)
    }

    public updatePreserveOrder(id: string, nextValue: boolean) {
        this.produceTable(id, (draft) => {
            draft.preserveOrder = nextValue
        })
    }
}
